import { Box } from "@mui/material";
import type { ComponentProps } from "react";
import { useEffect } from "react";

import { FormProvider } from "react-hook-form";

import type { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";

interface CustomFormProviderProps<TFormValues extends FieldValues>
    extends Omit<ComponentProps<"form">, "onSubmit"> {
    className?: string;
    id?: string;
    isDisabled?: boolean;
    form: UseFormReturn<TFormValues>;
    onSubmit?: SubmitHandler<TFormValues>;
}

export const CustomFormProvider = <TFormValues extends FieldValues>({
    form,
    onSubmit,
    children,
    className,
    id,
    ...props
}: CustomFormProviderProps<TFormValues>) => {
    const { clearErrors, reset, handleSubmit, formState, getValues } = form;
    const { isSubmitSuccessful } = formState;

    useEffect(
        () => () => {
            // reset on unmount
            clearErrors();
            reset();
        },
        [clearErrors, reset]
    );

    useEffect(() => {
        // reset form isDirty = false
        if (isSubmitSuccessful) {
            reset(getValues());
        }
    }, [isSubmitSuccessful, getValues, reset]);

    return (
        <FormProvider {...form}>
            <form
                noValidate
                id={id}
                className={className}
                onSubmit={onSubmit ? handleSubmit(onSubmit) : (e) => e.preventDefault()}
                {...props}
            >
                <Box>{children}</Box>
            </form>
        </FormProvider>
    );
};
