export const sortContractorsUtility = (input: any) => {
    let contractors;
    let isWrapped = false;
    let isTrippleWrapped = false;

    if (Array.isArray(input)) {
        contractors = input;
    } else if (input && Array.isArray(input.contractors)) {
        contractors = input.contractors;
        isWrapped = true;
    } else if (input.data.data.contractors && Array.isArray(input.data.data.contractors)) {
        contractors = input.data.data.contractors;
        isTrippleWrapped = true;
    } else {
        return input;
    }

    const contractorSiteHistory = JSON.parse(localStorage.getItem("contractorSiteHistory") || "[]").map(item => item.toString());

    const sortedContractors = contractors.sort((a, b) => {
        if (!a || !a.id) return 1;
        if (!b || !b.id) return -1;
        const indexA = contractorSiteHistory.indexOf(a.id.toString());
        const indexB = contractorSiteHistory.indexOf(b.id.toString());

        if (indexA === -1 && indexB === -1) {
            return 0;
        }
        if (indexA === -1) {
            return 1;
        }
        if (indexB === -1) {
            return -1;
        }
        return indexA - indexB;
    });

    if (isWrapped) {
        return {
            ...input,
            contractors: sortedContractors,
        };
    } else if (isTrippleWrapped) {
        return {
            ...input,
            data: {
                ...input.data,
                data: {
                    ...input.data.data,
                    contractors: sortedContractors,
                }
            },
        };
    } else {
        return sortedContractors;
    }
};