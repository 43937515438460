import styled from "styled-components";
import small_bg_dot from "@assets/Images/small_bg_dot.svg";


export const StyledVehicleDetailView = styled.div`
    // background-image: url(${small_bg_dot});
    background-color:${(p) => p.theme.colors.white02};
    min-height: 100vh;
    // padding-bottom: 4rem;
    .wrapperVehicleDetail {
        display: flex;
        justify-content: center;
        
    }
    .VehicleDetail {
        text-align: left;
        padding: 1rem 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
        }
            margin-top:7rem;
            padding-bottom:12rem;
        &__back {
            cursor: pointer;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding-top: 1rem;
            }
        }
        &__top {
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
            }
                
            &-title {
                display: flex;
                color: ${(p) => p.theme.colors.black01};
                align-items:center;
                // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                //     padding-left: 9rem;
                // }
                .main {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    font-size: 3.2rem;
                }
                .subTitle {
                    padding: 0.8rem;
                    border-radius:6px;
                    background-color: ${(p) => p.theme.colors.white00};
                    color: ${(p) => p.theme.colors.black02};
                    font-size: 1.6rem;
                    display: inline-block;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    // margin-top: 2.5rem;
                }
            }
        }
        // &__content {
        //     margin-top: 4rem;
        //     @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        //         width: 1224px;
        //         max-width: 1224px;
        //     }
        // }
    }
`;
export const StyledVehicleDetailInfo = styled.div`
      .infoTitle {
          padding:1rem;
          background-color:${(p) => p.theme.colors.lightgray09};
          border-top-left-radius:6px;
          border-top-right-radius:6px;
          display:flex;
          justify-content:space-between;
          align-items:center;

        }

      .infoTitleMain {
        font-size:20px;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
        color: ${(p) => p.theme.colors.black01};

        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
              font-size:14px;
          }

      }

    .infoTitleSub {
         padding:5px;
         border-radius:6px;
         background-color:${(p) => p.theme.colors.white00};
         font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};

         @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                font-size:10px;
            }

        }

    .VehicleDetailInfo {
        margin-top: 2rem;
        background-color: ${(p) => p.theme.colors.white};
        border-radius: 6px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            // margin-top: 0;
            // background-color: ${(p) => p.theme.colors.white};
            // border-radius: 1.6rem;
            // padding: 4rem;
            // height: 673px;
            // max-height: 673px;
        }
        .info {
            &__detail {
                border-bottom-left-radius:6px;
                padding: 1rem 1rem;
                background-color: ${(p) => p.theme.colors.white00};
                // border-radius: 1.6rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    // border-radius: 0;
                    // padding: 0rem 4rem;
                    // border-right: 1px solid ${(p) => p.theme.colors.gray4};
                }
            }
            &__title {
                font-size: 1.4rem;
                // width: 40%;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                color: #818181;
            }
            &__imageWrapper {
                // margin: 1.5rem 0;
                width: 100%;
                // max-width:30rem;
                // padding: 1.5rem;
                // border: 1px solid ${(p) => p.theme.colors.gray2};
                // min-height: 30rem;

                .image {
                    border-radius:6px;
                    width:100%;
                    max-height:50rem;
                    // width: 30rem;
                    // height: 35rem;
                    object-fit:cover;

                }
            }
            &__infoWrapper {
                display: flex;
                justify-content: space-between;
                padding: 2rem 1rem;
                color: ${(p) => p.theme.colors.black01};
            }
            &__number {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.6rem;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 15rem;
            }
            &__link {
                text-align: right;
                font-size: 1.4rem;
                color: ${(p) => p.theme.colors.primary};
                // text-decoration: none;
                cursor: pointer;
                display: block;
                // display: -webkit-box;
                // line-height: 1.2;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                // word-break: break-all;
                max-width: 18rem;
            }
        }
        .info__form {
            margin-top: 4rem;
            padding: 1rem 1rem;
            background-color: ${(p) => p.theme.colors.white00};
            border-radius: 6px;
            padding-top: 1rem;

            margin-left:0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                // margin-top: 0;
                padding: 2rem 2rem;
                padding-top: 0;
                width:80%;
                 margin-left:auto;

            }
            &__title {
                font-size: 1.4rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            }
            &__select {
                padding: 0.8rem 0;
            }
            &__item {
                display: flex;
                justify-content: space-between;
                padding: 1.7rem 0;
                border-top: 1px solid #f0f0f0;
                border-bottom: 1px solid #f0f0f0;
                &-content {
                    display: flex;
                    align-items: center;
                }
                &-text {
                    display: block;
                    padding: 0 0 0.2rem 1rem;
                    font-size: 1.4rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                }
                &-amount {
                    display: block;
                    padding-bottom: 1rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    font-size: 2rem;
                }
            }
            &__amount {
                /* padding-top: 1rem; */
                display: flex;
                flex-direction: column;
                &-label {
                    padding: 1rem 0;
                }
                &-input {
                    margin-top: 1rem;
                }
            }
        }
        .btn {
          display:flex;
          justify-content:flex-end;
          align-items:center;
          margin-left:auto;
          margin-top: 2rem;
          // padding: 1rem 2rem;
          max-width:65%;
          @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
          margin-left:0;
            margin-top: 1rem;
            // padding: 1rem 1rem;
            max-width:100%;
            }
        }
    }
`;

export const StyledVehicleCalendar = styled.div`
    background-color: ${(p) => p.theme.colors.white02};
    min-height: 100vh;
    // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
    //     background-color: ${(p) => p.theme.colors.white02};
    // }
    .VehicleDetail {
        text-align: left;
        padding: 1rem 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
        }
            margin-top:7rem;
        &__back {
            cursor: pointer;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding-top: 1rem;
            }
        }
        &__top {
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                margin-bottom: 4rem;
            }
            &-title {
                color:${(p) => p.theme.colors.black01};
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    // padding-left: 4rem;
                }

                .main {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    font-size: 3.2rem;
                    display: block;
                    display: -webkit-box;
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    max-width: 100%;
                }
                .subTitle {
                    padding: 0.8rem;
                    background-color: ${(p) => p.theme.colors.secondary};
                    color: ${(p) => p.theme.colors.white};
                    font-size: 1.6rem;
                    display: inline-block;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    margin-top: 2rem;
                }
            }
        }
        &__wrapperContent {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                // display: flex;
                // justify-content: center;
            }
        }
        &__wrapperContent {
            // display: flex;
            // justify-content: center;
            // flex-direction: column;
        }
        &__calendarWrapper {
            background-color: ${(p) => p.theme.colors.white00};
            border-radius: 6px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                // display: flex;
                // justify-content: center;
            }
        }
        &__selectWrapper {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
        }
        .select {
            padding-bottom: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 60rem;
            }
        }
        .calendar {
            padding:2rem;

            @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 1rem;
            }
        }
        .calendar-content {
          display:flex;
          justify-content:center;

        }
        .calendarTitle {
          border-top-right-radius:6px;
          border-top-left-radius:6px;
          background-color: ${(p) => p.theme.colors.lightgray09};
          display:flex;
          align-items:center;
          justify-content:space-between;

          .main {
              font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
              font-size: 2rem;
              display: block;
              display: -webkit-box;
              line-height: 1.2;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              max-width: 100%;
              margin-left:1rem;
          }
          .subTitle {
              padding: 0.8rem;
              margin:6px;
              border-radius:6px;
              background-color: ${(p) => p.theme.colors.white00};
              color: ${(p) => p.theme.colors.black01};
              font-size: 1.2rem;
              display: inline-block;
              font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
              // margin-top: 2rem;
          }


        }
        .wrappBtn {
            display: flex;
            justify-content: flex-end;
            margin-top: 3rem;
             @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                justify-content: center;
            }
        }
        .btn {
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 35rem;
            }
        }
    }
`;

export const StyledVehicleDetailHistory = styled.div`
    background-color: ${(p) => p.theme.colors.white00};
    color:${(p) => p.theme.colors.black01};
    // padding: 3.6rem 2rem;
    border-radius: 6px;
    height: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .wrapper {
        max-height: 550px;
        overflow-y: auto;
    }
    .title {
        padding:1rem 1rem;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
        font-size: 2rem;
        background-color: ${(p) => p.theme.colors.lightgray09};
        border-top-right-radius:6px;
        border-top-left-radius:6px;
    }
    .HistoryInventory {
        display: flex;
        justify-content: space-around;
        padding: 1rem 1rem;
        background-color: ${(p) => p.theme.colors.white00};
        border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
        // border-radius: 1.6rem;
        // margin-top: 1.5rem;
        margin:auto;
        width:95%;
        .wrapperImage {
            height: 5rem;
            max-height: 5rem;
            overflow: hidden;
            border-radius: 100%;
            width: 5rem;
            min-width: 5rem;
            .image {
                width: 100%;
                height: 100%;
            }
        }
        .wrapperContent {
            width: 70%;
            padding-left: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .name {
                font-size: 1.4rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                display: block;
                display: -webkit-box;
                max-width: 17rem;
                line-height: 1;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                /* overflow: hidden; */
                text-overflow: ellipsis;
                word-break: break-all;
            }

            .constructionName {
                padding: 2px 0;
                font-size: 1.2rem;
                color: #8e8e8e;
            }
            .subTitle {
                font-size: 1.2rem;
                color: #8e8e8e;
            }
        }
        .button {
            display: flex;
            align-items:center;
            .wrapperbtn {
                display: flex;
                &:last-child {
                    padding-left: 1rem;
                }
            }
            .amount {
                font-size: 1.3rem;
                display: block;
                // padding: 0.5rem 1rem 0 0;
                @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                font-size: 1rem;
                width:12rem;
            }
            }
            .MuiIconButton-root {
                background-color: #f4f4f4 !important;
                padding: 1.2rem !important;
            }
        }
    }
    .HistoryInventory:last-child {
        border-bottom:none;
        }
    .viewMore {
        text-align: center;
        padding-top: 1.6rem;
        &__text {
            font-size: 1.6rem;
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => p.theme.colors.secondary};
            text-decoration: underline;
        }
        &__icon {
            margin-left: 0.5rem;
            padding-top: 0.5rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            display: none;
        }
    }
`;
