import { StyledOverTimeCalendar } from ".";
import { Calendar, momentLocalizer, View } from "react-big-calendar";
import moment from "moment";
import React, { Children, cloneElement, useEffect, useState } from "react";
import { Avatar, Box } from "@mui/material";
import { StyledOverTimeEventContent } from ".";
import { getMonthMoment, linkImage } from "@utils";
import clsx from "clsx";
import { selectAuth, useAppSelector } from "@redux";

const localizer = momentLocalizer(moment);

interface IProps {
  dataListOT?: any;
  handleOpenModal: (date: any, id: any) => void;
  handleOpenModalId: (date: any, id: any, groupId: any) => void;
  handleCloseToggle: (date: any, id: any) => void;
  handleViewDetail: ({ otId, userId, status }) => void;
  month?: any;
}
// const TouchCellWrapper = ({ children, value, onSelectSlot, onSelectEvent }) =>
//     cloneElement(Children.only(children), {
//         onClickCapture: (e) => {
//             e.stopPropagation();
//             e.preventDefault();
//             onSelectSlot({ action: "click", slots: [value] });
//             onSelectEvent({ action: "click", slots: [value] });
//         },
//         style: {
//             className: `${children}`,
//         },
//     });
const TouchCellWrapper = ({ children, value, onSelectSlot, onSelectEvent }) => {
  let startX = 0;
  let startY = 0;

  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
    startY = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    const deltaX = Math.abs(e.touches[0].clientX - startX);
    const deltaY = Math.abs(e.touches[0].clientY - startY);

    if (deltaY > deltaX) {
      e.stopPropagation();
    }
  };

  return cloneElement(Children.only(children), {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onClick: () => {
      onSelectSlot({ action: "click", slots: [value] });
      onSelectEvent({ action: "click", slots: [value] });
    },
    style: {
      className: `${children}`,
    },
  });
};

export const OverTimeCalendar = (props: IProps) => {
  const [getId, setGetId] = useState<any>();
  const [groupId, setGroupId] = useState<any>();
  const { dataListOT, /*handleOpenModal,*/ month, handleOpenModalId } = props;
  const dataListOTExpanded = expandOvertimeRequests(dataListOT);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [calendarView, setCalendarView] = useState<View>(window.innerWidth < 768 ? "agenda" : "month");

  useEffect(() => {
    const handleResize = () => {
      setCalendarView(window.innerWidth < 768 ? "agenda" : "month");
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      handleOpenModalId(
        {
          startDate: selectedEvent.startDate,
        },
        getId,
        groupId
      );
    }
  }, [selectedEvent]);

  const onSelectEvent = (event, id) => {
    if (getMonthMoment(event.startDate) === getMonthMoment(moment(month).format("YYYY-MM-DD"))) setSelectedEvent({ ...event, id });
  };
  const onSelectSlot = ({ action /*, slots*/ }) => {
    if (action === "click") {
      //if (getMonthMoment(moment(slots[0]).format("YYYY-MM-DD")) === getMonthMoment(moment(month).format("YYYY-MM-DD")))
      //    handleOpenModal(
      //        {
      //            startDate: slots,
      //        },
      //        getId,
      //        groupId
      //    );
      setGetId("");
      setGroupId("");
    }
    return false;
  };

  const Event = (event: any) => {
    const ref = React.useRef(null);
    const auth = useAppSelector(selectAuth);
    const userInfo = auth.userInfo;

    //        const handleClick = (event) => {
    //            const userId = event.currentTarget.dataset.userId;
    //            const groupId = event.currentTarget.dataset.groupId;
    //            //const overtimeId = event.currentTarget.dataset.overtimeId;
    //            //const from = event.currentTarget.dataset.from;
    //
    //            setGetId(userId);
    //            setGroupId(groupId);
    //
    //            //handleOpenModal(
    //            //    {
    //            //        startDate: [from],
    //            //    },
    //            //    overtimeId,
    //            //    //groupId
    //            //);
    //        };

    return (
      <Box sx={{ width: "100%", height: "100%" }} ref={ref}>
        <StyledOverTimeEventContent>
          <div className="groupUser">
            <div className="user">
              {event.event?.users &&
                event.event?.users?.map((user) => {
                  if (!user.overtimes) return null;
                  return (
                    <div key={user.id} className={clsx(user.id === userInfo?.id ? "mine" : "", "wrapperAvatar")} onClick={undefined /*handleClick*/} data-user-id={user.id} data-group-id={user.overtimes[0].group_id} data-from={user.overtimes[0]?.from} data-overtime-id={user.overtimes[0]?.id}>
                      <div className="content">
                        <Avatar
                          src={linkImage(
                            user?.avatar?.path
                          )}
                          alt="avatar"
                        />
                        <span className="name">
                          {user?.last_name}
                          {user?.first_name}
                        </span>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </StyledOverTimeEventContent>
      </Box>
    );
  };
  return (
    <StyledOverTimeCalendar>
      <Calendar
        localizer={localizer}
        events={dataListOTExpanded}
        startAccessor="startDate"
        endAccessor="endDate"
        defaultDate={month}
        date={month}
        components={{
          event: Event,
          dateCellWrapper: (props) => (
            <TouchCellWrapper
              // children={undefined}
              children="selectable"
              value={undefined}
              {...props}
              onSelectSlot={onSelectSlot}
              onSelectEvent={(e) => onSelectEvent(e, getId)}
            />
          ),
          agenda: {
            time: () => null,
          },
        }}
        formats={{
          agendaDateFormat: (date, culture, localizer) =>
            localizer!.format(date, "ddd DD", culture),
        }}
        selectable={true}
        onSelectSlot={onSelectSlot}
        onSelectEvent={(e) => onSelectEvent(e, getId)}
        view={calendarView}
        views={["month", "agenda"]}
        longPressThreshold={250}
      // showAllEvents={true}
      />
      {/* <div style={{ height: "500px", width: "100%" }}></div> */}
    </StyledOverTimeCalendar>
  );
};

function expandOvertimeRequests(dataListOT) {
  if (!dataListOT) return [];

  // Helper function to convert a date string (YYYY-MM-DD) to a Date object
  const parseDate = (dateStr) => new Date(dateStr);

  // Helper function to format a Date object to YYYY-MM-DD string
  const formatDate = (date) =>
    date.toISOString().split("T")[0];

  // Loop through each day in the dataListOT
  for (let i = 0; i < dataListOT.length; i++) {
    const currentDay = dataListOT[i];

    // Loop through each user in the current day
    currentDay.users?.forEach(user => {
      // Loop through each overtime request for the user
      user.overtimes?.forEach(overtime => {
        const fromDate = parseDate(overtime.from);
        const toDate = parseDate(overtime.to);

        // If the overtime spans multiple days
        if (fromDate < toDate) {
          const nextDay = new Date(fromDate);

          // Loop through each day covered by this overtime request
          while (nextDay < toDate) {
            nextDay.setDate(nextDay.getDate() + 1);
            const nextDayStr = formatDate(nextDay);

            // Ensure the next day exists in the current month
            const nextDayIndex = dataListOT.findIndex(day => day.startDate === nextDayStr);
            if (nextDayIndex !== -1) {
              const nextDayData = { ...dataListOT[nextDayIndex] };
              nextDayData.users = [...nextDayData.users];

              // Find if the user already exists on the next day
              let userOnNextDay = nextDayData.users.find(u => u.employee_id === user.employee_id);

              if (!userOnNextDay) {
                // If the user does not exist on this day, create a copy of the user
                userOnNextDay = { ...user, overtimes: [] };
                nextDayData.users.push(userOnNextDay);
              }

              // Check if the overtime already exists for this user on this day
              const overtimeExists = userOnNextDay.overtimes.some(ot => ot.id === overtime.id);

              if (!overtimeExists) {
                // Add the overtime object to the user's overtimes for the next day
                userOnNextDay.overtimes.push(overtime);
              }
            }
          }
        }
      });
    });
  }

  return dataListOT;
}
