import React from "react";
import { TechnicalDocumentsView } from "@components";

export const TechnicalDocuments = () => {
    return (
        <div>
            <TechnicalDocumentsView />
        </div>
    );
};
