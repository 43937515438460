import styled from "styled-components";

export const StyledModal = styled.div`
    outline: none;

    .edit-modal__container {
        background-color: #ffffff;
        min-width: 31vw;
        overflow-x: auto;
        padding: 4rem;
        border-radius: 1.6rem;
    }
`;
