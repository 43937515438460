export const EyeCloseIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.1083 7.8916L7.8916 12.1083C7.34994 11.5666 7.0166 10.8249 7.0166 9.99994C7.0166 8.34994 8.34994 7.0166 9.99994 7.0166C10.8249 7.0166 11.5666 7.34994 12.1083 7.8916Z"
                    stroke="#656565"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.8501 4.8084C13.3918 3.7084 11.7251 3.1084 10.0001 3.1084C7.05845 3.1084 4.31678 4.84173 2.40845 7.84173C1.65845 9.01673 1.65845 10.9917 2.40845 12.1667C3.06678 13.2001 3.83345 14.0917 4.66678 14.8084"
                    stroke="#656565"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.0166 16.2751C7.9666 16.6751 8.97493 16.8917 9.99993 16.8917C12.9416 16.8917 15.6833 15.1584 17.5916 12.1584C18.3416 10.9834 18.3416 9.0084 17.5916 7.8334C17.3166 7.40006 17.0166 6.99173 16.7083 6.6084"
                    stroke="#656565"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.925 10.5833C12.7083 11.7583 11.75 12.7166 10.575 12.9333"
                    stroke="#656565"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.89175 12.1084L1.66675 18.3334"
                    stroke="#656565"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3334 1.66675L12.1084 7.89175"
                    stroke="#656565"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
