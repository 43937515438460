import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { technicalDocumentsApi } from "src/Api/technicalDocuments";
import { technicalDocumentType } from "src/types";

interface IState {
    loadingTechnicalDocuments: boolean;
    technicalDocuments: technicalDocumentType[];
    quantity: number;
    error?: string;
}
const initialState: IState = {
    loadingTechnicalDocuments: false,
    technicalDocuments: [],
    quantity: 0
};

// Async thunk for fetching documents
export const fetchTechnicalDocuments = createAsyncThunk(
    "technicalDocuments/fetchTechnicalDocuments",
    async (page: number, { rejectWithValue }) => {
        try {
            const response = await technicalDocumentsApi.getTechnicalDocuments();
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || "An unknown error occurred");
        }
    }
);

export const deleteTechnicalDocument = createAsyncThunk(
    "technicalDocuments/deleteTechnicalDocument",
    async (id: string, { rejectWithValue }) => {
        try {
            await technicalDocumentsApi.deleteTechnicalDocuments({
                box_document_id: [id],
            });
        } catch (err: any) {
            return rejectWithValue(err.response?.data || "An unknown error occurred");
        }
    }
);

export const readTechnicalDocument = createAsyncThunk(
    "technicalDocuments/readTechnicalDocument",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await technicalDocumentsApi.readTechnicalDocuments({
                box_document_id: id,
            });
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || "An unknown error occurred");
        }
    }
);

const technicalDocumentsSlice = createSlice({
    name: "technicalDocuments",
    initialState,
    reducers: {
        updateQuantity: (state, action: { payload: number }) => {
            state.quantity = action.payload;
        },
        resetTechnicalDocuments: (state) => {
            state.technicalDocuments = [];
            state.quantity = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTechnicalDocuments.pending, (state) => {
                state.loadingTechnicalDocuments = true;
                state.error = undefined;
            })
            .addCase(fetchTechnicalDocuments.fulfilled, (state, action) => {
                state.loadingTechnicalDocuments = false;
                state.technicalDocuments = action.payload.data;
                state.quantity = action.payload.length;
            })
            .addCase(fetchTechnicalDocuments.rejected, (state, action) => {
                state.loadingTechnicalDocuments = false;
                state.error = action.payload as string;
            });
    },
});

export const selectTechnicalDocuments = (state: RootState) => state.technicalDocuments.technicalDocuments;
export const selectDocumentsLoading = (state: RootState) => state.technicalDocuments.loadingTechnicalDocuments;
export const selectDocumentsError = (state: RootState) => state.technicalDocuments.error;
export const { updateQuantity, resetTechnicalDocuments } = technicalDocumentsSlice.actions;
export default technicalDocumentsSlice.reducer;