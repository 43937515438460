import { useCallback } from 'react';

const useConstructionSites = () => {
    const updateConstructionHistory = useCallback((siteId) => {
        if (Array.isArray(siteId)) {
            siteId.map((id) => {
                updateConstructionHistory(id);
            });
            return;
        }
        if (typeof siteId !== "number" && typeof siteId !== "string") return;

        const constructionSiteHistory = JSON.parse(localStorage.getItem("constructionSiteHistory") || "[]");
        const siteIdStr = siteId.toString();

        const index = constructionSiteHistory.indexOf(siteIdStr);
        if (index !== -1) {
            constructionSiteHistory.splice(index, 1);
        }
        constructionSiteHistory.unshift(siteIdStr);
        localStorage.setItem("constructionSiteHistory", JSON.stringify(constructionSiteHistory));
    }, []);

    const sortConstructionSites = useCallback((input) => {
        let constructions;
        let isWrapped = false;

        if (Array.isArray(input)) {
            constructions = input;
        } else if (input && Array.isArray(input.constructions)) {
            constructions = input.constructions;
            isWrapped = true;
        } else {
            return input;
        }

        const constructionSiteHistory = JSON.parse(localStorage.getItem("constructionSiteHistory") || "[]");

        const sortedConstructions = constructions.sort((a, b) => {
            if(!a || !a.id) return 1;
            if(!b || !b.id) return -1;
            const indexA = constructionSiteHistory.indexOf(a.id.toString());
            const indexB = constructionSiteHistory.indexOf(b.id.toString());

            if (indexA === -1 && indexB === -1) {
                return 0;
            }
            if (indexA === -1) {
                return 1;
            }
            if (indexB === -1) {
                return -1;
            }
            return indexA - indexB;
        });

        if (isWrapped) {
            return {
                ...input,
                constructions: sortedConstructions
            };
        } else {
            return sortedConstructions;
        }
    }, []);

    return { updateConstructionHistory, sortConstructionSites };
};

export default useConstructionSites;
