import * as React from "react";
import { Avatar, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Box } from "@mui/system";
import { StyledOTApprovalProcess } from "./styles";
import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import clsx from "clsx";
import { Formik } from "formik";
import { AppTextArea, PassChangeButton } from "..";
import { linkImage, overtimeApprovalStatus, theme } from "@utils";
import { selectAuth, useAppSelector } from "@redux";
import checkIcon from "@assets/checkIcon.png";
import xIcon from "@assets/xIcon.png";

interface IProps {
  showForm?: boolean;
  data?: any;
  handleOnSubmit?: (values: any, resetForm: any) => void;
}
const roleNames = [
  {
    id: 1,
    name: "従業員",
  },
  {
    id: 2,
    name: "チームリーダー",
  },
  {
    id: 3,
    name: "部長",
  },
  {
    id: 4,
    name: "大統領",
  },
  {
    id: 5,
    name: "会計",
  },
];
export const OTApprovalProcess = (props: IProps) => {
  const { showForm = false, data, handleOnSubmit } = props;
  const auth = useAppSelector(selectAuth);
  const { userInfo } = auth;

  return (
    <StyledOTApprovalProcess>
      {data?.confirmation?.map((e, index) => {
        return (
          <div className="process" key={index}>
            <div className="process__top">
              {e?.status === 2 ? (
                <span
                  className="process__circle"
                  style={{ backgroundColor: `${theme.colors.red05}` }}
                ></span>
              ) : (
                <span
                  className="process__circle"
                  style={{ backgroundColor: `${theme.colors.green07}` }}
                ></span>
              )}

              <div className="process__content">
                <p className="process__title">
                  {
                    roleNames[
                      roleNames.findIndex((item) => item.id === e?.role)
                    ]?.name
                  }
                </p>
              </div>
            </div>

            <div className="process__bottom">
              <div className="process__divider"></div>
              <Box
                sx={{
                  width: "90%",
                  paddingLeft: "2rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="process__container">
                  <div className="process__status">
                    {e?.status === OT_STATUS_OK && (
                      <div className="process__status-checked">
                        <span className="text">OK</span>
                        <img
                          className="approvalIcon"
                          src={checkIcon}
                          alt="icon"
                        />
                      </div>
                    )}
                    {e?.status === OT_STATUS_REJECT && (
                      <div className="process__status-unChecked">
                        <span className="text">NG</span>
                        <img
                          className="approvalIcon"
                          src={xIcon}
                          alt="icon"
                        />
                      </div>
                    )}
                    {e?.status === OT_STATUS_PENDING && (
                      <div className="process__status-pending">
                        <span className="text">未決</span>
                      </div>
                    )}
                  </div>

                  <div className="process__person">
                    <Avatar src={linkImage(e?.avatar)} />
                    <div>
                      <span className="process__person-name">{e?.name}</span>
                      <div className="process__date">
                        <div>承認日時</div>
                        <div className="process__date-time">{e?.date}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="note">{e?.comment}</p>

                {/* <div className="process__status">
                  {e?.status === OT_STATUS_OK && (
                    <div className="process__status-checked">
                      <span className="text">OK</span>
                      <img
                        className="icon"
                        src={checked_status_icon}
                        alt="icon"
                      />
                    </div>
                  )}
                  {e?.status === OT_STATUS_REJECT && (
                    <div className="process__status-unChecked">
                      <span className="text">NG</span>
                      <img
                        className="icon"
                        src={unchecked_status_icon}
                        alt="icon"
                      />
                    </div>
                  )}
                  {e?.status === OT_STATUS_PENDING && (
                    <div className="process__status-pending">
                      <span className="text">未決</span>
                    </div>
                  )}
                </div> */}
              </Box>
            </div>
          </div>
        );
      })}
      {showForm && (
        <div>
          <div className="process">
            <div className="process__top">
              <span
                className="process__circle"
                style={{ backgroundColor: "#DEDEDE" }}
              ></span>
              <div className="process__content">
                {/* <p className="process__title">{e.label}</p> */}
              </div>
            </div>
            <div className="process__bottom">
              <div className="process__divider"></div>
              <Box
                sx={{
                  width: "90%",
                  paddingLeft: "2rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div className="process__person">
                    <Avatar src={linkImage(userInfo?.avatar?.path)} />
                    <span className="process__person-name">
                      {userInfo?.last_name}
                      {userInfo?.first_name}
                    </span>
                  </div>
                </div>
              </Box>
            </div>
          </div>
          <div className="processForm">
            <Formik
              initialValues={{ inputValue: "", radioValue: "1" }}
              onSubmit={(values, { resetForm }) => {
                handleOnSubmit &&
                  handleOnSubmit(
                    {
                      ...values,
                    },
                    resetForm
                  );
              }}
              validationSchema={overtimeApprovalStatus}
            >
              {({
                handleSubmit,
                values,
                errors,
                touched,
                handleBlur,
                setFieldValue,
                handleChange,
              }) => {
                const handleOnChange = (e) => {
                  setFieldValue("inputValue", e);
                };
                return (
                  <div>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="radioValue"
                      value={values.radioValue}
                      onChange={handleChange("radioValue")}
                    >
                      <FormControlLabel
                        value={"1"}
                        className={clsx(
                          values.radioValue === "1" ? "active" : ""
                        )}
                        control={<Radio />}
                        label={
                          <p>
                            <span className="title">承認</span>
                            <img
                              className="icon"
                              src={checked_status_icon}
                              alt="icon"
                            />
                          </p>
                        }
                      />
                      <FormControlLabel
                        value={"2"}
                        control={<Radio />}
                        label={
                          <p>
                            <span className="title">戻す</span>
                            <img
                              className="icon"
                              src={unchecked_status_icon}
                              alt="icon"
                            />
                          </p>
                        }
                        className={clsx(
                          values.radioValue === "2" ? "active" : ""
                        )}
                      />
                      {values.radioValue === "2" ? (
                        <Box sx={{ width: "100%" }}>
                          <AppTextArea
                            name="inputValue"
                            handleChange={(e) => handleOnChange(e)}
                            value={values.inputValue}
                            error={errors.inputValue}
                            touched={touched.inputValue}
                            handleBlur={handleBlur("inputValue")}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                    </RadioGroup>
                    <Box sx={{ marginTop: "2rem" }}>
                      {/* {" "} */}
                      <PassChangeButton onClick={() => handleSubmit()}>更新</PassChangeButton>
                      {/* <AppButton onClick={handleSubmit} text="更新" /> */}
                    </Box>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </StyledOTApprovalProcess>
  );
};
