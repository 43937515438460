import React from "react";

import { VehicleDetailView } from "@components";

export const VehiclesDetail = () => {
    return (
        <>
            <VehicleDetailView />
        </>
    );
};
