import styled from "styled-components";

export const StyledTextAreaNumber = styled.div`
    width: 100%;
    .input {
        &__wrapper {
            width: 95%;
            display:flex;
            // align-items:center;
            // margin-left:-10px;
            .MuiFormControl-root {
                & > textarea {
                    resize: none;
                    padding: 10px 12px;
                    border-radius: 50px;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
                    font-size: 14px;
                    color: ${(p) => p.theme.colors.black01};
                    background-color: ${(p) => p.theme.colors.white02};

                    border: 1px solid ${(p) => p.theme.colors.gray03};
                    outline-color: #253ca5;
                    &:hover {
                        border-color: #333;
                    }
                }

                .Mui-disabled {
                    border-radius: 0;
                    border-right: 1px solid rgba(0, 0, 0, 0.26);
                    margin-right: 2px;
                }
                .MuiOutlinedInput-input {
                    border: none;
                }

                &:hover {
                    fieldset: {
                        border-color: "yellow";
                    }
                }
            }
            &__label {
                text-align: left;
                padding-bottom: 5px;
                color: ${(p) => p.theme.colors.gray1};
                font-size: 14px;
            }
        }
    }
`;
