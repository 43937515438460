import React from "react";
import { ErrorContentView } from "@components";

export const PageNotFound = () => {
    return (
        <div>
            <ErrorContentView />
        </div>
    );
};
