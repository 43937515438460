import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { categoriesApi, vehicleApi } from "@api";

import { RootState } from ".";
import { API_CATEGORY_TYPE_VEHICLE } from "@configs";
import { VehicleCategoryType } from "src/Interfaces/vehicle";

export enum VehicleRequestsEnum {
    LIST = "list",
    CATEGORIES = "categories",
}
interface IState {
    processing: boolean;
    categories?: any;
    dataResult?: any;
    selectedDate?: any;
    vehicleName?: string;
    idConstuctionSearch?: string;
    vehicleCategories: VehicleCategoryType[];
    ongoingRequests: VehicleRequestsEnum[];
}

const initialState: IState = {
    processing: false,
    categories: undefined,
    dataResult: undefined,
    selectedDate: undefined,
    vehicleName: "",
    idConstuctionSearch: "",
    vehicleCategories: [],
    ongoingRequests: [],
};

export const fetchVehiclesData = createAsyncThunk(
    "vehicles/fetchVehiclesData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await vehicleApi.getVehiclesListProducts({ category: "", name: "" });
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchVehiclesCategories = createAsyncThunk(
    "vehicles/fetchVehiclesCategories",
    async (_, { rejectWithValue }) => {
        try {
            const response = await categoriesApi.getCategories({type: API_CATEGORY_TYPE_VEHICLE});
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

const vehiclesSlice = createSlice({
    name: "vehicles",
    initialState: initialState,
    reducers: {
        setVehiclesCategory: (state, action: { payload: any }) => {
            state.dataResult = action.payload;
        },
        selectDateRange: (state, action: { payload: any }) => {
            state.selectedDate = action.payload;
        },
        setSelectedDateVehicle: (state, action: { payload: any }) => {
            state.selectedDate = action.payload;
        },
        selectVehicleName: (state, action: { payload: string }) => {
            state.vehicleName = action.payload;
        },
        setIdConstuctionSearch: (state, action: { payload: string }) => {
            state.idConstuctionSearch = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVehiclesData.pending, (state) => {
            state.processing = true;
            if (!state.ongoingRequests.includes(VehicleRequestsEnum.LIST)) {
                state.ongoingRequests.push(VehicleRequestsEnum.LIST);
            }
        });
        builder.addCase(fetchVehiclesData.fulfilled, (state, action) => {
            state.processing = false;
            state.categories = action.payload;
            state.ongoingRequests = state.ongoingRequests.filter((request) => request !== VehicleRequestsEnum.LIST);
        });
        builder.addCase(fetchVehiclesData.rejected, (state) => {
            state.processing = false;
            state.ongoingRequests = state.ongoingRequests.filter((request) => request !== VehicleRequestsEnum.LIST);
        });
        builder.addCase(fetchVehiclesCategories.pending, (state) => {
            if (!state.ongoingRequests.includes(VehicleRequestsEnum.CATEGORIES)) {
                state.ongoingRequests.push(VehicleRequestsEnum.CATEGORIES);
            }
            state.processing = true;
        });
        builder.addCase(fetchVehiclesCategories.fulfilled, (state, action) => {
            state.ongoingRequests = state.ongoingRequests.filter((request) => request !== VehicleRequestsEnum.CATEGORIES);
            state.processing = false;
            state.vehicleCategories = action.payload.data;
        });
        builder.addCase(fetchVehiclesCategories.rejected, (state) => {
            state.ongoingRequests = state.ongoingRequests.filter((request) => request !== VehicleRequestsEnum.CATEGORIES);
            state.processing = false;
        });
    }
});

export const selectVehicle = (state: RootState) => state.vehicle;

export const { setVehiclesCategory, selectVehicleName, selectDateRange, setSelectedDateVehicle, setIdConstuctionSearch } =
    vehiclesSlice.actions;

export default vehiclesSlice.reducer;
