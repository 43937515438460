import { flatArrayToString } from "@utils";
import axiosClient from "./axiosClient";

export interface ICreateNewOTParams {
    from: string | string[];
    to: string | string[];
    note?: any;
    group_id: any;
    construction_id: any;
    title: string;
    overtime_reason_id: any;
}
export interface IUpdateOTParams {
    id: any;
    from: string;
    to: string;
    overtime: any;
    note?: any;
    overtime_reason_id: any;
}
export interface IConfirmApprovalStatusOTParams {
    overtime_id: any;
    status: any;
    comment: string | null;
}
export const overtimeApi = {
    getListOT: (params: any, arrayGroupId: any) => {
        let url = `/api/overtime?`;
        if (arrayGroupId?.length) {
            url += flatArrayToString("groupId", arrayGroupId);
        }
        if (params.userId) {
            url += "&userId=" + params.userId;
        }
        return axiosClient.get(url);
    },
    getListOTCalendar: (params: any, body: any) => {
        let url = `/api/overtimes/get-overtime-in-month?month=${body.month}`;
        if (body?.overtimeReasonId?.length) {
            url += flatArrayToString("overtimeReasonId", body?.overtimeReasonId);
        }
        if (params.userId) {
            url += "&userId=" + params.userId;
        }
        return axiosClient.get(url, {
            headers: {
                "cache-control": "no-cache",
            },
        });
    },
    getListOTModal: (body: any) => {
        const url = `/api/group-users/overtime?date=${body.date || ""}&id=${body.userId || ""}&group_id=${body.groupId || ""}&status=${body.status ?? ""}`;
        return axiosClient.get(url, {
            headers: {
                "cache-control": "no-cache",
            },
        });
    },
    getReasonsOT: () => {
        const url = `/api/overtime-reasons`;
        return axiosClient.get(url, {
            headers: {
                "cache-control": "no-cache",
            },
        });
    },
    createNewOT: (params: ICreateNewOTParams) => {
        const url = `/api/overtime/storeAll`;
        return axiosClient.post(url, params);
    },
    viewOTDetail: (params: any) => {
        const url = `/api/overtime/${params.id}`;
        return axiosClient.get(url);
    },
    updateOT: (params: IUpdateOTParams) => {
        const url = `/api/overtime/update/${params.id}}`;
        return axiosClient.put(url, params);
    },
    confirmApprovalStatus: (params: IConfirmApprovalStatusOTParams) => {
        const url = "/api/overtime/confirm";
        return axiosClient.post(url, params);
    },
    getListReasons: () => {
        const url = "/api/overtime-reasons";
        return axiosClient.get(url);
    },
    changeOverTimesType: (body: any) => {
        const url = "/api/overtime/change-to-official";
        return axiosClient.post(url, body);
    },
    deleteOverTimes: (id: any) => {
        const url = `/api/overtime/delete-overtime?overtime_id=${id}`;
        return axiosClient.post(url);
    },
    getListByTypeFilter: (body: any) => {
        let url = `/api/overtime/list?month=${body?.month}`;
        if (body?.typeTitles?.length) {
            url += flatArrayToString("typeTitles", body?.typeTitles);
        }
        if (body.userId) {
            url += "&userId=" + body.userId;
        }
        return axiosClient.get(url, {
            headers: {
                "cache-control": "no-cache",
            },
        });
    },
    getRoleOfUser: (id: any) => {
        const url = `/api/overtimes/check-role-section-chief/${id}`;
        return axiosClient.get(url);
    },
    createNewOTVacation: (body: any) => {
        const url = `/api/overtime/storeAll`;
        return axiosClient.post(url, body);
    },
};
