import styled from "styled-components";

export const StyledAlert = styled.div`
    padding: 0 2rem;
    .leftSpace {
     width:10rem;
    }
    .buttonContainer {
      display:flex;
      justify-content:center;
    }
    .iconAndText {
        display:flex;
        justify-content:start;
        flex-direction:row-reverse;
        align-items:center;
        gap:2rem;

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                flex-direction:column;
                justify-content:center;
                gap:1rem;
            }
        &__text {
            font-size: 2.6rem;
            text-align: center;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            color: ${(p) => p.theme.colors.black01};

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-bottom:1rem;
            }

        }
        &__space {
          width:2rem;

          @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display:none;
            }

        }

    }
    .alert {
        &__content {
            position: relative;
            background-color: ${(p) => p.theme.colors.white00};
            border-radius: 6px;
            padding: 3rem 0rem 3rem 0rem;
            z-index: 1;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding: 3rem 4.5rem;
            }

        }
        &__goodIcon {
        height:15rem;
        width:15rem;
        object-fit:cover;



        &__cancelIcon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
        }
        &__cancelIconBg {
            position: absolute;
            right: 0;
            top: 0;
        }
        &__groupIcon {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                left: 20%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                left: 0;
                width: 95%;
            }
        }
        &__text {
            font-size: 2.6rem;
            text-align: center;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            color: ${(p) => p.theme.colors.black01};
        }
        &__icon {
            padding-top: 2.2rem;
            display: flex;
            justify-content: center;
        }
    }
`;
export const StyledAlertConfirm = styled.div`
    .alertConfirm__wrapper {
        min-width: 30rem;
    }
    .alert__info {

        // position: relative;
        // overflow: hidden;
        // z-index: 1;
        // padding: 0 2rem;
        .alert__bg {
            position: absolute;
            top: 0;
            left: 1.5rem;
            z-index: -1;
            width: 90%;
            img {
                width: 100%;
            }
        }
    }
    .info {
        &__content {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__icon {
            // border: 1px solid black;
            border-radius: 6px;
            height: 11rem;
            width: 11rem;
            object-fit:cover;
            background-color: white;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
              height: 9rem;
              width: 9rem;
            }
        }

        &__text-date {
            min-width:30rem;
            // width:100%;
            margin-left:1rem;
            display: flex;
            justify-content: space-between;
            align-items:start;
            border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
            height:4rem;

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
              border-bottom:none;
              box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.lightgray09};
              display: block;
              height:6rem;
              margin-left:0.5rem;
              min-width:auto;
            }

        }

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-align: right;
            &-main {
                font-size: 1.3rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                color: ${(p) => p.theme.colors.black01};
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // word-break: break-all;
                max-width: 18rem;
                // margin-bottom: 1rem;
                margin-right:0.5rem;
            }
            &-sub {
                font-size: 1rem;
                color: ${(p) => p.theme.colors.black01};
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 18rem;
            }
            &-sub-quantity {
                font-size: 1rem;
                color: ${(p) => p.theme.colors.black01};
                display: flex;
                align-items:center;
                // display: -webkit-box;
                // line-height: 1.2;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // word-break: break-all;
                max-width: 18rem;
            }
            &-product {
                max-width: 10rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                font-size: 1.6rem;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            &-amount {
                display: grid;
                /* justify-content: flex-end; */
                font-size: 1.6rem;
                grid-template-columns: 1fr auto auto;
                grid-column-end: auto;
                gap: 1rem;
                /* column-gap: 1rem; */
                padding-top: 0.5rem;
            }
            .takenAmount {
                color: #81e1ad;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 7rem;
                height: 20px;
            }
            .addedAmount {
                color: #ff2e00;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 7rem;
                height: 20px;
            }
        }
    }
    .alert__confirm {
        padding: 2rem;
        background-color: ${(p) => p.theme.colors.white00};
        border-radius: 6px;
        &-title {
            margin-top:1.5rem;
            text-align: center;
            font-size: 1.8rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            color: ${(p) => p.theme.colors.black01};

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
              display:none;

            }
        }

        &-title-medium {
            display:none;

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display:block;
            margin-top:1rem;
            text-align: center;
            font-size: 1.8rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            color: ${(p) => p.theme.colors.black01};

            }
        }


        &-line {
            margin-top: 2rem;
        }
        &-btn {
            display: grid;
            padding-top: 2rem;
            gap: 10px;
            grid-template-columns: auto auto;

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display:block;

            }

            .button {
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin-bottom:1rem;

            }

            }


            // .button {
            //     font-size: 1.6rem;
            //     width: 100%;
            //     text-align: center;
            //     padding: 1.5rem 0;
            //     color: #d4d4d4;
            //     border: 1px solid #d4d4d4;
            //     border-radius: 1rem;
            //     font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            //     cursor: pointer;
            //     &:hover {
            //         background-color: ${(p) => p.theme.colors.primary};
            //         color: ${(p) => p.theme.colors.darkBlack};
            //     }
            // }
        }
    }
`;
