import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
// import { Avatar, Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import moment from "moment";
import { updateSnackBar, useAppSelector, selectAuth, fetchOvertimes /*, useAppDispatch */ } from "@redux";

// import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { AppSelect, AppModal, AppAlert, CircularLoader, AppDateRangePicker, StyledToggleButton, PassChangeButton, LogoutButton, GrayButton } from "..";
import { StyledNewOT, OTApprovalProcess } from ".";
import { appRouteEnums, OVERTIME_TYPE_VALUE } from "@enums";
import { initOTAllType, OTFormBereavementSchema, OTFormDefault, OTFormHalfDaySchema, OTFormHolidayWorkSchema, OTFormNightToDaySchema, OTFormOvertimeSchema, OTFormVacationSchema, overtimeNewTitle, theme, useConstructionSites, } from "@utils";
import { ICreateNewOTParams, IUpdateOTParams, overtimeApi, timesheetApi } from "@api";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
// import clsx from "clsx";
import dayjs from "dayjs";
import OTNewVacation from "./OTNewVacation";
import OTNewHoliday from "./OTNewHoliday";
import OTNewHalfDay from "./OTNewHalfDay";
import OTNewNightShift from "./OTNewNightShift";
import OTNewOvertime from "./OTNewOvertime";
import OTNewDayToNight from "./OTNewDayToNight";
import OTNewSpecialLeave from "./OTNewSpecialLeave";
import OTNewBereavement from "./OTNewBereavement";
import OTNewAbsense from "./OTNewAbsense";
import OTNewTardiness from "./OTNewTardiness";
import OTNewEarlyLeave from "./OTNewEarlyLeave";
import OTNewNightToDay from "./OTNewNightToDay";
import { useDispatch } from "react-redux";
// import { StyledGrayToggleButton } from "../WeeklySchedule/styles";

type DateRangeType = {
  startDate: Date;
  endDate: Date;
}

const optionOthers = [
  {
    name: "その他",
    id: 0,
  },
];
export const NewOT = () => {
  const { id } = useParams<{ id: any }>();
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");

  // redux state
  const userInfo = useAppSelector(selectAuth).userInfo;

  //component states
  const [dateRanges, setDateRanges] = React.useState<DateRangeType[]>([{
    startDate: date ? new Date(date) : new Date(),
    endDate: date ? new Date(date) : new Date(),
  }]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
  const [dataDetail, setDataDetail] = React.useState<any>(null);
  const [listReasons, setListReasons] = React.useState<any>();
  const [optionGroups, setOptionGroup] = React.useState<any>();
  const [checkTypeTextOT, setCheckTypeTextOT] = React.useState<any>();
  const [isSectionChief, setIsSectionChief] = React.useState<boolean>();
  const [valuesTitle, setValuesTitle] = React.useState<any>();
  const [updateTypeOt] = React.useState<any>(false);
  const [changeToOfficial, setChangeToOfficial] = React.useState(false);
  const [optionConstractors, setOptionConstractors] = React.useState<any>();

  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateConstructionHistory, sortConstructionSites } = useConstructionSites();

  const [initialValuesPackage, setInitialValuesPackage] = React.useState(initOTAllType);
  const enableCheckTypeOfOverTime = history.location.pathname.includes("edit");
  React.useEffect(() => {
    fetchListReasons();
    return () => {
      // clean up
    };
  }, []);
  React.useEffect(() => {
    fetchListConstructions();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    fetchListConstractors();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    fetchListGroupsByUser();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    fetchRoleUser();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    if (id) {
      fetchViewDetail(id);
    }
    return () => {
      // do something with cleanup
    };
  }, [id, updateTypeOt]);
  // fetch api group by user
  const fetchListGroupsByUser = async () => {
    if (!id) {
      try {
        const result = await timesheetApi.listGroupsByUser(userInfo?.id);
        setOptionGroup(result.data.data);
      } catch (err) { }
    }
  };
  const fetchRoleUser = async () => {
    try {
      const res = await overtimeApi.getRoleOfUser(userInfo?.id);
      setIsSectionChief(res?.data?.data?.isSectionChief);
    } catch (error) { }
  };
  // fetch api view detail OT
  const fetchViewDetail = async (id: any) => {
    setLoading(true);
    try {
      const responseConstructions = await timesheetApi.getListConstructions();
      setOptionSiteConstructions(sortConstructionSites(responseConstructions.data.data));
      const resultGroups = await timesheetApi.listGroupsByUser(userInfo?.id);
      setOptionGroup(resultGroups.data.data);
      const result = await overtimeApi.getListReasons();
      setListReasons([...result.data.data, ...optionOthers]);
      const res = await overtimeApi.viewOTDetail({
        id,
      });
      if (res.data.data.overtimeData?.employee?.id !== userInfo?.id) {
        history.push(`${appRouteEnums.PATH_OVERTIME}`);
      }
      setDataDetail(res.data.data);
      setLoading(false);
      setDateRanges([{
        startDate: dayjs(res.data.data?.overtimeData?.from, "YYYY-MM-DD").toDate(),
        endDate: dayjs(res.data.data?.overtimeData?.to, "YYYY-MM-DD").toDate(),
      }]);
      setInitialValuesPackage({
        title: res.data.data.overtimeData.title || "",
        reasonType: res.data.data?.overtimeData?.reason?.id || "0",
        phoneNumber: res.data.data?.overtimeData?.phone_number?.toString() || "",
        reasonContent: res.data.data?.overtimeData?.note || "",
        memo: res.data.data?.overtimeData?.memo || "",
        start_time: res?.data?.data?.overtimeData?.start_at || "",
        stop_time: res?.data?.data?.overtimeData?.stop_at || "",
        type_of_overtime: res.data.data?.overtimeData?.type_of_overtime === 0 ? "0" : "1",
        break_time: res.data.data.overtimeData.break_time || "",
        construction: res.data.data.overtimeData.constructionId?.id || "",
        subContractor:
          res.data.data.overtimeData.contractor?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }) || "",
        detail: res.data.data?.overtimeData?.detail || "",
        work_detail: res.data.data.overtimeData.work_detail || "",
        work_time: res.data.data.overtimeData.work_time || "",
        compensatory_leave: res.data.data.overtimeData.compensatory_leave || 0,
        start_at_extra: res?.data?.data?.overtimeData?.start_at_extra || "",
        stop_at_extra: res?.data?.data?.overtimeData?.stop_at_extra || "",
        break_time_extra: res?.data?.data?.overtimeData?.break_time_extra || "",
        work_time_extra: res?.data?.data?.overtimeData?.work_time_extra || "",
        construction_id_extra: res?.data?.data?.overtimeData?.constructionIdExtra?.id || "",
        work_detail_extra: res?.data?.data?.overtimeData?.work_detail_extra || "",
        contractor_id_extra:
          res?.data?.data?.overtimeData?.contractorextra?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }) || "",
        memo_extra: res?.data?.data?.overtimeData?.memo_extra || "",
        family_relationship: res?.data?.data?.overtimeData?.family_relationship || "",
        family_living: `${res?.data?.data?.overtimeData?.family_living}` || "",
      });

      setCheckTypeTextOT(res.data.data?.overtimeData?.type_of_overtime);
    } catch (err: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
      history.push(appRouteEnums.PATH_OVERTIME);
    }
  };
  // fetch api list work type
  const fetchListReasons = async () => {
    if (!id) {
      try {
        const result = await overtimeApi.getListReasons();
        setListReasons([...result.data.data, ...optionOthers]);
      } catch (err) { }
    }
  };
  // fetch api create new OT
  const fetchApiCreateNewOT = async (values: ICreateNewOTParams, resetForm: any) => {
    setLoading(true);
    try {
      const res = await overtimeApi.createNewOTVacation(values);
      setLoading(false);
      setMessage(res.data.message);
      setIsSuccess(true);
      setOpenModal(true);
      resetForm();
    } catch (err: any) {
      setLoading(false);
      setIsSuccess(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
    }
  };
  // fetch api update OT
  const fetchUpdateOT = async (values: IUpdateOTParams) => {
    setLoading(true);
    try {
      // const responseGroups = await timesheetApi.listGroupsByUser(userInfo?.id);
      // setGroups(responseGroups.data.data);
      const res = await overtimeApi.updateOT(values);
      setChangeToOfficial(false);
      setLoading(false);
      setMessage(res.data.message);
      setOpenModal(true);
      setIsSuccess(true);
    } catch (err: any) {
      setChangeToOfficial(false);
      setLoading(false);
      setIsSuccess(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
    }
  };
  // fetch api
  const fetchListConstructions = async () => {
    if (!id) {
      try {
        const result = await timesheetApi.getListConstructions();
        setOptionSiteConstructions(sortConstructionSites(result.data.data));
      } catch (err) { }
    }
  };
  // events
  const handleChangeDateRange = (event, index) => {
    setDateRanges((prev) => {
      const newDateRange = [...prev];
      newDateRange[index] = { startDate: event.startDate, endDate: event.endDate };
      return newDateRange;
    });
  };

  const handleOnSubmit = async (values: any, resetForm: any) => {

    // console.log("values", values);
    if (id) {
      await fetchUpdateOT({
        ...values,
        id: id,
      });
    } else {
      await fetchApiCreateNewOT(values, resetForm);
    }
    dispatchFetchOvertimesForDateRanges();
  };

  const dispatchFetchOvertimesForDateRanges = () => {
    const uniqueMonths: Set<string> = new Set();

    dateRanges.forEach(({ startDate, endDate }) => {
      const currentDate = new Date(startDate);
      currentDate.setDate(1); // Start with the first day of the startDate month

      while (currentDate <= endDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // getMonth() is zero-indexed, so we add 1
        const monthString = `${year}-${month.toString().padStart(2, '0')}`;

        uniqueMonths.add(monthString);

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    });

    setTimeout(() => {
      uniqueMonths.forEach((month) => {
        dispatch(fetchOvertimes({ month, groups: [] }) as any);
      });
    }, 1000);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    isSuccess && history.push(appRouteEnums.PATH_OVERTIME);
    setTimeout(() => {
      window.location.reload(); // This is a bit of a hack, would be better to dispatch a fetchOvertimes action
    }, 250);
  };

  const fetchDeleteOverTimes = async () => {
    setLoading(true);
    try {
      const result = await overtimeApi.deleteOverTimes(id);
      setMessage(result.data.message);
      setIsSuccess(true);
      dispatch(
        updateSnackBar({
          messageType: "success",
          message: result.data.message,
        })
      );
      setLoading(false);
      if (id) {
        history.push(appRouteEnums.PATH_OVERTIME);
      }
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response?.data?.message,
        })
      );
    }
  };
  const handleDelete = () => {
    fetchDeleteOverTimes();
  };

  const fetchListConstractors = async () => {
    // if (!id) {
    try {
      const result = await timesheetApi.getListContractors();
      setOptionConstractors(result.data.data);
      if (result.data.data.suggestedContractors?.length) {
        setInitialValuesPackage({
          ...initialValuesPackage,
          subContractor: result.data.data.suggestedContractors?.map((item) => {
            return {
              label: item.name,
              value: item.id.toString(),
            };
          }),
        });
      } else {
        setOptionConstractors(result.data.data);
      }
    } catch (err) { }
    // }
  };

  const onChangeConstractors = (e, values, setFieldValue) => {
    const listConstractors = e.map((e, i) => {
      return {
        contractor_id: e.value.toString(),
        ...values.subContractor[i],
      };
    });
    setFieldValue("subContractor", listConstractors);
  };

  const onChangeConstractorsExtra = (e, values, setFieldValue) => {
    const listConstractors = e.map((e, i) => {
      return {
        contractor_id: e.value.toString(),
        ...values.subContractor[i],
      };
    });
    setFieldValue("contractor_id_extra", listConstractors);
  };

  const onChangeConstruction = (e, setFieldValue) => {
    setFieldValue("construction", e.value.toString());
  };

  const onChangeConstructionExtra = (e, setFieldValue) => {
    setFieldValue("construction_id_extra", e.value.toString());
  };

  let schema;

  switch (valuesTitle) {
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
      schema = OTFormOvertimeSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
      schema = OTFormBereavementSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
      schema = OTFormHalfDaySchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
      schema = OTFormHolidayWorkSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
      schema = OTFormOvertimeSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
      schema = OTFormNightToDaySchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
      schema = OTFormNightToDaySchema;
      break;
    default:
      schema = OTFormDefault;
      break;
  }

  const removeDateRange = (event, index) => {
    event.preventDefault();
    setDateRanges((prev) => {
      const newDateRange = [...prev];
      newDateRange.splice(index, 1);
      return newDateRange;
    });
  };

  return (
    <StyledNewOT>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading} />
      <AppModal open={openModal} handleClose={handleCloseModal}>
        <AppAlert icon={<img src={hot_yellow_frame} alt="icon" />} text={message} handleClose={handleCloseModal} />
      </AppModal>
      <div className="newOT">
        <div className="newOT__top">
          {/* <p className="newOT__top-icon" onClick={() => history.push(appRouteEnums.PATH_OVERTIME)}>
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p> */}
          <p className="newOT__top-title">私の申請</p>
        </div>
        <Formik
          initialValues={initialValuesPackage}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            updateConstructionHistory(values?.construction);

            const from = dateRanges.length === 1 ? moment(dateRanges[0].startDate).format("YYYY-MM-DD") : dateRanges.map((item) => moment(item.startDate).format("YYYY-MM-DD"));
            const to = dateRanges.length === 1 ? moment(dateRanges[0].endDate).format("YYYY-MM-DD") : dateRanges.map((item) => moment(item.endDate).format("YYYY-MM-DD"));
            const start_at = values.start_time;
            const stop_at = values.stop_time;
            const overtime_reason_id = values.reasonType;
            const note = values.reasonContent ? values.reasonContent : null;
            const title = values.title;
            const memo = values.memo;
            const phone_number = values.phoneNumber;
            const type_of_overtime = changeToOfficial ? 1 : Number(values.type_of_overtime);
            const work_detail = values.work_detail;
            const break_time = values.break_time;
            const work_time = values.work_time;
            const construction_id = values.construction;

            // const contractor_id = values.subContractor?.map((item: any) => Number(item?.value));
            const contractor_id = values.subContractor ? values.subContractor.map((item: any) => Number(item?.value)) : [];

            const family_relationship = values.family_relationship;
            const family_living = Number(values.family_living);
            const detail = values.detail;
            const compensatory_leave = Number(values.compensatory_leave);
            const start_at_extra = values.start_at_extra;
            const stop_at_extra = values.stop_at_extra;
            const break_time_extra = values.break_time_extra;
            const work_time_extra = values.work_time_extra;
            const construction_id_extra = values.construction_id_extra;
            const work_detail_extra = values.work_detail_extra;

            // const contractor_id_extra = values.contractor_id_extra?.map((item: any) => Number(item?.value));
            const contractor_id_extra = values.contractor_id_extra ? values.contractor_id_extra.map((item: any) => Number(item?.value)) : [];

            const memo_extra = values.memo_extra;

            switch (values?.title) {
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
                handleOnSubmit(
                  { from, to, start_at, stop_at, overtime_reason_id, note, title, memo, phone_number, type_of_overtime },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
                handleOnSubmit(
                  { from, to, start_at, stop_at, work_detail, title, memo, type_of_overtime, break_time, work_time, construction_id, contractor_id },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
                handleOnSubmit(
                  { from, to, title, memo, phone_number, type_of_overtime, family_relationship, family_living },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
                handleOnSubmit(
                  { from, to, start_at, stop_at, work_detail, title, memo, type_of_overtime, break_time, work_time, construction_id, contractor_id, detail, compensatory_leave },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
                handleOnSubmit(
                  { from, to, start_at, stop_at, work_detail, title, memo, type_of_overtime, break_time, work_time, construction_id, contractor_id, start_at_extra, stop_at_extra, break_time_extra, work_time_extra, construction_id_extra, work_detail_extra, contractor_id_extra, memo_extra },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
                handleOnSubmit(
                  { from, to, start_at, stop_at, work_detail, title, memo, type_of_overtime, break_time, work_time, construction_id, contractor_id, overtime_reason_id, note, phone_number },
                  resetForm
                );
                break;
            }
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, values, handleChange, errors, touched, handleBlur, setFieldValue }) => {
            let componentToShow;
            setValuesTitle(values?.title);
            switch (values.title) {
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
                componentToShow = <OTNewVacation values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
                componentToShow = (
                  <OTNewHoliday
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
                componentToShow = (
                  <OTNewHalfDay
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    listReasons={listReasons}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
                componentToShow = (
                  <OTNewNightShift
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
                componentToShow = (
                  <OTNewOvertime
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
                componentToShow = (
                  <OTNewDayToNight
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstractorsExtra={onChangeConstractorsExtra}
                    onChangeConstruction={onChangeConstruction}
                    onChangeConstructionExtra={onChangeConstructionExtra}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
                componentToShow = (
                  <OTNewNightToDay
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstractorsExtra={onChangeConstractorsExtra}
                    onChangeConstruction={onChangeConstruction}
                    onChangeConstructionExtra={onChangeConstructionExtra}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
                componentToShow = <OTNewSpecialLeave values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
                componentToShow = <OTNewBereavement values={values} errors={errors} handleChange={handleChange} touched={touched} handleBlur={handleBlur} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
                componentToShow = <OTNewAbsense values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
                componentToShow = <OTNewTardiness values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
                componentToShow = <OTNewEarlyLeave values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              default:
                componentToShow = null;
                break;
            }
            return (
              <div className="wrapperContent">
                <div className="content">
                  {dataDetail?.confirmation?.length ? (
                    <div className="approval" style={{ backgroundColor: theme.colors.white02 }}>
                      <OTApprovalProcess data={dataDetail} />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="newOT__user">
                    <div className="userInfo">
                      {/* <div className="userInfo__name"> */}
                      {/* <p className="title">氏名</p> */}
                      {/* <p className="wrapperAvatar"> */}
                      {/* <Avatar
                              sx={{
                                height: "50px !important",
                                width: "50px !important",
                              }}
                              src={linkImage(userInfo?.avatar?.path)}
                            /> */}
                      <h3 className="userInfo__name">{userInfo?.last_name} {userInfo?.first_name}</h3>
                      {/* </p> */}
                      {/* </div> */}
                    </div>
                    <div className="userContent"
                    // style={{
                    //   display: "flex",
                    //   justifyContent: "space-between",
                    // }}
                    >
                      {!isSectionChief && (
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <p className="label">所属</p>
                          {!!optionGroups?.length && (
                            <p className="label" style={{ fontWeight: "bold" }}>
                              {optionGroups[0]?.name}
                            </p>
                          )}
                        </div>
                      )}

                      {!enableCheckTypeOfOverTime && (
                        // <Grid className="label-type-overtime">
                        // <label className="label">シングルタイプ</label> */}
                        <div className="selectButton">
                          {/* <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group-type_of_overtime"
                              name="controlled-radio-buttons-group-type_of_overtime"
                              value={values.type_of_overtime}
                              onChange={handleChange("type_of_overtime")}
                              className="form__checkbox"
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                justifyContent: "center",
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                                "& .MuiTypography-body1": {
                                  fontWeight: "700",
                                },
                                "& .MuiRadio-root": {
                                  display: "none",
                                },
                              }}
                            >
                              <FormControlLabel value={1} className={!enableCheckTypeOfOverTime ? clsx(parseInt(values.type_of_overtime) === 0 ? "active" : "") : "active"} label="本届" control={<Radio />} />
                              <FormControlLabel
                                value={0}
                                label="予定届"
                                control={<Radio />}
                                className={clsx(parseInt(values.type_of_overtime) === 1 ? "active" : "")}
                                sx={{
                                  "& .MuiTypography-root": {
                                    width: "43px",
                                  },
                                  "& .MuiTypography-body1": {
                                    fontWeight: "700",
                                  },
                                }}
                              />
                            </RadioGroup> */}
                          <StyledToggleButton isActive={parseInt(values.type_of_overtime) === 0} onClick={() => setFieldValue("type_of_overtime", 0)}>予定届</StyledToggleButton>
                          <StyledToggleButton isActive={parseInt(values.type_of_overtime) === 1} onClick={() => setFieldValue("type_of_overtime", 1)}>本届</StyledToggleButton>
                        </div>
                        // </Grid>
                      )}


                      <div className="topContent">
                        {/* <Grid container spacing={2}>
                      <Grid item xs={12} md={7}> */}
                        <div className="user__title">
                          <p className="label">題名</p>
                          <AppSelect
                            options={overtimeNewTitle}
                            placeholder="タイムシート細別を選択"
                            value={values.title}
                            handleChange={handleChange("title")}
                            name="title"
                            error={touched.title ? errors.title : undefined}
                          />
                        </div>
                        {/* </Grid>
                    </Grid> */}
                        {/* <Grid container spacing={4}> */}

                        <div className="user__title">

                          {
                            dateRanges.map((dateRange, index) => (
                              // <Grid item xs={12} md={7} key={index}>
                              <>
                                <div className="datePicker">
                                  <AppDateRangePicker
                                    value={{
                                      startDate: dateRange?.startDate,
                                      endDate: dateRange?.endDate,
                                    }}
                                    onChange={(e) => handleChangeDateRange(e, index)}
                                    startText="開始"
                                    endText="終了"
                                  />
                                </div>
                                {dateRanges.length > 1 && (
                                  <div style={{ paddingTop: "1rem" }}>
                                    {/* <button onClick={(event) => { removeDateRange(event, index); }} style={{ padding: "0.5rem 2rem", margin: "0.5rem", backgroundColor: "rgba(255,0,0,0.1)", border: "1px solid #888", borderRadius: "1.6rem" }}>消去</button> */}
                                    <PassChangeButton onClick={(event) => { removeDateRange(event, index); }}>消去</PassChangeButton>
                                  </div>)}
                              </>
                              // </Grid>
                            ))
                          }
                          {/* </Grid> */}
                          {!id && dateRanges.length < 5 &&
                            <div style={{ paddingTop: "1rem" }}>
                              <PassChangeButton onClick={() => setDateRanges([...dateRanges, { startDate: new Date(), endDate: new Date() }])}>+ 日付追加</PassChangeButton>
                              {/* <button style={{ padding: "0.5rem 2rem", margin: "0.5rem 0", backgroundColor: "rgba(0,0,0,0.1)", border: "1px solid #888", borderRadius: "1.6rem" }} onClick={() => setDateRanges([...dateRanges, { startDate: new Date(), endDate: new Date() }])}>+ 日付追加</button> */}
                            </div>
                          }
                        </div>
                      </div>

                    </div>
                    {componentToShow}
                    {enableCheckTypeOfOverTime && checkTypeTextOT === 0 ? (
                      <>
                        <div className="newOT__btn">
                          {/* <div className="wrapperBtn"> */}
                          <PassChangeButton onClick={() => handleSubmit()}>予定届申請</PassChangeButton>
                          {/* <AppButton onClick={handleSubmit} text="予定届申請" /> */}
                          {/* </div> */}
                        </div>
                        <div className="newOT__btn">
                          {/* <div className="wrapperBtn"> */}
                          <LogoutButton onClick={() => { setChangeToOfficial(true); handleSubmit(); }}>本届申請</LogoutButton>
                          {/* <AppButton bgColor="#45b6fe" onClick={() => { setChangeToOfficial(true); handleSubmit(); }} text="本届申請" /> */}
                          {/* </div> */}
                        </div>
                      </>
                    ) : (
                      <div className="newOT__btn">
                        {/* <div className="wrapperBtn"> */}
                        <PassChangeButton onClick={() => handleSubmit()}>申請</PassChangeButton>
                        {/* <AppButton onClick={handleSubmit} text="申請" /> */}
                        {/* </div> */}
                      </div>
                    )}
                    {id && (
                      <div className="newOT__btn">
                        {/* <div className="delete"> */}
                        <GrayButton onClick={() => handleDelete()}>削除する</GrayButton>
                        {/* <AppButton text="削除する" onClick={handleDelete} /> */}
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </StyledNewOT>
  );
};
