import styled from "styled-components";

import small_inventory_bg from "@assets/Background/small_inventory_bg.png";
import inventory_bg from "@assets/Background/inventory_bg.png";

export const StyledInstrumentsView = styled.div`
    // background-image: url(${small_inventory_bg});
    // background-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors.white02};
    min-height: 100vh;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        // background-image: url(${inventory_bg});
    }
    .instruments {
        padding: 1rem 1rem;
        min-height: 80vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
        }
            margin-top:7rem;
            padding-bottom:12rem;
        .visible-pc {
            display: none;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: block;
            }
        }
        .visible-mobile {
            padding: "0 2rem";
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: none;
            }
        }
        .activeCategory {
            color: ${(p) => p.theme.colors.white} !important;
            background-color: ${(p) => p.theme.colors.primary} !important;
        }
        &__top {
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-bottom: 2rem;
            }
            &-searchForm {
                height: 100%;
                display: flex;
                align-items: end;
                .MuiIconButton-root {
                    padding: 0rem 1rem;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        padding: 0rem 2rem;
                    }
                }
            }
        }
        &__content {
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-top: 1px solid ${(p) => p.theme.colors.lightgray09};
            }
            .category {
                padding: 2rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    padding-left: 0;
                    min-height: 77vh;
                    // border-right: 1px solid rgb(212, 212, 212);
                    padding-left: 0px;
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding-top: 0;
                }
                &__list {
                    text-align: center;
                    padding: 1.5rem 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        text-align: center;
                    }
                }
                &__item {
                    position: relative;
                    font-size: 1.6rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    color: ${(p) => p.theme.colors.darkBlack};
                    display: inline-block;
                    display: -webkit-box;
                    max-width: 100%;
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    width: fit-content;
                    text-align: center;
                    margin: 0 auto;
                    cursor: pointer;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: transparent;
                    }
                }
            }
            .listItem {
                margin-top: 2rem;
                min-height: 75vh;
            }
        }
    }
`;
export const StyledInstrumentsListItemComponent = styled.div`
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding-left: 2.5rem;
    }
    .Instruments {
        &__title {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.6rem;
            padding: 2.5rem 0;
            text-align: left;
            padding-top: 0;
        }
        .error {
            min-height: 70vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export const StyledInstrumentsItem = styled.div`
    .wrapper {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        background-color: ${(p) => p.theme.colors.white00};
        padding: 1rem 1rem;
        border-radius: 6px;
        position: relative;
        cursor: pointer;
        .wrapperImage {
            width: 9rem;
            height: 9rem;
            max-height: 9rem;
            max-width: 9rem;

            // border: 2px solid ${(p) => p.theme.colors.gray3};
            // overflow: hidden;
            // width: 5rem;
            .image {
                // width: 100%;
                // height: 100%;
                width: 9rem;
                height: 9rem;
                object-fit:cover;
                border-radius: 6px;

            }
        }
        .link {
            // position: absolute;
            // display: block;
            z-index: 99;
            // color: blue;
            text-decoration: none;
            text-align: left;
            // padding-bottom: 1rem;
            &__text {
                display: block;
                display: -webkit-box;
                max-width: 90%;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                text-decoration: none;
                color:${(p) => p.theme.colors.black01};
            }
        }
        .wrapperContent {
            display:flex;
            flex-direction:column;
            justify-content:space-between;
            padding-left: 1rem;
            text-align: left;
            // width: 60%;
            .title {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.6rem;
                // padding-top: 1rem;
                display: block;
                display: -webkit-box;
                max-width: 100%;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
        }
        .notAvailable {
            display: block;
            padding-top: 1rem;
            color: ${(p) => p.theme.colors.error};
            display: block;
            display: -webkit-box;
            max-width: 6rem;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            height: 45px;
        }
    }
`;
