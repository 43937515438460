import { flatArrayToString } from "@utils";
import axiosClient from "./axiosClient";
import { IDeleteWorkTypeRequest, IStoreWorkTypeRequest } from "@interfaces";

export const workScheduleApi = {
    getOverViewWorkSchedule: (params: any, arrayGroupId: any) => {
        let url = `api/work-schedule/constructions?month=${params.month}&contractorId=${params?.userId ?? ""}&employeeId=${params?.employeeId ?? ""}&constructionId=${params?.constructionId ?? ""}`;
        if (arrayGroupId?.length) {
            url += flatArrayToString("groupId", arrayGroupId);
        }
        return axiosClient.get(url);
    },
    getListConstructionSite: (params: any) => {
        const url = `api/get-list-construction?construction_id=${params}`;
        return axiosClient.get(url);
    },
    getListWorksInConstruction: (params) => {
        const url = `api/work-schedule/get-list-by-construction?construction_id=${params.constructionId}&month=${params.month}&contractorId=${params?.contractorId ?? ""}`;
        return axiosClient.get(url);
    },
    getMyListContruction: () => {
        const url = `api/work-schedule/constructions-by-user`;
        return axiosClient.get(url);
    },
    getListWorkTypes: () => {
        const url = "api/work-types";
        return axiosClient.get(url);
    },
    deleteWorkSchedule: (params) => {
        const url = `api/work-schedule/delete/${params.workScheduleId}`;
        return axiosClient.delete(url);
    },
    deleteDaySchedule: (params) => {
        const url = `api/work-schedule/delete-specific-day`;
        return axiosClient.post(url, params);
    },
    updateWorkSchedule: (params) => {
        const url = `api/work-schedule/update-number-of-people`;
        return axiosClient.post(url, params);
    },
    storeWorkSchedule: (params) => {
        const url = "api/work-schedule/store";
        return axiosClient.post(url, params);
    },
    storeWorkScheduleV2: (params) => {
        const url = "api/work-schedule/store-work-type-v2";
        return axiosClient.post(url, params);
    },
    storeClassificationWorkSchedule: (params) => {
        const url = "api/work-schedule/store-classification";
        return axiosClient.post(url, params);
    },
    storeTypeWorkSchedule: (params) => {
        const url = "api/work-schedule/store-work-type";
        return axiosClient.post(url, params);
    },
    storeTypeWorkScheduleV2: (params: IStoreWorkTypeRequest) => {
        const url = "api/work-schedule/store-work-type-v2";
        return axiosClient.post(url, params);
    },
    deleteWorkType: (params: IDeleteWorkTypeRequest) => {
        const url = `api/work-schedule/delete-work-type-v2`;
        return axiosClient.post(url, params);
    },
    getWorkTypeByType: () => {
        const url = "api/work-types-by-type";
        return axiosClient.get(url);
    },
    getWorkDetail: (payload) => {
        const url = "api/work-schedule/detail";
        return axiosClient.get(url, { params: payload });
    },
};
