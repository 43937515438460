import { useCallback, useMemo, useState } from "react";
import { BiRightArrow } from "react-icons/bi";
import { useWorkScheduleContext } from "./context/WorkScheduleContext";
import { AppSelect } from "./Select";
import { EWorkTypeAction, IWorkTypeAction } from "./types";
import { useDispatch } from "react-redux";
import { updateSnackBar } from "@redux";
import { timesheetApi } from "@api";
import { format, getMonth, getYear } from "date-fns";
import { CircularProgress, TextField } from "@mui/material";
import cancel_icon from "@assets/Icons/cancel_icon_black.svg";
import { over } from "lodash";

const WORK_TYPE = {
    1: {
        label: "工",
        backgroundColor: "#07bc0c",
    },
    2: {
        label: "種",
        backgroundColor: "#1976d2",
    },
    3: {
        label: "細",
        backgroundColor: "#ffc65c",
    },
};

export default function ListWorkType({
    type1,
    setType1Data,
    setType2Data,
    setType3Data,
    setReloadPage,
    handleOpenTypeForm,
    handleOpenClassificationForm,
    handleOpenOverallTypeForm,
    collapsedRowIds,
    setCollapsedRowIds,
}: {
    type1: any;
    setType1Data: React.Dispatch<React.SetStateAction<any>>;
    setType2Data: React.Dispatch<React.SetStateAction<any>>;
    setType3Data: React.Dispatch<React.SetStateAction<any>>;
    setReloadPage: React.Dispatch<React.SetStateAction<boolean>>;
    handleOpenTypeForm: () => void;
    handleOpenClassificationForm: () => void;
    handleOpenOverallTypeForm: () => void;
    collapsedRowIds: number[];
    setCollapsedRowIds: React.Dispatch<React.SetStateAction<number[]>>;
}) {
    return (
        <WorkTypeItem
            onClick={() => {
                handleOpenOverallTypeForm();
                setType1Data(type1);
            }}
            item={type1}
            workType={1}
            setReloadPage={setReloadPage}
            collapsedRowIds={collapsedRowIds}
            setCollapsedRowIds={setCollapsedRowIds}
            subItem={
                <div style={{ marginTop: "0px" }}>
                    {type1?.children?.map((type2, index) => (
                        <WorkTypeItem
                            key={index}
                            onClick={() => {
                                handleOpenClassificationForm();
                                setType2Data(type2);
                            }}
                            item={type2}
                            workType={2}
                            style={{ paddingLeft: "0.5em", marginTop: "0px" }}
                            setReloadPage={setReloadPage}
                            collapsedRowIds={collapsedRowIds}
                            setCollapsedRowIds={setCollapsedRowIds}
                            subItem={
                                <>
                                    {type2.children?.map((type3, index) => (
                                        <WorkTypeItem
                                            key={index}
                                            item={type3}
                                            workType={3}
                                            onClick={() => {
                                                handleOpenTypeForm();
                                                setType3Data(type3);
                                            }}
                                            style={{ paddingLeft: "1em" }}
                                            setReloadPage={setReloadPage}
                                            collapsedRowIds={collapsedRowIds}
                                            setCollapsedRowIds={setCollapsedRowIds}
                                        />
                                    ))}
                                </>
                            }
                        />
                    ))}
                </div>
            }
        />
    );
}

const WorkTypeItem = ({ item, onClick, workType, style, subItem, setReloadPage, collapsedRowIds, setCollapsedRowIds }: { item: any; onClick?: () => void; workType: number; style?: any; subItem?: React.ReactNode; setReloadPage: any; collapsedRowIds: number[], setCollapsedRowIds: React.Dispatch<React.SetStateAction<number[]>> }) => {
    const hasChildren = Array.isArray(item?.children) && !!item?.children?.length;
    const handleOnToggleCollapse = () => {
        setCollapsedRowIds((prev) => {
            const toggleVal = !prev.includes(item?.id);
            if(!toggleVal) {
                return prev.filter((it) => it !== item?.id);
            } else if(!prev.includes(item?.id)) {
                return [...prev, item?.id];
            }
            return prev;
        });
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: 4,
                    height: "38px",
                }}
            >
                <div
                    className="flex-start type-container"
                    onClick={onClick && onClick}
                    style={{
                        ...style,
                        minWidth: 140,
                    }}
                >
                    <WorkTypeToggleIcon showToggle={!!item?.children?.length} isOpen={!collapsedRowIds.includes(item.id)} onToggle={() => {handleOnToggleCollapse();}} />

                    <p className="opacity-80">
                        <WorkTypeIcon workType={workType} />
                        {item?.work_type?.name}
                    </p>
                </div>

                {hasChildren ? null : <WorkTypeButtonAction item={item} setReloadPage={setReloadPage} />}
            </div>
            {subItem && !collapsedRowIds.includes(item.id) ? subItem : null}
        </>
    );
};

const WorkTypeIcon = ({ workType }: { workType: number }) => {
    const workTypeData = WORK_TYPE[workType || 1];
    return (
        <span
            style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#ffffff",
                background: workTypeData?.backgroundColor,
                padding: "0 4px",
                marginRight: "4px",
                border: "1px solid #000000",
                borderRadius: "4px",
                fontWeight: "600",
            }}
        >
            {workTypeData?.label}
        </span>
    );
};

interface DateRangeItem {
    start: number | null;
    end: number | null;
    color: string;
}

const WorkTypeButtonAction = ({ item, setReloadPage }: { item: any; setReloadPage: any }) => {
    const { date, colorOptions, companyOptions, workTypeAction, selectedDateRange, workTypeData, dateRangeColors, setWorkTypeAction, setSelectedDateRange } = useWorkScheduleContext();
    const [chosenCompany, setChosenCompany] = useState("");
    const [chosenColor, setChosenColor] = useState("");
    const [chosenHex, setChosenHex] = useState("#ffffff");
    const [numOfPeople, setNumOfPeople] = useState(0);
    const dispatch = useDispatch();
    const selectedMonth = !date ? getMonth(new Date()) : getMonth(new Date(date));
    const selectedYear = !date ? getYear(new Date()) : getYear(new Date(date));

    const isSelectedRow = item?.id === workTypeAction?.selectedId;

    const [isLoadingCompany, setIsLoadingCompany] = useState(false);
    const [isLoadingDateRange, setIsLoadingDateRange] = useState(false);
    const [isLoadingPeople, setIsLoadingPeople] = useState(false);

    const handleStoreCompany = useCallback(
        async (data) => {
            try {
                setIsLoadingCompany(true);
                const response = await timesheetApi.storeCompanyV2(data);
                dispatch(
                    updateSnackBar({
                        messageType: "success",
                        message: response?.data?.message,
                    })
                );
                setReloadPage((prev) => !prev);
                setChosenCompany("");
                setIsLoadingCompany(false);
                setWorkTypeAction({
                    selectedId: "",
                    action: "",
                });
            } catch (error: any) {
                setIsLoadingCompany(false);
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: error?.response?.data?.message,
                    })
                );
            }
        },
        [setReloadPage, setChosenCompany, setIsLoadingCompany, setWorkTypeAction]
    );

    const handleStoreDateRange = useCallback(
        async (data) => {
            try {
                setIsLoadingDateRange(true);
                const response = await timesheetApi.storeDateRangeV2(data);
                dispatch(
                    updateSnackBar({
                        messageType: "success",
                        message: response?.data?.message,
                    })
                );
                setReloadPage((prev) => !prev);
                setChosenColor("");
                setIsLoadingDateRange(false);
                setWorkTypeAction({
                    selectedId: "",
                    action: "",
                });
            } catch (error: any) {
                setIsLoadingDateRange(false);
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: error?.response?.data?.message,
                    })
                );
            }
        },
        [setReloadPage, setChosenColor, setIsLoadingDateRange, setWorkTypeAction]
    );

    const handleStoreNumberOfPeople = useCallback(
        async (data) => {
            try {
                setIsLoadingPeople(true);
                const response = await timesheetApi.storeNumberOfPeopleV2(data);
                dispatch(
                    updateSnackBar({
                        messageType: "success",
                        message: response?.data?.message,
                    })
                );
                setReloadPage((prev) => !prev);
                setNumOfPeople(0);
                setIsLoadingPeople(false);
                setWorkTypeAction({
                    selectedId: "",
                    action: "",
                });
            } catch (error: any) {
                setIsLoadingPeople(false);
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: error?.response?.data?.message,
                    })
                );
            }
        },
        [setReloadPage, setNumOfPeople, setIsLoadingPeople, setWorkTypeAction]
    );

    const getParentIds = useCallback((workTypeData, parentId, type) => {
        if (type === 1) return [];

        let parent_ids = [parentId];
        let currentParentId = parentId;

        while (currentParentId) {
            const parentWorkType = workTypeData?.work_schedules?.flatMap((it) => it?.work_schedule_work_type)?.find((it) => String(it?.id) === String(currentParentId));
            currentParentId = parentWorkType?.parent_id;
            parent_ids = ([] as number[]).concat(parent_ids).concat(currentParentId);
        }

        return parent_ids?.filter(Boolean);
    }, []);

    const handleClickCompanyButton = useCallback(() => {
        setChosenCompany("");
        if (workTypeAction?.action === EWorkTypeAction.SELECT_COMPANY && isSelectedRow) {
            if (!chosenCompany) {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: "会社を選択してください",
                    })
                );
                return;
            }

            handleStoreCompany({
                contractor_id: chosenCompany,
                work_schedule_work_type_id: workTypeAction?.selectedId,
            });
            return;
        }
        setWorkTypeAction({
            selectedId: item?.id,
            action: EWorkTypeAction.SELECT_COMPANY,
        });
    }, [setChosenCompany, workTypeAction, chosenCompany, handleStoreCompany, setWorkTypeAction, isSelectedRow, item?.id]);

    function formatArray(inputArray): DateRangeItem[] {
        const result: DateRangeItem[] = [];
        let start: number | null = null;
        let currentColor = null;

        for (let i = 0; i < inputArray.length; i++) {
            const color = inputArray[i];

            if (color !== currentColor) {
                if (currentColor !== null) {
                    // Push the previous range when the color changes
                    result.push({ start, end: i - 1, color: currentColor });
                }
                // Update to the new color and start position
                if (color !== "") {
                    start = i;
                    currentColor = color;
                } else {
                    currentColor = null;
                }
            }
        }

        // Add the last range if needed
        if (currentColor !== null) {
            result.push({ start, end: inputArray.length - 1, color: currentColor });
        }

        return result;
    }

    // console.log("result", formatArray(dateRangeColors));

    const handleClickColorButton = useCallback(() => {
        if (workTypeAction?.action === EWorkTypeAction.SELECT_DATE_RANGE && isSelectedRow) {
            // if (!selectedDateRange?.[0] || !selectedDateRange?.[1] || !workTypeAction?.color) {
            //     let errorMessage = "";
            //     if (!selectedDateRange?.[0]) errorMessage = "開始日を選択してください";
            //     if (!selectedDateRange?.[1]) errorMessage = "終了日を選択してください";
            //     if (!workTypeAction?.color) errorMessage = "色を選択してください";
            //     dispatch(
            //         updateSnackBar({
            //             messageType: "error",
            //             message: errorMessage,
            //         })
            //     );
            //     return;
            // }

            // const dateRangeColor = item?.work_type_date?.map((it) => {
            //     const start = Number(format(new Date(it?.start_date), "dd"));
            //     const end = Number(format(new Date(it?.end_date), "dd"));
            //     return [start, end];
            // });

            // const isOverlapBeforeDateRange = dateRangeColor?.some((it) => {
            //     return (
            //         (selectedDateRange?.[0] <= it?.[0] && selectedDateRange?.[1] >= it?.[1]) ||
            //         (selectedDateRange?.[1] <= it?.[1] && selectedDateRange?.[1] >= it?.[0]) ||
            //         (selectedDateRange?.[0] >= it?.[0] && selectedDateRange?.[0] <= it?.[1]) ||
            //         (selectedDateRange?.[0] >= it?.[0] && selectedDateRange?.[1] <= it?.[1])
            //     );
            // });

            // if (isOverlapBeforeDateRange) {
            //     dispatch(
            //         updateSnackBar({
            //             messageType: "error",
            //             message: "日付範囲の色は上書きできません",
            //         })
            //     );
            //     return;
            // }

            const parentIds = getParentIds(workTypeData, item?.parent_id, item?.work_type?.type);

            // return;
            const data = formatArray(dateRangeColors)
                ?.map((it) => {
                    if (!it?.start || !it?.end || !it?.color) return null;

                    return {
                        ...([2, 3].includes(item?.work_type?.type) && {
                            work_schedule_id: item?.work_schedule_id, //For work type 2 and work type 3
                        }),
                        parent_id: parentIds, //Must be an array, for work type 2 and work type 3
                        work_schedule_work_type_id: workTypeAction?.selectedId,
                        start_date: format(new Date(selectedYear, selectedMonth, it?.start), "yyyy-MM-dd"),
                        end_date: format(new Date(selectedYear, selectedMonth, it?.end), "yyyy-MM-dd"),
                        color: it?.color,
                    };
                })
                .filter(Boolean);

            handleStoreDateRange(data);

            return;
        }
        setWorkTypeAction({
            selectedId: item?.id,
            action: EWorkTypeAction.SELECT_DATE_RANGE,
        });
        setChosenColor("");
        setSelectedDateRange([]);
    }, [setChosenColor, setSelectedDateRange, setWorkTypeAction, handleStoreDateRange, workTypeAction, isSelectedRow, selectedDateRange, workTypeData, item, selectedYear, selectedMonth]);

    const handleClickPeopleButton = useCallback(() => {
        setNumOfPeople(0);
        if (workTypeAction?.action === EWorkTypeAction.SELECT_PEOPLE && isSelectedRow) {
            if (!numOfPeople) {
                dispatch(
                    updateSnackBar({
                        messageType: "error",
                        message: "人を選んでください",
                    })
                );
                return;
            }

            handleStoreNumberOfPeople({
                work_schedule_work_type_id: workTypeAction?.selectedId,
                number_of_people: numOfPeople,
                contractor_id: item?.work_type_date?.[0]?.contractor_id || 0,
            });
            return;
        }
        setWorkTypeAction({
            selectedId: item?.id,
            action: EWorkTypeAction.SELECT_PEOPLE,
        });
    }, [setNumOfPeople, workTypeAction, numOfPeople, handleStoreNumberOfPeople, setWorkTypeAction, item]);

    const workTypeActionElement = useMemo(() => {
        return {
            [EWorkTypeAction.SELECT_COMPANY]: {
                isShow: !workTypeAction?.action || workTypeAction?.action === EWorkTypeAction.SELECT_COMPANY,
                render: () => {
                    return (
                        <>
                            <Button isLoading={isLoadingCompany} isSelectedRow={isSelectedRow} workTypeAction={workTypeAction} buttonType={EWorkTypeAction.SELECT_COMPANY} onClick={handleClickCompanyButton}>
                                {isSelectedRow && workTypeAction?.action === EWorkTypeAction.SELECT_COMPANY ? "業者決定" : "業"}
                            </Button>

                            {isSelectedRow && workTypeAction?.action === EWorkTypeAction.SELECT_COMPANY ? (
                                <>
                                    <Button
                                        disabled={isLoadingCompany}
                                        isSelectedRow={isSelectedRow}
                                        workTypeAction={workTypeAction}
                                        buttonType={EWorkTypeAction.SELECT_COMPANY}
                                        onClick={() => {
                                            setWorkTypeAction({
                                                action: "",
                                                selectedId: "",
                                                color: "",
                                            });
                                        }}
                                        style={{
                                            height: 24,
                                            minWidth: 24,
                                            ...(isLoadingCompany && {
                                                opacity: 0.8,
                                            }),
                                        }}
                                    >
                                        <img src={cancel_icon} alt="X" />
                                    </Button>

                                    <AppSelect
                                        options={
                                            Array.isArray(companyOptions) &&
                                            !!companyOptions?.length &&
                                            companyOptions.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                };
                                            })
                                        }
                                        handleChange={(e) => {
                                            setChosenCompany(String(e?.target?.value));
                                        }}
                                        value={chosenCompany}
                                        placeholder=""
                                        sx={{
                                            borderRadius: `0 !important`,
                                            width: "100px !important",
                                            height: "30px !important",
                                            svg: {
                                                display: "none",
                                            },
                                            ".MuiOutlinedInput-input": {
                                                p: "2px",
                                                height: "100% !important",
                                                fontSize: "12px !important",
                                                ".MuiBox-root": {
                                                    height: "100% !important",
                                                    padding: 0,
                                                    margin: 0,
                                                },
                                            },
                                        }}
                                    />
                                </>
                            ) : null}
                        </>
                    );
                },
            },
            [EWorkTypeAction.SELECT_DATE_RANGE]: {
                isShow: !workTypeAction?.action || workTypeAction?.action === EWorkTypeAction.SELECT_DATE_RANGE,
                render: () => {
                    return (
                        <>
                            <Button isLoading={isLoadingDateRange} isSelectedRow={isSelectedRow} workTypeAction={workTypeAction} buttonType={EWorkTypeAction.SELECT_DATE_RANGE} onClick={handleClickColorButton}>
                                {isSelectedRow && workTypeAction?.action === EWorkTypeAction.SELECT_DATE_RANGE ? "色を決定" : "色"}
                            </Button>

                            {isSelectedRow && workTypeAction?.action === EWorkTypeAction.SELECT_DATE_RANGE ? (
                                <>
                                    <Button
                                        disabled={isLoadingDateRange}
                                        isSelectedRow={isSelectedRow}
                                        workTypeAction={workTypeAction}
                                        buttonType={EWorkTypeAction.SELECT_DATE_RANGE}
                                        onClick={() => {
                                            setWorkTypeAction({
                                                action: "",
                                                selectedId: "",
                                                color: "",
                                            });
                                        }}
                                        style={{
                                            height: 24,
                                            minWidth: 24,
                                            ...(isLoadingDateRange && {
                                                opacity: 0.8,
                                            }),
                                        }}
                                    >
                                        <img src={cancel_icon} alt="X" />
                                    </Button>

                                    <AppSelect
                                        options={
                                            Array.isArray(colorOptions) &&
                                            !!colorOptions?.length &&
                                            colorOptions.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                    color: item?.color,
                                                };
                                            })
                                        }
                                        handleChange={(e) => {
                                            setChosenColor(String(e?.target?.value));
                                            const colorHex = colorOptions?.find((it) => String(it?.id) === String(e?.target?.value))?.color || "#ffffff";
                                            setChosenHex(colorHex);
                                            //console.log(`Chosen hex: ${colorHex}`);
                                            const color = colorOptions?.find((it) => String(it?.id) === String(e?.target?.value))?.color || "";
                                            setWorkTypeAction((prev) => ({
                                                ...prev,
                                                color,
                                            }));
                                        }}
                                        value={chosenColor}
                                        placeholder=""
                                        sx={{
                                            borderRadius: `0 !important`,
                                            width: "36px !important",
                                            height: "30px !important",
                                            backgroundColor: `${chosenHex} !important`,
                                            overflow: "hidden",
                                            svg: {
                                                display: "none",
                                            },
                                            ".MuiOutlinedInput-input": {
                                                p: "2px",
                                                height: "100% !important",
                                                fontSize: "12px !important",
                                                ".MuiBox-root": {
                                                    height: "100% !important",
                                                    padding: "9px",
                                                    margin: 0,
                                                },
                                            },
                                        }}
                                    />
                                </>
                            ) : null}
                        </>
                    );
                },
            },
            [EWorkTypeAction.SELECT_PEOPLE]: {
                isShow: !workTypeAction?.action || workTypeAction?.action === EWorkTypeAction.SELECT_PEOPLE,
                render: () => {
                    return (
                        <>
                            <Button isLoading={isLoadingPeople} isSelectedRow={isSelectedRow} workTypeAction={workTypeAction} buttonType={EWorkTypeAction.SELECT_PEOPLE} onClick={handleClickPeopleButton}>
                                {isSelectedRow && workTypeAction?.action === EWorkTypeAction.SELECT_PEOPLE ? "人数を決定" : "人"}
                            </Button>

                            {isSelectedRow && workTypeAction?.action === EWorkTypeAction.SELECT_PEOPLE ? (
                                <>
                                    <Button
                                        disabled={isLoadingPeople}
                                        isSelectedRow={isSelectedRow}
                                        workTypeAction={workTypeAction}
                                        buttonType={EWorkTypeAction.SELECT_PEOPLE}
                                        onClick={() => {
                                            setWorkTypeAction({
                                                action: "",
                                                selectedId: "",
                                                color: "",
                                            });
                                        }}
                                        style={{
                                            height: 24,
                                            minWidth: 24,
                                            ...(isLoadingPeople && {
                                                opacity: 0.8,
                                            }),
                                        }}
                                    >
                                        <img src={cancel_icon} alt="X" />
                                    </Button>

                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        sx={{
                                            maxWidth: 100,
                                            minWidth: "60px !important",
                                            ".MuiOutlinedInput-input": {
                                                padding: "4px",
                                                fontSize: 14,
                                            },
                                        }}
                                        value={numOfPeople}
                                        onChange={(e) => setNumOfPeople(Number(e?.target?.value) < 0 ? 0 : Number(e?.target?.value))}
                                    />
                                </>
                            ) : null}
                        </>
                    );
                },
            },
        };
    }, [
        getParentIds,
        workTypeAction?.action,
        colorOptions,
        isSelectedRow,
        companyOptions,
        chosenCompany,
        chosenColor,
        numOfPeople,
        isLoadingCompany,
        isLoadingDateRange,
        isLoadingPeople,
        item,
        setChosenCompany,
        setChosenColor,
        setNumOfPeople,
        handleClickColorButton,
        handleClickCompanyButton,
        handleClickPeopleButton,
    ]);

    return (
        <div
            style={{
                display: "flex",
                gap: "0px",
                alignItems: "center",
                marginLeft: 4,
                paddingRight: "0.5rem",
            }}
        >
            {Object.values(workTypeActionElement)?.map((it) => {
                if (!it?.isShow && isSelectedRow) return null;

                return it?.render();
            })}
        </div>
    );
};

const WorkTypeToggleIcon = ({ showToggle, isOpen, onToggle }: { showToggle: boolean; isOpen: boolean; onToggle: () => void }) => {
    return (
        <BiRightArrow
            onClick={(e) => {
                e.stopPropagation();
                onToggle();
            }}
            style={{
                marginRight: 4,
                width: 20,
                cursor: "pointer",
                transform: `rotate(${isOpen ? 90 : 0}deg)`,
                ...(!showToggle && {
                    visibility: "hidden",
                    transform: "rotate(0deg) !important",
                }),
            }}
        />
    );
};

const Button = ({
    isSelectedRow,
    workTypeAction,
    buttonType,
    onClick,
    isLoading,
    disabled = false,
    style,
    children,
}: {
    isSelectedRow: boolean;
    isLoading?: boolean;
    workTypeAction: IWorkTypeAction;
    buttonType: EWorkTypeAction;
    style?: any;
    disabled?: boolean;
    onClick: () => void;
    children: React.ReactElement | string;
}) => {
    return (
        <button
            disabled={disabled || isLoading}
            style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d4d4d4",
                padding: "0 4px",
                border: "1px solid #000000",
                borderRadius: "4px",
                fontWeight: "600",
                minWidth: "unset",
                fontSize: 14,
                minHeight: 24,
                ...(isSelectedRow &&
                    workTypeAction?.action === buttonType && {
                        background: "#ffc1c1",
                        minWidth: 84,
                    }),
                ...(disabled && {
                    cursor: "not-allowed",
                }),
                ...style,
            }}
            onClick={onClick}
        >
            {children}
            {isLoading ? <CircularProgress size={12} style={{ marginLeft: "2px" }} /> : null}
        </button>
    );
};
