import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { StyledInstrumentsItem } from "./styles";
import { linkProductImage } from "@utils";
import { Box } from "@mui/system";
interface IProps {
  item?: any;
  categoryId?: any;
}
export const InstrumentItemComponent = (props: IProps) => {
  // props
  const { item } = props;

  //hooks
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const categoryId = query.get("categoryId");
  const subCategoryId = query.get("subCategoryId");
  const history = useHistory();

  const imageSrc = linkProductImage(item?.image?.path);
  //    const [imageSrc, setImageSrc] = useState(linkProductImage(item?.image?.path));
  //    const [hasAttemptedForceEnv, setHasAttemptedForceEnv] = useState(false);
  //
  //    // Handle image load error
  //    const handleImageError = () => {
  //        if (!hasAttemptedForceEnv) {
  //            // First error: try with `isForceEnv = true`
  //            setImageSrc(linkProductImage(item?.image?.path, true));
  //            setHasAttemptedForceEnv(true);
  //        } else {
  //            // Second error: stop trying (fallback to default or leave broken image)
  //            // console.error("Image failed to load: ", item?.image?.path);
  //        }
  //    };

  return (
    <StyledInstrumentsItem>
      <div
        className="wrapper disabled"
        onClick={() => {
          categoryId
            ? history.push(
              `/instruments/calendar/${item?.id}?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
            )
            : history.push(`/instruments/calendar/${item?.id}`);
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",

          }}
        >
          <div style={{ display: "flex" }}>
            <div className="wrapperImage">
              <img
                className="image"
                src={imageSrc}
                alt="inventory item"
              />
            </div>
            <div className="wrapperContent">
              <div className="title" >
                {item?.name}
              </div>
              <div className="link">
                <a className="link__text" href={item?.url} target="_blank">
                  {item?.url}
                </a>
              </div>
            </div>
          </div>
          {/* <span>{!item?.borrowing && <img src={star_icon} />}</span> */}
          <span>
            {!item?.borrowing && (
              <p style={{ color: "red", width: "10rem", textAlign: "right" }}>{item?.isLate ? "遅延" : "使用中"}</p>
            )}
          </span>
        </Box>
      </div>
    </StyledInstrumentsItem>
  );
};
