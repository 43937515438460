import React, { useEffect } from "react";
import { AppModal, CircularLoader } from "../Common";
import { useDispatch } from "react-redux";
import {
  fetchTechnicalDocuments,
  useAppSelector,
  readTechnicalDocument,
  deleteTechnicalDocument,
  selectPreloadData,
  selectPreloadDataLoading,
} from "@redux";
import { StyledTechnicalDocumentsComponent, TechnicalDocumentsItem } from ".";
import active_arrow_down from "@assets/Icons/active_arrow_down.svg";
import {
  DOCUMENT_EXCEL_FAILED_TO_LOAD,
  DOCUMENT_FAILED_TO_LOAD,
  LABEL_LIST_DOCUMENT,
  MESSAGE_DATA_NOT_FOUND,
  MESSAGE_LOADING_PDF,
} from "@configs";
import { StyledIframe, StyledPDF } from "../Document";
import { Document, Page } from "react-pdf";
import {
  converNumberToArrayOfInterger,
  isLinkExel,
  linkDocument,
  useWindowDimensions,
} from "@utils";
import { saveAs } from "file-saver";
import { TitleMenuComponent } from "../TitleMenuComponent";
import { technicalDocumentsApi } from "@api";

export const TechnicalDocumentsView = () => {
  //const technicalDocuments = useAppSelector(selectTechnicalDocuments);
  //const loading = useAppSelector(selectDocumentsLoading);
  const preloadData = useAppSelector(selectPreloadData);
  //console.log("preloadData", preloadData);
  const preloadDataLoading = useAppSelector(selectPreloadDataLoading);
  const boxDocuments = preloadData?.boxDocuments;

  const [page, setPage] = React.useState<number>(1);
  const [boxDocumentListLoading, setBoxDocumentListLoading] = React.useState<boolean>(false);
  // const history = useHistory();
  const dispatch = useDispatch();
  const loadMore = false;
  const [openPDF, setOpenPDF] = React.useState<boolean>(false);
  const [linkPdf, setLinkPdf] = React.useState<any>("");
  const [numPages, setNumPages] = React.useState(null);
  const width = useWindowDimensions().width;

  const fetchListDocument = async () => {
    try {
      setBoxDocumentListLoading(true);
      const res = await technicalDocumentsApi.getTechnicalDocumentsPage(page);
      //            if (page === 1) {
      //                dispatch(resetListDocument());
      //            }
      setBoxDocumentListLoading(false);
      //            dispatch(updateListDocument(res.data.data.documents));
      //            dispatch(updateNumberListDocument(res.data.data.not_read));
      if (
        res.data.data.documents.length < 10 &&
        res.data.data.documents.length < res.data.data.total
      ) {
        //console.log("setLoadMore", false);
        //setLoadMore(false);
      } else {
        //console.log("setLoadMore", true);
        //setLoadMore(true);
      }
    } catch (err) {
      setBoxDocumentListLoading(false);
    }
  };

  useEffect(() => {
    fetchListDocument();
  }, []);

  const handleOnSelectTechnicalDocuments = async (path: any, title: any, id: any) => {
    try {
      dispatch(readTechnicalDocument(id));
      if (path.endsWith(".xlsx")) {
        const response = await fetch(path);
        const blob = await response.blob();
        saveAs(blob, `${title}.xlsx`);
      } else {
        handleOpenPDF(path);
      }
    } catch (error) { }
  };

  const handleDeleteTechnicalDocuments = (id: any) => {
    try {
      dispatch(deleteTechnicalDocument(id));
    } catch (err) { }
  };

  const handleOpenPDF = (value: any) => {
    if (width <= 980) {
      window.location.href = linkDocument(value);
    }
    setLinkPdf(value);
    setOpenPDF(true);
  };

  const handleClosePDF = () => {
    setOpenPDF(false);
    setLinkPdf("");
  };

  const handleFetchTechnicalDocuments = () => {
    try {
      dispatch(fetchTechnicalDocuments(page));
    } catch (err) {
      /** */
    }
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };
  React.useEffect(() => {
    handleFetchTechnicalDocuments();
    return () => {
      //    do something cleanup
    };
  }, [page]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <StyledTechnicalDocumentsComponent>
      {/* <AppTopBar /> */}
      <CircularLoader loading={preloadDataLoading || boxDocumentListLoading} />
      {width >= 981 && (
        <AppModal open={openPDF} handleClose={handleClosePDF}>
          <StyledPDF>
            {linkPdf ? (
              isLinkExel(linkPdf) ? (
                <p className="failePDF">{DOCUMENT_EXCEL_FAILED_TO_LOAD}</p>
              ) : width >= 991 ? (
                <StyledIframe
                  heightCalculationMethod="lowestElement"
                  src={linkDocument(linkPdf)}
                  log
                  scrolling={true}
                  autoResize={false}
                />
              ) : (
                <Document
                  file={linkDocument(linkPdf)}
                  options={{ workerSrc: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error={DOCUMENT_FAILED_TO_LOAD}
                  loading={MESSAGE_LOADING_PDF}
                >
                  {numPages &&
                    converNumberToArrayOfInterger(numPages).map(
                      (page, index) => <Page pageNumber={index} />
                    )}
                </Document>
              )
            ) : (
              <p className="failePDF">{DOCUMENT_FAILED_TO_LOAD}</p>
            )}
          </StyledPDF>
        </AppModal>
      )}
      {/* <div className="wrapperForm-top">
        <p className="top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
          <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
        </p>
      </div> */}
      <div className="wrapperForm">
        <div className="top-icon">
          <TitleMenuComponent text={LABEL_LIST_DOCUMENT} />
        </div>
      </div>
      {/* <DocumentBorder /> */}
      <div className="noti">
        <div className="noti__wrapper">
          <div className="noti__content">
            {boxDocuments?.length
              ? boxDocuments.map((noti, index) => (
                <div key={index}>
                  <TechnicalDocumentsItem
                    handleOnSelectTechnicalDocuments={() =>
                      handleOnSelectTechnicalDocuments(
                        noti?.path,
                        noti?.title,
                        noti?.id
                      )
                    }
                    data={noti}
                    handleDeleteTechnicalDocuments={() => {
                      handleDeleteTechnicalDocuments(noti?.id);
                    }}
                  />
                </div>
              ))
              : !(preloadDataLoading || boxDocumentListLoading) && (
                <p className="messageNotFound">{MESSAGE_DATA_NOT_FOUND}</p>
              )}
          </div>
        </div>
      </div>
      {/* <div className="wrapperLoading">
                <CircularLoader loading={loading} size={15} type="fullContent" />
            </div> */}
      {!(preloadDataLoading || boxDocumentListLoading) && loadMore && (
        <p className="viewMore">
          <span className="viewMore__text" onClick={() => handleLoadMore()}>
            続きを見る
          </span>
          <img
            className="viewMore__icon"
            src={active_arrow_down}
            alt="icon"
            onClick={() => handleLoadMore()}
          />
        </p>
      )}
    </StyledTechnicalDocumentsComponent>
  );
};
