import { alcoholApi } from "@api";
import { updateSnackBar } from "@redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CircularLoader } from "../Common";
import { StyledAlcoholTable } from "./style";
import { Avatar, Grid } from "@mui/material";
import { linkAvatar, linkImage, theme } from "@utils";

interface IState {
  date: any;
}

const AlcoholCheckerTable = (props: IState) => {
  const { date } = props;
  const [selectedIds, setSelectedIds] = useState<{ [key: number]: boolean }>({});
  const [loading, setLoading] = React.useState<boolean>();
  const [idChecker, setIdChecker] = useState<any>();
  const [data, setData] = useState<any>();
  const [dataConfirmHistory, setDataConfirmHistory] = useState<any>();

  const dispatch = useDispatch();

  const handleCheckboxChange = (id: number) => {
    setSelectedIds((prevSelectedIds) => ({
      ...prevSelectedIds,
      [id]: !prevSelectedIds[id],
    }));
    setIdChecker(id);
  };
  const submitCheck = async () => {
    setLoading(true);
    try {
      const result = await alcoholApi.confirmCheckAlcohol({ id: idChecker });
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "success",
          message: result.data.message,
        })
      );
    } catch (error: any) {
      setSelectedIds({});
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response.data.message,
        })
      );
    }
  };

  useEffect(() => {
    const getDataAlcoholInformation = async () => {
      setLoading(true);
      try {
        const res = await alcoholApi.getAlcoholInformation({
          date: moment(date).format("YYYY-MM-DD"),
        });
        setLoading(false);
        setData(res.data.data);
      } catch (error: any) {
        setLoading(false);
      }
    };
    const getListAlcoholConfirmHistory = async () => {
      setLoading(true);
      try {
        const res = await alcoholApi.getAlcoholInformationHistory({
          date: moment(date).format("YYYY-MM-DD"),
        });
        setLoading(false);
        setDataConfirmHistory(res.data.data);
      } catch (error: any) {
        setLoading(false);
      }
    };
    getDataAlcoholInformation();
    getListAlcoholConfirmHistory();
    if (idChecker) {
      submitCheck();
      getDataAlcoholInformation();
      getListAlcoholConfirmHistory();
    }
  }, [date, idChecker]);
  return (
    <StyledAlcoholTable>
      <CircularLoader loading={loading} />

      <div className="al-table">

        <Grid container>
          <Grid item xs={12}>
            <div className="subTitle">
              <Grid container spacing={1} >
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>名前</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>チェック者</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>運転者</Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>車両名</Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>チェック製品</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>出勤/退勤</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>数値</Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>確認</Grid>
              </Grid>
            </div>
          </Grid>


          <Grid item xs={12}>
            <div className="al-content">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <Grid
                    container
                    spacing={1}
                    key={item.id}
                    sx={{
                      borderBottom: index === data.length - 1 ? "none" : `1px solid ${theme.colors.lightgray09}`,
                      paddingBottom: 1,
                      alignItems: "center"
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                      <div className="userContainer">
                        <Avatar src={linkAvatar(item?.avatar)} />
                        <p>{item.employee_name}</p>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">チェック者</p>
                      </div>
                      <p>{item.inspector}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">運転者</p>
                      </div>
                      <p>{item.driver}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                      <div className="recordName">
                        <p className="recordName__text">車両名</p>
                      </div>
                      <p>{item.vehicle}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                      <div className="recordName">
                        <p className="recordName__text">チェック製品</p>
                      </div>
                      <p>{item.device_check}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">出勤/退勤</p>
                      </div>
                      <div>
                        <p>{item.start_time_check_alcohol.slice(0, 5)}</p>
                        <p>{item.end_time_check_alcohol.slice(0, 5)}</p>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">数値</p>
                      </div>
                      <div>
                        <p>{item.start_capacity_alcohol} mg</p>
                        <p>{item.end_capacity_alcohol} mg</p>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                      <div className="recordName">
                        <p className="recordName__text">確認</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          checked={selectedIds[item.id]}
                          onChange={() => handleCheckboxChange(item.id)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>
                  アルコール濃度に関するデータはありません
                </p>
              )}
            </div>
          </Grid>

        </Grid>
      </div>

      <div className="al-table">
        <Grid container>
          <Grid item xs={12}>
            <div className="subTitle">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>名前</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>チェック者</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>運転者</Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>車両名</Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>チェック製品</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>出勤/退勤</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>数値</Grid>
                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>管理者確認</Grid>
              </Grid>
            </div>
          </Grid>


          <Grid item xs={12}>
            <div className="al-content">
              {dataConfirmHistory && dataConfirmHistory.length > 0 ? (
                dataConfirmHistory?.map((item, index) => (
                  <Grid
                    container
                    spacing={1}
                    key={item.id}
                    sx={{
                      borderBottom: index === dataConfirmHistory.length - 1 ? "none" : `1px solid ${theme.colors.lightgray09}`,
                      paddingBottom: 1,
                      alignItems: "center"
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                      <div className="userContainer">
                        <Avatar src={linkAvatar(item?.avatar)} />
                        <p>{item.employee_name}</p>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">チェック者</p>
                      </div>
                      <p>{item.inspector}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">運転者</p>
                      </div>
                      <p>{item.driver}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                      <div className="recordName">
                        <p className="recordName__text">車両名</p>
                      </div>
                      <p>{item.vehicle}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                      <div className="recordName">
                        <p className="recordName__text">チェック製品</p>
                      </div>
                      <p>{item.device_check}</p>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">出勤/退勤</p>
                      </div>
                      <div>
                        <p>{item.start_time_check_alcohol.slice(0, 5)}</p>
                        <p>{item.end_time_check_alcohol.slice(0, 5)}</p>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>
                      <div className="recordName">
                        <p className="recordName__text">数値</p>
                      </div>
                      <div>
                        <p>{item.start_capacity_alcohol} mg</p>
                        <p>{item.end_capacity_alcohol} mg</p>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                      <div className="recordName">
                        <p className="recordName__text">管理者確認</p>
                      </div>
                      <div>{item.alcohol_checker}</div>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>
                  アルコール濃度に関するデータはありません
                </p>
              )}
            </div>
          </Grid>
        </Grid>
      </div>



      {/* <StyledAlcoholChecker>
                <CircularLoader loading={loading} />
                <table>
                    <thead>
                        <tr>
                            <th>名前</th>
                            <th>チェック者</th>
                            <th>運転者</th>
                            <th>車両名</th>
                            <th>チェック製品</th>
                            <th>出勤/退勤</th>
                            <th>数値</th>
                            <th>確認</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 ? (
                            data?.map((item) => (
                                <tr key={item.id}>

                                    <td key={item.id}>{item.employee_name}</td>
                                    <td key={item.id}>{item.inspector}</td>
                                    <td key={item.id}>{item.driver}</td>
                                    <td key={item.id}>{item.vehicle}</td>
                                    <td key={item.id}>{item.device_check}</td>
                                    <td key={item.id}>
                                        <div>{item.start_time_check_alcohol.slice(0, 5)}</div>
                                        <div>{item.end_time_check_alcohol.slice(0, 5)}</div>
                                    </td>
                                    <td key={item.id}>
                                        <div>{item.start_capacity_alcohol} mg</div>
                                        <div>{item.end_capacity_alcohol} mg</div>
                                    </td>

                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedIds[item.id]}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td style={{ textAlign: "center" }} colSpan={8}>
                                    アルコール濃度に関するデータはありません
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </StyledAlcoholChecker>
            <StyledAlcoholInformationHistory>
                <CircularLoader loading={loading} />
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>名前</th>
                            <th>チェック者</th>
                            <th>運転者</th>
                            <th>車両名</th>
                            <th>チェック製品</th>
                            <th>出勤/退勤</th>
                            <th>数値</th>
                            <th>管理者確認</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataConfirmHistory && dataConfirmHistory.length > 0 ? (
                            dataConfirmHistory?.map((item, i) => (
                                <tr key={item.id}>

                                    <td key={item.id}>{i + 1}</td>
                                    <td key={item.id}>{item.employee_name}</td>
                                    <td key={item.id}>{item.inspector}</td>
                                    <td key={item.id}>{item.driver}</td>
                                    <td key={item.id}>{item.vehicle}</td>
                                    <td key={item.id}>{item.device_check}</td>
                                    <td key={item.id}>
                                        <div>{item.start_time_check_alcohol.slice(0, 5)}</div>
                                        <div>{item.end_time_check_alcohol.slice(0, 5)}</div>
                                    </td>
                                    <td key={item.id}>
                                        <div>{item.start_capacity_alcohol} mg</div>
                                        <div>{item.end_capacity_alcohol} mg</div>
                                    </td>

                                    <td key={item.id}>{item.alcohol_checker}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td style={{ textAlign: "center" }} colSpan={9}>
                                    アルコール濃度に関するデータはありません
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </StyledAlcoholInformationHistory> */}
    </StyledAlcoholTable>
  );
};

export default AlcoholCheckerTable;
