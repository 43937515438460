import moment from "moment";
// import {extendMoment} from 'moment-range';

export const formatDateOfBirth = (date: any) => {
    return `${moment(date).format("YYYY-MM-DD")}`;
};

// check array date is greater than current date
export const formatDateAndTime = (date: any) => {
    return moment(date).format("YYYY/MM/DD・h:mm");
};

// convert data response to calendar data
export const convertCalendarData = (data: any, userId: any) => {
    return data.map((item) => ({
        start: item?.from,
        end: moment(item?.to).add(1, "days").subtract({ hours: 0, minutes: 0, seconds: 59 }),
        user: item?.user,
        borrowingId: item?.id,
        // allDay: true,
        isDragable: item?.user?.id === userId ? true : false,
        itemType: item?.item_type,
    }));
};

// get list range date from start date to end date -1 date
export const getListRangeDateMoment = (startDate: any, endDate: any, type: any) => {
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, type);
    const range = [] as any;
    for (let i = 0; i < diff; i++) {
        range.push(moment(startDate).add(i, type));
    }
    return range;
};
// get list range date from start date to end date
export const dateListMoment = (start: any, end: any, isMoment?: any) => {
    let now = moment.utc(start);
    end = moment.utc(end);
    const dates = [] as any;
    while (
        now.isValid() &&
        end.isValid() &&
        now.isSameOrBefore(end, "day") /* end day inclusive */
    ) {
        dates.push(now);
        now = moment(now).add(1, "days"); // add 1 day on a new instance
    }
    if (isMoment) {
        return dates;
    }
    return dates.map((item) => moment(item).format("YYYY-MM-DD"));
};

// group dates
export const groupDateResponse = (listDates: any) => {
    const list = listDates.map((item) => dateListMoment(item.from, item.to));

    const merged = [].concat(...list) as any;
    return merged.map((date) => moment(date).format("YYYY-MM-DD"));
};

// campare date to current date
export const compareDateToCurrentDate = (selectDate: any) => {
    const currentDate = moment(Date.now()).format("YYYY-MM-DD");
    const currentDateMoment = moment(currentDate);
    const selectDateMoment = moment(selectDate);
    return selectDateMoment.diff(currentDateMoment, "days");
};

// check list selected Dates contains previous date
export const checkListDateContainsPreviousDate = (listSelectedDate: Array<any>) => {
    for (let i = 0; i < listSelectedDate.length; i++) {
        if (compareDateToCurrentDate(listSelectedDate[i]) < 0) {
            return false;
        }
    }
    return true;
};
// count hours between two dates
export const countHoursBetweenTwoDates = (startDate: any, endDate: any) => {
    const totalDays = dateListMoment(startDate, endDate).length;
    const totalHours = totalDays * 24;
    return totalHours;
};
// Count hours between 2 time
export const minutesOfDay = function (m) {
    return m.minutes() + m.hours() * 60;
};
export const convertDateToTime = (date: any) => {
    const time = date.toTimeString().split(" ")[0].split(":");
    return parseInt(time[0]) + ":" + time[1];
};

export const convertMinutesToHourAndMinute = (minutes: any) => {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return h + "." + m;
};

// convert time to datetime
export const convertTimeToDateTime = (time: any) => {
    const momentTime = moment(time, "HH:mm");
    // return momentTime.format('YYYY-MM-DD HH:mm');
    return momentTime.toDate();
};

// convert date string to Date()
export const convertStrToDate = (dateStr: any) => {
    return dateStr ? moment(dateStr, "YYYY-MM-DD").toDate() : dateStr;
};

// from "05:30" => "5:30"
export const formatTime = (time: any) => {
    const arr = time.split("");
    if (arr[0] === "0") {
        return time.slice(1);
    }
    return time;
};

export const getMomentFromTimeString = (str) => {
    const t = moment(str, "HH:mm A");
    // Now t is a moment.js object of today's date at the time given in str

    if (t.get("hour") < 22)
        // If it's before 9 pm
        t.add("d", 1); // Add 1 day, so that t is tomorrow's date at the same time

    return t;
};

// Date() => [1,2...30] (list days of the month)
export const getDaysArrayMoment = (time: any) => {
    const timeFormat = moment(time).format("YYYY/MM");
    const month = timeFormat.split("/")[1];
    const year = timeFormat.split("/")[0];
    const monthIndex = parseInt(month) - 1; // 0..11 instead of 1..12
    const date = new Date(parseInt(year), monthIndex, 1) as any;
    const result = [] as any;
    while (date.getMonth() === monthIndex) {
        result.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }
    return result;
};

export const getDaysWeeksArrayMoment = (time: any) => {
    const timeFormat = moment(time).format("YYYY/MM");
    const month = timeFormat.split("/")[1];
    const year = timeFormat.split("/")[0];
    const monthIndex = parseInt(month) - 1;
    const names = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    const date = new Date(parseInt(year), monthIndex, 1) as any;
    const result = [] as any;
    while (date.getMonth() === monthIndex) {
        result.push({
            dayNumber: date.getDate(),
            dayInWeek: names[moment(`${timeFormat}/${date.getDate()}`).isoWeekday() - 1],
        });
        date.setDate(date.getDate() + 1);
    }

    return result;
};

export const getDayIndexMoment = (time: any) => {
    const formatTime = moment(time).format("YYYY/MM/DD");
    const day = formatTime.split("/")[2];
    return parseInt(day);
};

// format date in history borrowing vehicle, instrument Item;
export const getListDateEvents = (listDate: any) => {
    return (
        listDate &&
        listDate.map((item) => {
            if (item?.returned?.length) {
                return {
                    ...item,
                    to: moment(item?.returned?.to).format("YYYY-MM-DD"),
                };
            } else {
                if (compareDateToCurrentDate(item?.to) !== 0) {
                    return item;
                } else {
                    return {
                        ...item,
                        to: moment().format("YYYY-MM-DD"),
                    };
                }
            }
        })
    );
};

// validate dates resize events
export const validateResizeEvents = (selectedEvent, endDate, startDate, events) => {
    let checkExistingDate = false;
    // if (compareDateToCurrentDate(endDate) <= -1 || compareDateToCurrentDate(startDate) <= -1) {
    //     return true;
    // }
    const listFilter = events.filter((item) => item.borrowingId !== selectedEvent.borrowingId);
    const temp = listFilter.map((i) => {
        return [...dateListMoment(i.start, i.end)];
    });
    const merged = [].concat(...temp) as any;
    checkExistingDate =
        merged.includes(moment(endDate).format("YYYY-MM-DD")) ||
        merged.includes(moment(startDate).format("YYYY-MM-DD"));
    return checkExistingDate;
};

// view month of moment date
export const getMonthMoment = (date: any) => {
    return moment(date, "YYYY/MM/DD").month() + 1;
};
// check date contain
export const checkListMonthDate = (listSelectDate: any) => {
    const check = true;
    const listMonths = listSelectDate?.map((item) => getMonthMoment(item));
    for (let i = 0; i < listMonths.length - 1; i++) {
        for (let j = i + 1; j < listMonths.length; j++) {
            if (listMonths[j] !== listMonths[i]) {
                return false;
            }
        }
    }
    return check;
};

// check followng month
// export const checkListFollowingDate = (listSelectDate: any) => {
//     const check = true;
//     const listMonths = listSelectDate?.map((item) => getMonthMoment(item));
//     for (let i = 0; i < listMonths.length - 1; i++) {
//         for (let j = i + 1; j < listMonths.length; j++) {
//             if (listMonths[j] - listMonths[i]  1) {
//                 return false;
//             }
//         }

//     }
//     return check;
// };
export const checkSelectSlot = (listSelectedSlot: any, slot: any) => {
    if (!listSelectedSlot.length) {
        return true;
    } else {
        const checkFirstDate = listSelectedSlot
            ?.map((item) => moment(item).format("YYYY-MM-DD"))
            .includes(moment(slot[0]).format("YYYY-MM-DD"));
        const checkLastDate = listSelectedSlot
            ?.map((item) => moment(item).format("YYYY-MM-DD"))
            .includes(moment(slot[slot.length - 1]).format("YYYY-MM-DD"));
        if (checkFirstDate || checkLastDate) {
            return true;
        } else {
            return false;
        }
    }
};

// format to japanese timezone
export const formtJPT = (date: any, formatString: string) => {
    // return moment(date).tz("Asia/Tokyo").format("YYYY-MM-DD・HH:mm");
    return moment(date).tz("Asia/Tokyo").format(formatString);
};
