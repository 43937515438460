import React from "react";
import { Formik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppButton, CircularLoader, AppModal, InstrumentEditTime, PassChangeButton } from "..";
import { StyledVehicleCalendar } from "./styles";
import arrow_left from "@assets/Icons/arrow_left.svg";
import { appRouteEnums } from "@enums";
import { getListDateEvents, groupDateResponse } from "@utils";
import { updateSnackBar, setSelectedDateVehicle } from "@redux";
import { instrumentApi, vehicleApi } from "@api";
import moment from "moment";
import AppDragAndDrop from "../Calendar/AppDragAndDropCalendar";

export const VehicleCalendarComponent = () => {
  // component state
  const [selected, setSelected] = React.useState<any>([]);
  const [calendarData, setCalendarData] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams<{ id: any }>();
  const [reloadPage, setReloadPage] = React.useState<boolean>(false);
  const [dataEdit, setDataEdit] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [slots, setSlots] = React.useState<any>();
  // form value
  const initialValuesPackage = {
    selectValue: "",
  };
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const categoryId = query.get("categoryId");
  const subCategoryId = query.get("subCategoryId");
  const resId = query.get("resId");

  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  React.useEffect(() => {
    fetchCalendaData(id);
    return () => {
      // do clean up function
    };
  }, [reloadPage]);
  // api show calendar data
  const fetchCalendaData = async (id) => {
    setLoading(true);
    try {
      const response = await vehicleApi.vehicleItemCalendar({
        id: id,
      });
      setLoading(false);
      setCalendarData(response.data.data);
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response.data.message,
        })
      );
    }
  };
  const fetchUpdateBorrowingItem = async (values: any) => {
    setLoading(true);
    try {
      const res = await instrumentApi.updateBorrowItem(
        {
          from: moment(values?.dateRate.startDate).format("YYYY-MM-DD"),
          to: moment(values?.dateRate.endDate).format("YYYY-MM-DD"),
        },
        dataEdit?.borrowingId
      );
      setReloadPage(!reloadPage);
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "success",
          message: res.data.message,
        })
      );
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response?.data?.data,
        })
      );
    }
  };
  const handleOpenEditModal = (values: any) => {
    setSlots(values.selectedDate);
    setDataEdit(values.events);
    setOpenModal(true);
  };
  const handleCloseEditModal = () => {
    setDataEdit("");
    setOpenModal(false);
  };
  const handleEditSubmit = (values: any) => {
    fetchUpdateBorrowingItem(values);
    handleCloseEditModal();
  };
  React.useEffect(() => {
    dispatch(setSelectedDateVehicle(selected));
  }, [selected]);
  return (
    <StyledVehicleCalendar>
      {/* <AppTopBar /> */}
      <AppModal open={openModal} handleClose={handleCloseEditModal}>
        <InstrumentEditTime
          data={dataEdit}
          slots={slots}
          handleEditSubmit={handleEditSubmit}
        />
      </AppModal>
      <CircularLoader loading={loading} />
      <div className="VehicleDetail">
        <div className="VehicleDetail__top">
          {/* <span
            className="VehicleDetail__back"
            onClick={() => {
              // history.push(appRouteEnums.PATH_VEHICLES)
              categoryId
                ? history.push(
                  appRouteEnums.PATH_VEHICLES +
                  `?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
                )
                : history.push(appRouteEnums.PATH_VEHICLES);
            }}
          >
            <img src={arrow_left} alt="<" />
          </span> */}
          <div className="VehicleDetail__top-title">
            <p className="main">車両</p>
            {/* <p className="main">{calendarData?.title}</p>
            {calendarData?.category ? (
              <p className="subTitle">{calendarData?.category}</p>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <Formik
          initialValues={initialValuesPackage}
          onSubmit={() => {
            resId ? history.push(`/vehicles/${id}?resId=${resId}`) : history.push(`/vehicles/${id}`);
          }}
        >
          {({ handleSubmit }) => {
            return (
              <div className="VehicleDetail__wrapperContent">
                <div className="VehicleDetail__selectWrapper"></div>
                {/* <div className="VehicleDetail__wrapperContent"> */}
                <div className="VehicleDetail__calendarWrapper">
                  <div className="calendarTitle">
                    <p className="main">{calendarData?.title}</p>
                    {calendarData?.category ? (
                      <p className="subTitle">{calendarData?.category}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="calendar">
                    <div className="calendar-content">
                      {calendarData && (
                        // <AppCalendar
                        //     listEventsDate={groupDateResponse(
                        //         getListDateEvents(calendarData?.history)
                        //     )}
                        //     events={getListDateEvents(
                        //         calendarData?.history
                        //     )}
                        //     setSelected={setSelected}
                        //     handleOpenEditModal={handleOpenEditModal}
                        // />

                        <AppDragAndDrop
                          selected={selected}
                          events={getListDateEvents(
                            calendarData?.history
                          )}
                          setSelected={setSelected}
                          listEventsDate={groupDateResponse(
                            getListDateEvents(calendarData?.history)
                          )}
                          handleOpenEditModal={handleOpenEditModal}
                        />
                      )}
                    </div>
                    <div className="wrappBtn">
                      {/* <div className="btn"> */}
                      <PassChangeButton onClick={() => handleSubmit()}>次へ</PassChangeButton>
                      {/* <AppButton
                      onClick={handleSubmit}
                      disabled={selected ? false : true}
                      text="次へ"
                    /> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            );
          }}
        </Formik>
      </div>
    </StyledVehicleCalendar>
  );
};
