import styled from "styled-components";
import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledDailogView = styled.div`
    min-height: 100vh;
    // background-image: url(${small_bg_dot});
    // background-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors.white02};

    .searchContainer {
      margin:2rem 0;
    }

    .datePicker {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        .wrapperDatePicker {
            max-width: 32rem;
        }
        .label {
            display: flex;
            align-items: center;
            padding-right: 2.5rem;
        }
    }
    .wrapperInput {
        display: flex;
        justify-content: center;
        grid-column-gap: 2rem;

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            justify-content: space-between;
        }
    }
    .inputContent {
      width:100%;

    }
    .daily {
        text-align: left;
        padding: 1rem 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
        }
            margin-top:7rem;
            padding-bottom:14rem;
        &__title {
            padding-bottom: 2rem;
            border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
        }
        &__bottom {
            display: flex;
            justify-content: space-between;
            padding: 1.6rem;
            background-color: #04182a;
            color: ${(p) => p.theme.colors.primary};
            margin-top: 4rem;
        }
        .errorMessage {
            padding-top: 4rem;
            width: 100%;
            text-align: center;
            color: ${(p) => p.theme.colors.error};
        }
        .date{
            margin-top: 60px;
        }
    }
`;
export const StyledDailyTable = styled.div`
    .dailyView {
        width: 100%;
        margin-top: 2.5rem;
    }
    .title {
        text-align: left;
        padding-bottom: 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            text-align: center;
        }
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
        font-size: 1.6rem;
        &__number {
            padding-right: 2rem;
        }
    }
    .content {
        padding-bottom: 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            background: ${(p) => p.theme.colors.white};
        }
        word-break: break-all;
        &__title {
            color: #d4d4d4;
            padding: 1rem 2rem;
            padding-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;
            /* text-align: left; */
            font-family: ${(p) => p.theme.colors.NotoSansJP_700};
            background-color: ${(p) => p.theme.colors.white};
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                background-color: #f0f6fd;
            }
            &-itemTitle {
                width: 20%;
                padding-right: 1rem;
            }
            &-itemContent {
                text-align: center;
                border-left: 1px solid #d4d4d4;
                padding: 0 1rem;
                width: 65%;
                text-align: left;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    border-left: 0px solid transparent;
                }
            }
            &-itemNumber {
                width: 15%;
                text-align: left;
                border-left: 1px solid #d4d4d4;
                padding-left: 1rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    border-left: 0px solid transparent;
                }
            }
        }
        .wrapperContent {
            margin-bottom: 1rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding-bottom: 1rem;
                border-bottom: 1px solid #d5d5d5;
                margin-bottom: 0;
            }
        }
        .wrapperContent:last-child {
            border-bottom: 0px solid transparent;
        }
        &__content {
            padding: 2rem 2rem;
            padding-top: 0;
            padding-bottom: 1rem;
            background-color: ${(p) => p.theme.colors.white};
            &-item {
                display: flex;
                justify-content: space-between;
            }
            &-itemTitle {
                width: 20%;
                padding-top: 1rem;
                padding-right: 1rem;
            }
            &-itemContent {
                text-align: center;
                border-left: 1px solid #d4d4d4;
                padding: 0 1rem;
                padding-top: 1rem;
                width: 65%;
                text-align: left;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    border-left: 0px solid transparent;
                }
            }
            &-itemNumber {
                width: 15%;
                text-align: left;
                border-left: 1px solid #d4d4d4;
                padding-top: 1rem;
                padding-left: 1rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    border-left: 0px solid transparent;
                }
            }
        }
        .note {
            margin: 1.2rem;
            background-color: #ffe7d1;
            border-radius: 0.8rem;
            &__text {
                padding: 1.5rem;
                font-size: 1.6rem;
                color: #7a3b00;
            }
        }
    }
    .bottom {
        text-align: right;
        padding: 0.4rem 2rem;
        background: linear-gradient(270deg, #ccf2fa 0%, rgba(204, 242, 250, 0) 100%);
        color: ${(p) => p.theme.colors.primary};
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
        font-size: 1.6rem;
        margin-top: 0.9rem;
        &__number {
            padding-left: 1rem;
        }
    }
`;
export const StyledDaily = styled.div`
  color:${(p)=>p.theme.colors.black01};

.data-table {
  background-color:${(p)=>p.theme.colors.white00};
  border-radius:6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.top-titles {
  color:${(p)=>p.theme.colors.black01};
  background-color:${(p)=>p.theme.colors.lightgray09};
  border-top-right-radius:6px;
  border-top-left-radius:6px;
  padding:1rem;

  @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
    display:none;
  }
}

.contentsContainer {
  margin:1rem;
}

.daily {

  &__userContainer {
    display:flex;
    align-items:center;
    gap:0.5rem;
  }

  &__userAvatar {

  }

}

.approval {
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;

    &-checked {
      background-color:${(p)=>p.theme.colors.green07};
      border-radius:50px;
      display:flex;
      align-items:center;
      justify-content:center;
      width:3rem;
      height:3rem;
    }

    &-rejected {
      background-color:${(p)=>p.theme.colors.red05};
      border-radius:50px;
      display:flex;
      align-items:center;
      justify-content:center;
      width:3rem;
      height:3rem;
    }

    &-unchecked {
      background-color:${(p)=>p.theme.colors.lightgray09};
      border-radius:50px;
      display:flex;
      align-items:center;
      justify-content:center;
      width:3rem;
      height:3rem;
    }

  }
    .subtitle {

      &__text{
        color:${(p)=>p.theme.colors.gray03};

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display:none;
      }
      }
      }

    .total {


      @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display:flex;
        gap:1rem;
      }
    }

    .item {
        width: 100%;
        padding: 1.6rem;
        margin: 1.5rem 0;
        border-bottom: 1px solid #d6d6d6;
        &__top {
            display: flex;
            justify-content: space-between;
        }
        &__userInfo {
            display: flex;
            flex: 1.4;
            &-title {
                padding-left: 1rem;
                .name {
                    font-size: 1.6rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    display: block;
                    display: -webkit-box;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &__status {
            flex: 0.5;
            text-align: center;
            &-checked {
                justify-content: center;
                padding: 0.4rem 1rem;
                background-color: #d6fddc;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #0aba3b;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                justify-content: center;
                padding: 0.4rem 1rem;
                background-color: #fdd6d6;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #ba0a0a;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                justify-content: center;
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
            }
        }
        &__title {
            flex: 1.5;
            .content {
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 20px;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .contractor-item-height {
            margin-top: 2rem;
        }
        &__date {
            display: flex;
            justify-content: space-between;
            padding-top: 2.5rem;
            flex: 7;
            &-start {
                flex: 1.5;
            }
            &-end,
            &-hour {
                flex: 3.5;
                /* padding-right: 4rem; */
                width: 100%;
                display: flex;
                justify-content: center;
                .item__work-type {
                    .work-type-item-title {
                        .title {
                            width: 40%;
                        }
                    }
                    .work-type-item {
                        .work-type-content {
                            max-width: 120px;
                        }
                    }
                }
            }
            &-number {
                flex: 1;
            }

            .title {
                font-size: 1.4rem;
                color: #d4d4d4;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            }
            .work-type-title {
                text-align: center;
            }
        }
        &__work-type {
            display: flex;
            flex-direction: column;
            width: 100%;
            .work-type-item {
                display: flex;
                width: 100%;
                div {
                    width: calc(100% / 3 - 0.5rem);
                }
                /* div ~ div {
                    padding-left: 1rem;
                } */
                /* padding-right: 4rem; */
            }
            .work-type-item-title {
                width: 100%;
                display: flex;
                p {
                    width: calc(100% / 3 - 0.5rem);
                }
                /* p ~ p {
                    padding-left: 1rem;
                } */
            }
            .work-type-content {
                width: 100%;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 1rem;
            }
        }
        .desktop {
            display: none;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                width: 60%;
                padding-top: 0;
            }
        }
        .mobile {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
        }
    }
`;
