import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyleTimeSheet, TimeSheetCalendarComponent, TimeSheetToggleModal } from ".";
import {
  CircularLoader,
  TitleMenuComponent,
  AppCustomeSelect,
  AppExportCsv,
  PassChangeButton,
} from "..";
import { selectAuth, useAppSelector, selectGroupsLoading, selectGroupsData, selectGroupsError, selectEmployeesData, selectTimesheetsDataByMonth, fetchTimesheets } from "@redux";
import { checkExistUserSelected, linkImage } from "@utils";
import { StyledGrayToggleButton } from "../WeeklySchedule/styles";

const defaultGroup = {
  id: 0,
  name: "全て",
};

export const TimeSheetView = () => {
  const auth = useAppSelector(selectAuth);
  const id = auth?.userInfo?.id;

  // hooks
  const dispatch = useDispatch();
  const history = useHistory();
  //const location = useLocation();

  const currentDate = new Date();
  const initialYear = currentDate.getFullYear();
  const initialMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
  const initialCalendarMonth = (`${initialYear}-${initialMonth}`);
  const initialCsvStartDate = new Date(Date.UTC(initialYear, currentDate.getMonth(), 1));
  const initialCsvEndDate = new Date(Date.UTC(initialYear, currentDate.getMonth() + 1, 0));

  // component state
  const [timeSheetDateData, setTimeSheetDateData] = React.useState<any>();
  const [timeSheetIdData, setTimeSheetIdData] = React.useState<any>();
  const [timesheetListData, setTimeSheeListData] = React.useState<any>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<any>();
  const [inputGroupValue, setInputGroupValue] = React.useState<any>();
  const [inputStatusValue, setInputStatusValue] = React.useState<any>();
  const [currentMonth, setCurrentMonth] = React.useState(initialCalendarMonth); // YYYY-MM format
  const [loading, setLoading] = React.useState<boolean>();
  const [groups, setGroups] = React.useState<any>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);
  const [cvsStartDate, setCvsStartDate] = React.useState<string>(initialCsvStartDate.toISOString().split('T')[0]);
  const [cvsEndDate, setCvsEndDate] = React.useState<string>(initialCsvEndDate.toISOString().split('T')[0]);
  const [options, setOptions] = React.useState<{ label: string; value: string, type: string }[]>([]);

  const statusOptions: { label: string; value: string }[] = [
    { label: "全て", value: "all" },
    { label: "未承認", value: "pending" },
    { label: "承認済み", value: "approved" },
    { label: "差し戻し", value: "rejected" }
  ];

  // Redux selectors for groups, timesheets, and employees
  const groupsLoading = useAppSelector(selectGroupsLoading);
  const groupsData = useAppSelector(selectGroupsData);
  const groupsError = useAppSelector(selectGroupsError);

  //const timesheetsLoading = useAppSelector(selectTimesheetsLoading);
  const timesheetsDataForMonth = useAppSelector((state) => selectTimesheetsDataByMonth(state, currentMonth));
  //const timesheetsError = useAppSelector(selectTimesheetsError);

  //const employeesLoading = useAppSelector(selectEmployeesLoading);
  const employeesData = useAppSelector(selectEmployeesData);
  //const employeesError = useAppSelector(selectEmployeesError);

  //    // Scroll to the top of the page when the component mounts
  //    useEffect(() => {
  //        window.scrollTo(0, 0);
  //    }, [location]);

  useEffect(() => {
    if (groupsData) { setGroups(groupsData); }
  }, [groupsData]);

  useEffect(() => {
    if (employeesData) {
      //            const sortedUsers = (!Array.isArray(employeesData)) ? [] : employeesData
      //                .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0));
      //            setUsers(sortedUsers);
      setUsers(employeesData);
    }
  }, [employeesData]);


  // Check if timesheets for the selected month exist
  React.useEffect(() => {
    if (timesheetsDataForMonth) {
      // const selectedUserId = inputValue && parseInt(inputValue?.value) || null;
      // const selectedGroupId = inputGroupValue && parseInt(inputGroupValue?.value) || null;
      const selectedTSStatus = inputStatusValue && inputStatusValue?.value || null;

      let filteredTimesheets = filterTimesheets(timesheetsDataForMonth);

      if (selectedTSStatus) {
        filteredTimesheets = (filterTimesheetsStatus(filteredTimesheets, inputStatusValue.value));

      }
      // If the data is already available, use it directly
      setTimeSheeListData(filteredTimesheets);
      setLoading(false);
    } else {
      // If the data is not available, dispatch fetchTimesheets to load it
      dispatch(fetchTimesheets({ month: currentMonth }));

      // Set loading state while fetching data
      setLoading(true);
    }
  }, [currentMonth, timesheetsDataForMonth, inputValue?.value, inputGroupValue?.value, inputStatusValue?.value, selectedGroup]);

  // useEffect(() => {
  //   if (inputStatusValue) {
  //     setTimeSheeListData(filterTimesheetsStatus(timesheetsDataForMonth, inputStatusValue.value));
  //   }
  // }, [inputStatusValue, timesheetsDataForMonth]);


  useEffect(() => {
    if (users && groups) {

      const userOption = [...users].filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id))).sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user) => ({
        label: `${user.last_name}${user.first_name}`,
        value: user?.id?.toString(),
        type: "user",
      }));

      const groupOption = groups.map((group) => ({
        label: group.name,
        value: group.id.toString(),
        type: "group",
      }));

      setOptions([...userOption, ...groupOption]);
      setInputValue(userOption[0]);
    }
  }, [users, groups]);

  // Fetch API timesheet data for months other than the current month
  //    const fetchApiDetailTimeSheet = async (params: any) => {
  //        setLoading(true);
  //        try {
  //            const responseGroups = await timesheetApi.listGroupsTimesheet();
  //            setGroups(responseGroups.data);
  //            const responseTimesheet = await timesheetApi.listTimesheetByGroup(
  //                params,
  //                selectedGroup
  //            );
  //            setTimeSheeListData(responseTimesheet.data);
  //            setSelectedGroup(selectedGroup ? selectedGroup : 0);
  //            const responseListUsers = await timesheetApi.listUsersInGroup(selectedGroup);
  //            const filteredSortedUsers = (!responseListUsers?.data?.data || !Array.isArray(responseListUsers.data.data)) ? [] : responseListUsers.data.data
  //                //.filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
  //                .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0));
  //            setUsers(filteredSortedUsers);
  //            //setUsers(responseListUsers.data.data);
  //            setLoading(false);
  //        } catch (err: any) {
  //            setLoading(false);
  //            dispatch(
  //                updateSnackBar({
  //                    messageType: "error",
  //                    message: err.response?.data?.message,
  //                })
  //            );
  //        }
  //    };
  // events
  const handleOpenModal = (date: any, id: number) => {
    setOpenModal(true);
    setTimeSheetDateData({
      date: date.startDate[0],
    });
    setTimeSheetIdData({
      id,
    });
  };
  const handleOpenModalId = (date: any, id: number) => {
    setOpenModal(true);
    setTimeSheetDateData({
      date: date.startDate,
    });
    setTimeSheetIdData({
      id,
    });
  };

  const handleCloseToggle = () => {
    setOpenModal(false);
    setTimeSheetDateData([]);
    setTimeSheetIdData("");
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value; // The value will be in 'YYYY-MM-DD' format
    const selectedMonth = selectedDate.slice(0, 7); // Extract the month part
    setCurrentMonth(selectedMonth);
  };

  const handleSelectGroup = (id) => {
    setInputValue("");
    if (id === 0) {
      setSelectedGroup([]);
      setTimeSheeListData(timesheetsDataForMonth);
      return;
    }
    const newSelectedGroup = checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id];
    setSelectedGroup(newSelectedGroup);
    setTimeSheeListData(filterTimesheets(timesheetsDataForMonth, newSelectedGroup));
  };

  const handleOnChange = (value) => {
    if (!value) {
      setInputValue(null);
      setInputGroupValue(null);
      setSelectedGroup([]);
      return;
    }

    // setInputStatusValue(null);

    if (value.type === "user") {
      setSelectedGroup([]);
      setInputValue(value);
    }

    if (value.type === "group") {
      setInputValue("");
      setInputGroupValue(value);
      setSelectedGroup([]);
      const groupId = parseInt(value.value);

      if (groupId === 0) {
        setSelectedGroup([]);
        setTimeSheeListData(timesheetsDataForMonth);
        return;
      }

      setSelectedGroup(() => {
        const updatedGroup = [groupId];
        setTimeSheeListData(filterTimesheets(timesheetsDataForMonth, updatedGroup));

        return updatedGroup;
      });

    }

  };

  const handleOnChangeStatus = (value) => {
    if (!value) {
      setInputStatusValue(null);
      // setInputValue(null);
      // setInputGroupValue(null);
      // setSelectedGroup([]);
      return;
    }

    // setInputValue(null);
    // setInputGroupValue(null);

    setInputStatusValue(value);

  };

  const filterTimesheetsStatus = (timesheets, selectedStatus) => {
    const status = selectedStatus === "approved" ? 1 : selectedStatus === "rejected" ? 2 : selectedStatus === "pending" ? 0 : 3;
    return timesheets.map((day) => {
      const filteredUsers = day.users.filter((user) => {

        const userHasMatchingTimesheet = user.timesheets.some((timesheet) => {
          const isDateMatching = timesheet.date === day.startDate;

          let isStatusMatching = true;

          if (status !== 3) {
            isStatusMatching = status === timesheet.status;
          }

          return isDateMatching && isStatusMatching;

        });

        return userHasMatchingTimesheet;
      });

      return {
        ...day,
        users: filteredUsers,
      };
    });
  };

  const filterTimesheets = (timesheets, mewSelectedGroup = selectedGroup) => {
    return timesheets.map((day) => {
      // Filter users for the current day
      const filteredUsers = day.users.filter((user) => {
        // Filter the user's timesheets based on date and group_id conditions
        const isUserMatching = !inputValue || parseInt(inputValue.value) === user.id;

        const userHasMatchingTimesheet = user.timesheets.some((timesheet) => {
          // Condition 1: The timesheet date must match the day's startDate
          const isDateMatching = timesheet.date === day.startDate;

          // Condition 2: If no groups are selected, all timesheets are valid.
          // Otherwise, only timesheets with a group_id in selectedGroup should be considered.
          const isGroupMatching =
            mewSelectedGroup.length === 0 || mewSelectedGroup.includes(timesheet.group_id);

          return isDateMatching && isGroupMatching;
        });

        // Include the user only if they have at least one matching timesheet
        return isUserMatching && userHasMatchingTimesheet;
      });

      // Return a new day object with filtered users. If no users match, this day will have an empty users array.
      return {
        ...day,
        users: filteredUsers,
      };
    });
  };

  const groupsContent = groupsLoading ? (
    <span>Loading...</span>
  ) : groupsError ? (
    <div> Groups error: {groupsError} </div>
  ) : (
    groups.map((item) => (
      <StyledGrayToggleButton
        key={item.id}
        isActive={checkExistUserSelected(item.id, selectedGroup)}
        onClick={() => handleSelectGroup(item.id)}
      >
        {item.name}
      </StyledGrayToggleButton>
      // <div
      //   key={item.id}
      //   onClick={() => handleSelectGroup(item.id)}
      //   className={clsx(
      //     checkExistUserSelected(item.id, selectedGroup)
      //       ? "active"
      //       : "",
      //     "timesheet__filter-item"
      //   )}
      // >
      //   <span className="title">{item.name}</span>
      //   <Box sx={{ display: "flex", alignItems: "center" }}>
      //     {checkExistUserSelected(item.id, selectedGroup) && (
      //       <img src={tick_icon} alt="icon" />
      //     )}
      //   </Box>
      // </div>
    ))
  );

  return (
    <StyleTimeSheet>
      {/* <AppTopBar /> */}
      <TimeSheetToggleModal
        openToogle={openModal}
        anchor="right"
        handleCloseToggle={handleCloseToggle}
        timeSheetDateData={timeSheetDateData}
        id={timeSheetIdData}
        selectedGroup={selectedGroup}
      />
      <CircularLoader loading={loading} />
      <div className="timesheet">
        {/* <p
          className="newOT__top-icon"
          onClick={() => history.push(appRouteEnums.PATH_HOME)}
        >
          <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
        </p> */}
        <div className="timesheet__title to-link">
          <TitleMenuComponent text={menuTitleEnums.TITLE_TIMESHEET} />

          {/* <WrapLink onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>
            週間予定
          </WrapLink> */}
        </div>
        <div className="timesheet__datePicker">
          <div style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "1rem" }}>
            <div className="label">月を選択</div>
            <div className="wrapperDatePicker">
              <input
                type="month"
                // value={`${currentMonth}-01`}
                value={currentMonth}
                onChange={handleChange}
                className="inputMonth"
              />
            </div>
          </div>
          {/* <PassChangeButton onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>
            週間予定
          </PassChangeButton> */}
          <div className="timesheet__search">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "1rem" }}>
              <div className="label">班・従業員</div>
              <div className="wrapperSelectGroup">
                <AppCustomeSelect
                  placeholder="人・班を選択"
                  onChange={handleOnChange}
                  groupUserOptions={options}
                  options={options}
                  value={(inputValue === "") ? inputGroupValue : inputValue}
                  isClearable
                />
              </div>
            </div>
          </div>

          <div className="timesheet__search">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "1rem" }}>
              <div className="label">ステータス</div>
              <div className="wrapperSelectGroup">
                <AppCustomeSelect
                  placeholder="ステータスを選択"
                  onChange={handleOnChangeStatus}
                  groupUserOptions={statusOptions}
                  options={statusOptions}
                  value={inputStatusValue}
                  isClearable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="timesheet__form">
          {/* <div className="wrapper__filter">
            <div className="timesheet__filter">
              <StyledGrayToggleButton isActive={!selectedGroup.length}
                onClick={() => handleSelectGroup(defaultGroup.id)}>
                {defaultGroup.name}
              </StyledGrayToggleButton>
              {groupsContent}
            </div>
          </div> */}

        </div>

        <div className="timesheet__calendar">
          <TimeSheetCalendarComponent
            handleOpenModal={handleOpenModal}
            handleOpenModalId={handleOpenModalId}
            handleCloseToggle={handleCloseToggle}
            timeSheetData={timesheetListData}
            month={currentMonth}
          />
        </div>

        <div className="csvExportDiv">
          <span className="csvExportTitle">CSVエクスポート</span>
          <div className="tsDatePicker">

            <div className="dateContainer">

              <div className="label">作成日時</div>
              <div className="dateSubContainer">
                <input type="date" className="inputDate" value={cvsStartDate} onChange={(e) => setCvsStartDate(e.target.value)} />
                <span>から</span>
              </div>

              <div className="dateSubContainer">
                <input type="date" className="inputDate" value={cvsEndDate} onChange={(e) => setCvsEndDate(e.target.value)} />
                <span>まで</span>
              </div>
            </div>

            <div>
              <AppExportCsv type={"timesheets"} startDate={cvsStartDate} endDate={cvsEndDate} />
            </div>

          </div>

        </div>


        {/* {
          <div className="cvsExportDiv">
            <span className="csvExportTitle">CSVエクスポート</span>
            <div className="tsDatePicker">

              <div className="csvDateContainer">
                <div className="label">作成日時</div>

                <div className="dateSubContainer">
                  <input type="date" className="inputDate" value={cvsStartDate} onChange={(e) => setCvsStartDate(e.target.value)} />
                  <span>から</span>
                </div>

                <div className="dateSubContainer">
                  <input type="date" className="inputDate" value={cvsEndDate} onChange={(e) => setCvsEndDate(e.target.value)} />
                  <span>まで</span>
                </div>

              </div>
            </div>

            <div>
              <AppExportCsv type={"timesheets"} startDate={cvsStartDate} endDate={cvsEndDate} />
            </div>
          </div>
        } */}

      </div>



    </StyleTimeSheet>
  );
};

// const WrapLink = styled.div`
//     font-weight: 700;
//     margin-top: 27px;
//     margin-left: 40px;
//     border: 2px solid orange;
//     color: orange;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     width: fit-content;
//     padding: 8px 10px;
//     :hover {
//         cursor: pointer;
//         color: #d88d02;
//         border: 2px solid #d88d02;
//     }
// `;
