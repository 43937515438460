export enum TimeSheetFormTypeEnums {
    ENUMS_TYPE_DAYSHIT = 0, //form 1
    ENUMS_TYPE_NIGHTSHIFT = 1, //form 1
    ENUMS_TYPE_DATSHIFT_AND_NIGHTSHIFT = 2,
    ENUMS_TYPE_HOLIDAYWORK = 3, //form 1
    ENUMS_TYPE_PAID_TIME_OFF = 4, //form 2
    ENUMS_TYPE_HALFDAY_WORK = 5, //form 3
    ENUMS_TYPE_ABSENCE = 6, //form 3.
    ENUMS_TYPE_LEGAL_HOLIDAY = 7, // form 4
    ENUMS_TYPE_NIGHTSHIFT_AND_DAYSHIFT = 8,
    ENUMS_TYPE_TARDINESS = 9,
    ENUMS_TYPE_EARLY_LEAVE = 10,
    ENUMS_TYPE_OVERTIME = 11,
    ENUMS_TYPE_BEREAVEMENT_LEAVE = 12,


}
export enum TimeSheetFormNameForm {
    ENUMS_NAME_DAYSHIFT = "日勤",
    ENUMS_NAME_NIGHTSHIFT = "夜勤",
    ENUMS_NAME_DATSHIFT_AND_NIGHTSHIFT = "日勤からの夜勤",
    // 	日勤と夜勤
    ENUMS_NAME_HOLIDAYWORK = "休日出勤",
    ENUMS_NAME_PAID_TIME_OFF = "有給休暇",
    ENUMS_NAME_HALFDAY_WORK = "半日有給",
    ENUMS_NAME_ABSENCE = "欠勤",
    ENUMS_NAME_LEGAL_HOLIDAY = "公休日",
    ENUMS_NAME_OVERTIME = "残業",
    ENUMS_NAME_NIGHTSHIFT_AND_DAYSHIFT = "夜勤からの日勤",
    ENUMS_NAME_BEREAVEMENT_LEAVE = "忌引き休暇",
    ENUMS_NAME_EARLY_LEAVE = "早退",
    ENUMS_NAME_TARDINESS = "遅刻",
}
export enum WorkTypeNameEnums {
    TYPE_1 = "工種",
    TYPE_2 = "種別",
    TYPE_3 = "細別"
}

export enum ApproveTypeEnums {
    PENDING = 0,
    APPROVED = 1,
    REJECTED = 2,
}