// import React from "react";

import { StyledAlert } from ".";
// import cancel_icon from "@assets/Icons/cancel_icon.svg";
// import wrap_cancel_btn from "@assets/Icons/wrap_cancel_btn.svg";
import GoodIcon from "@assets/goodIcon.png";
import { PassChangeButton } from "src/Components/Profile";

type Props = {
  icon?: any;
  text?: string;
  handleClose?: () => void;
};

export const AppAlert = (props: Props) => {
  const { text, handleClose } = props;
  return (
    <StyledAlert>
      <div className="alert__wrapper">
        <div className="alert__content">
          {/* <span className="alert__cancelIconBg">
            <img src={wrap_cancel_btn} alt="bg" />
          </span> */}
          {/* <span className="">
                        <img className="alert__groupIcon" src={popup_icons} alt="icons" />
                    </span> */}
          {/* <span onClick={handleClose} className="alert__cancelIcon">
            <img src={cancel_icon} alt="X" />
          </span> */}
          <div className="iconAndText">

            <div>
              <img src={GoodIcon} alt="good-icon" className="alert__goodIcon" />
            </div>
            <div>
              {text && <p className="iconAndText__text">{text}</p>}
            </div>
            <div className="iconAndText__space"></div>
            <div className="leftSpace"></div>
          </div>
          {/* <span className="alert__icon">{icon}</span> */}
          <div className="buttonContainer">
            <PassChangeButton onClick={handleClose}>閉じる</PassChangeButton>
          </div>
        </div>
      </div>
    </StyledAlert>
  );
};
