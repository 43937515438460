import { FormControl, IconButton, InputAdornment, OutlinedInput } from "@mui/material";

import { StyledAppInput } from ".";
import { Error } from "./styles";
import React from "react";
import { EyeCloseIcon, EyeIcon } from "@assets";
import { MAXIMIN_PASSWORD_CHARACTERS, MAXIMIN_255_CHARACTERS } from "@configs";

type IAppInputProps = {
    label?: string;
    value?: string | number;
    name?: string;
    icon?: any;
    error?: any;
    handleChange: (value: any) => void;
    handleBlur?: (value: any) => void;
    onClick?: () => void;
    isPassword?: boolean;
    iconStart?: any;
    touched?: any;
    placeholder?: string;
    disabled?: boolean;
    isNumber?: boolean;
    max?: number;
    min?: number;
};

export const AppInput = (props: IAppInputProps) => {
    const { label, value, name, error, handleBlur, handleChange, onClick, iconStart, icon, isPassword, touched, placeholder, disabled = true, isNumber, max, min = 0 } = props;

    // component variable
    const [showPassword, setShowPassword] = React.useState(isPassword);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleOnChange = React.useCallback(
        (event: any) => {
            handleChange(event.target.value);
        },
        [handleChange]
    );

    return (
        <StyledAppInput iconStart={iconStart}>
            <div className="input__wrapper">
                {label && <div className="input__label">{label}</div>}
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <OutlinedInput
                        style={{ border: "none" }}
                        value={value}
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        name={name}
                        type={isNumber ? "number" : showPassword ? "password" : "text"}
                        inputMode={isNumber ? "numeric" : undefined}
                        autoComplete="off"
                        placeholder={placeholder}
                        sx={{
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                    borderColor: "orange",
                                },
                            },
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                onClick && onClick();
                            }
                        }}
                        startAdornment={
                            <>
                                {iconStart && (
                                    <InputAdornment position="start">
                                        <IconButton edge="end" disabled={disabled} onClick={onClick} disableRipple={true} disableFocusRipple={true}>
                                            {iconStart}
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                {isPassword && (
                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                        {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
                                    </IconButton>
                                )}
                                {icon && <IconButton edge="end">{icon}</IconButton>}
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": name,
                            maxLength: isPassword ? MAXIMIN_PASSWORD_CHARACTERS : MAXIMIN_255_CHARACTERS,
                            max: max,
                            min: min,
                            step: "0.5",
                            pattern: isNumber ? "[0-9]+(\\.[0-9]{1,2})?" : "",
                        }}
                        fullWidth
                    />
                    {/* show error message only when change input value */}
                    {!!error && !!touched && <Error>{error}</Error>}
                    {/* show error message only when imply first submit action  */}
                    {/* {!!error || (name && <ErrorMessage name={name} component={Error} />)} */}
                </FormControl>
            </div>
        </StyledAppInput>
    );
};
