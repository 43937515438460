import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyledOverTimeView, StyledWrapCalendarComponent, OverTimeToggleModal } from ".";
import {
  TitleMenuComponent,
  CircularLoader,
  AppCustomeSelect,
  AppExportCsv,
  PassChangeButton,
} from "..";
import { useAppSelector, selectAuth, selectOvertimeLoading, selectOvertimeByMonth, selectEmployeesData, fetchOvertimes, selectGroupsData } from "@redux";
import { OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import { checkExistUserSelected, filterOverTimeTitle, linkImage } from "@utils";
import { OverTimeCalendar } from "./OverTimeCalendar";
import { useDispatch } from "react-redux";
import { StyledGrayToggleButton } from "../WeeklySchedule/styles";

const defaultGroup = {
  id: 0,
  name: "全て",
};

export const OverTimeView = () => {
  // redux States
  const auth = useAppSelector(selectAuth);
  const id = auth?.userInfo?.id;

  // hooks
  const history = useHistory();
  const dispatch = useDispatch();

  const currentDate = new Date();
  const initialYear = currentDate.getFullYear();
  const initialMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
  const initialCalendarMonth = (`${initialYear}-${initialMonth}`);
  const initialCsvStartDate = new Date(Date.UTC(initialYear, currentDate.getMonth(), 1));
  const initialCsvEndDate = new Date(Date.UTC(initialYear, currentDate.getMonth() + 1, 0));

  // component states
  // const [radioValue, setRadioValue] = React.useState<any>("0");
  const [inputValue, setInputValue] = React.useState<any>("");
  const [inputGroupValue, setInputGroupValue] = React.useState<any>();
  const [inputStatusValue, setInputStatusValue] = React.useState<any>();
  // const [listOT, setListDate] = React.useState<Array<any>>();
  const [users, setUsers] = React.useState<any>([]);
  const [groups, setGroups] = React.useState<any>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<any>([]);
  const [options, setOptions] = React.useState<{ label: string; value: string, type: string }[]>([]);
  // const [optionGroups, setOptionGroup] = React.useState<any>([]);
  // const [optionReasonOT, setOptionReasonOT] = React.useState<any>([]);

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [timeSheetDateData, setTimeSheetDateData] = React.useState<any>([]);
  const [overTimeIdData, setOverTimeIdData] = React.useState<any>();
  const [overTimeGroupIdData, setOverTimeGroupIdData] = React.useState<any>();
  // const [timesheetListData, setTimeSheeListData] = React.useState<any>([]);
  const [date, setDate] = React.useState(new Date());
  const [dataListOT, setDataListOT] = React.useState<any>([]);
  const [calendarMonth, setCalendarMonth] = React.useState<string>(initialCalendarMonth);
  const [cvsStartDate, setCvsStartDate] = React.useState<string>(initialCsvStartDate.toISOString().split('T')[0]);
  const [cvsEndDate, setCvsEndDate] = React.useState<string>(initialCsvEndDate.toISOString().split('T')[0]);

  const statusOptions: { label: string; value: string }[] = [
    { label: "全て", value: "all" },
    { label: "未承認", value: "pending" },
    { label: "承認済み", value: "approved" },
    { label: "差し戻し", value: "rejected" }
  ];

  const overtimesLoading = useAppSelector(selectOvertimeLoading);
  const overtimeDataForMonth = useAppSelector(selectOvertimeByMonth(calendarMonth));
  const employeesData = useAppSelector(selectEmployeesData);
  const groupsData = useAppSelector(selectGroupsData);

  useEffect(() => {
    if (employeesData) {
      setUsers(employeesData);
    }
  }, [employeesData]);

  useEffect(() => {
    if (groupsData) { setGroups(groupsData); }
  }, [groupsData]);

  useEffect(() => {
    if (overtimeDataForMonth) {
      const selectedUserId = inputValue && parseInt(inputValue?.value) || null;
      const selectedGroupId = inputGroupValue && parseInt(inputGroupValue?.value) || null;
      const selectedOTStatus = inputStatusValue && inputStatusValue?.value || null;
      // console.log("status", selectedOTStatus?.value);

      let userFilteredOvertimeDataForMonth = [];

      if (selectedOTStatus && selectedOTStatus !== "all") {
        userFilteredOvertimeDataForMonth = filterOvertimesByStatus(overtimeDataForMonth, selectedOTStatus);

        if (selectedUserId) {
          userFilteredOvertimeDataForMonth = filterOvertimesByUser(userFilteredOvertimeDataForMonth, selectedUserId);
        }

      }

      if (!selectedOTStatus || selectedOTStatus === "all") {
        userFilteredOvertimeDataForMonth = filterOvertimesByUser(overtimeDataForMonth, selectedUserId);
      }

      // console.log("filtered data", userFilteredOvertimeDataForMonth);

      let groupFilteredOvertimeDataForMonth = [];

      if (!selectedGroupId) {
        groupFilteredOvertimeDataForMonth = filterOvertimesByGroup(userFilteredOvertimeDataForMonth, selectedStatus);
      } else {
        groupFilteredOvertimeDataForMonth = filterOvertimesByGroup(userFilteredOvertimeDataForMonth, selectedStatus, selectedGroupId);
      };

      fillOvertimesMultidays(groupFilteredOvertimeDataForMonth);
      setDataListOT(groupFilteredOvertimeDataForMonth);
    } else {
      dispatch(fetchOvertimes({ month: calendarMonth, groups: [...selectedStatus] }));
    }
  }, [calendarMonth, overtimeDataForMonth, inputValue?.value, inputGroupValue?.value, inputStatusValue?.value, selectedStatus, dispatch]);

  useEffect(() => {
    if (users && groups) {

      const userOption = [...users].filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id))).sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user) => ({
        label: `${user.last_name}${user.first_name}`,
        value: user?.id?.toString(),
        type: "user",
      }));

      const groupOption = groups.map((group) => ({
        label: group.name,
        value: group.id.toString(),
        type: "group",
      }));

      setOptions([...userOption, ...groupOption]);
      setInputValue(userOption[0]);
    }
  }, [users, groups]);


  // events
  const handleOnChange = (value) => {
    if (!value) {
      setInputValue(null);
      setInputGroupValue(null);
      // setInputStatusValue(null);
      setSelectedStatus([]);
      return;
    }

    // setInputStatusValue(null);

    if (value.type === "user") {

      setInputGroupValue(null);
      setInputValue(value);

    }

    if (value.type === "group") {
      setInputValue(null);

      if (value.value === 0) {
        setInputGroupValue(null);
        return;
      }
      setInputGroupValue(value);

    }

  };

  const handleOnChangeStatus = (value) => {
    if (!value) {
      setInputStatusValue(null);
      // setInputValue(null);
      // setInputGroupValue(null);
      // setSelectedStatus([]);
      return;
    }

    // setInputValue(null);
    // setInputGroupValue(null);
    // setSelectedStatus([]);

    setInputStatusValue(value);

  };


  // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setRadioValue((event.target as HTMLInputElement).value);
  //     setInputValue("");
  // };
  const handleViewDetail = (values: { otId; userId; status }) => {
    if ((id === values.userId && values.status === OT_STATUS_PENDING) || (id === values.userId && values.status === OT_STATUS_REJECT)) {
      history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values.otId}`);
    } else {
      history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otId}`);
    }
  };
  const handleCreateNewOT = () => {
    history.push(`${appRouteEnums.PATH_OVERTIME}/new`);
  };
  // const handleSelectGroup = (id) => {
  //   setInputValue("");
  //   if (id === 0) {
  //     setSelectedStatus([]);
  //     return;
  //   }
  //   setSelectedStatus(checkExistUserSelected(id, selectedStatus) ? selectedStatus.filter((e) => e !== id) : [...selectedStatus, id]);
  // };

  const handleOpenModal = (date: any, id: number) => {
    setOpenModal(true);
    setTimeSheetDateData({
      date: date.startDate[0],
    });
    setOverTimeIdData({
      id,
    });
    // setOverTimeGroupIdData({ groupId });
  };
  const handleOpenModalId = (date: any, id: number, groupId: number) => {
    setOpenModal(true);
    setTimeSheetDateData({
      date: date.startDate,
    });
    setOverTimeIdData({
      id,
    });
    setOverTimeGroupIdData({ groupId });
  };

  const handleCloseToggle = () => {
    setOpenModal(false);
    setTimeSheetDateData([]);
    setOverTimeIdData("");
    setOverTimeGroupIdData("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value + "-01"); // Ensure a valid Date format
    setDate(newDate);
  };

  const handleCalendarMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMonth = e.target.value;
    setCalendarMonth(selectedMonth);

    const [year, month] = selectedMonth.split('-').map(Number);

    const firstDay = new Date(Date.UTC(year, month - 1, 1));
    setCvsStartDate(firstDay.toISOString().split('T')[0]);

    const lastDay = new Date(Date.UTC(year, month, 0));
    setCvsEndDate(lastDay.toISOString().split('T')[0]);
  };

  const filterOvertimesByUser = (overtimes, userId) => {
    const isValidUserId = typeof userId === "number" && !isNaN(userId);
    return (isValidUserId
      ? overtimes.map(day => ({ ...day, users: day.users.filter(user => user.id === userId) }))
      : overtimes);
  };

  const filterOvertimesByGroup = (overtimes, selectedStatus, selectedGroups?) => {
    return overtimes.map((day) => {
      // Filter users for the current day
      const filteredUsers = day.users.filter((user) => {

        // Filter the user's overtimes based on Group_id
        const userHasMatchingOvertime = user.overtimes.some((overtime) => {

          // const isDateMatching = overtime.date === day.startDate;
          // If no groups are selected, all overtimes are valid.
          // Otherwise, only overtimes with a group_id in selectedGroup should be considered.

          const isStatusMatching =
            selectedStatus.length === 0 || selectedStatus.includes(overtime.title);


          const isGroupMatching =
            selectedGroups === null || selectedGroups === undefined || parseInt(selectedGroups) === overtime.group_id;

          return isStatusMatching && isGroupMatching;
        });

        // Include the user if they have at least one matching overtime
        return userHasMatchingOvertime;
      });

      // Return a new day object with filtered users. If no users match, this day will have an empty users array.
      return {
        ...day,
        users: filteredUsers,
      };
    });
  };

  const filterOvertimesByStatus = (overtimes, selectedOTStatus) => {
    const status = selectedOTStatus === "approved" ? 1 : selectedOTStatus === "rejected" ? 2 : selectedOTStatus === "pending" ? 0 : 3;
    return overtimes.map((day) => {
      const filteredUsers = day.users.filter((user) => {

        const userHasMatchingOvertime = user.overtimes.some((overtime) => {
          // const isDateMatching = overtime.date === day.startDate;

          let isStatusMatching = true;

          if (status !== 3) {
            isStatusMatching = status === overtime.status;
          }

          // console.log("selected status", selectedOTStatus);
          // console.log("filter status", overtime.status);
          // console.log("match status", isStatusMatching);

          return isStatusMatching;

        });

        return userHasMatchingOvertime;
      });

      return {
        ...day,
        users: filteredUsers,
      };
    });
  };

  function fillOvertimesMultidays(groupFilteredOvertimeDataForMonth) {
    // Helper function to find a day object by date within the groupFilteredOvertimeDataForMonth array
    function findDayByDate(date) {
      return groupFilteredOvertimeDataForMonth.find(day => day.startDate === date);
    }

    // Helper function to check if a user exists in the day's users array
    function findUserInDay(day, userId) {
      return day.users.find(user => user.id === userId);
    }

    // Iterate over each day in the month
    groupFilteredOvertimeDataForMonth.forEach(day => {
      // For each user on this day
      day.users.forEach(user => {
        // For each overtime span of the user
        user.overtimes.forEach(overtime => {
          const fromDate = new Date(overtime.from);
          const toDate = new Date(overtime.to);

          // Iterate through every date from the start (from) to the end (to) of the overtime period
          for (let date = new Date(fromDate); date <= toDate; date.setDate(date.getDate() + 1)) {
            // Format the current date to match the "startDate" in the day objects
            const currentDate = date.toISOString().split('T')[0];

            // Find the day object for the current date
            const targetDay = findDayByDate(currentDate);

            if (targetDay) {
              // Check if the user already exists in this day
              let targetUser = findUserInDay(targetDay, user.id);

              if (!targetUser) {
                // If user doesn't exist in the day, clone the user and add to that day's users array
                targetUser = { ...user, overtimes: [overtime] };
                targetDay.users.push(targetUser);
              }

              // Check if the overtime already exists in this user's "overtimes" array
              const overtimeExists = targetUser.overtimes.some(ot => ot.id === overtime.id);

              if (!overtimeExists) {
                // If overtime doesn't exist, add it to this user's "overtimes" array
                targetUser.overtimes.push(overtime);
              }
            }
          }
        });
      });
    });
  }

  return (
    <StyledOverTimeView>
      {/* <AppTopBar /> */}
      <OverTimeToggleModal openToogle={openModal} anchor="right" handleCloseToggle={handleCloseToggle} timeSheetDateData={timeSheetDateData}
        // id={overTimeIdData}
        id={inputValue?.value ?? null}
        // groupId={overTimeGroupIdData}
        groupId={inputGroupValue?.value ?? null}
        status={inputStatusValue?.value ?? null}
        overtimeDataForMonth={overtimeDataForMonth} />
      <div className="overtime">
        {/* <p className="newOT__top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
          <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
        </p> */}
        <div className="overtime__title">
          <div className="overtime__title-left">
            <TitleMenuComponent text={menuTitleEnums.TITLE_OVERTIME} />
          </div>
          {/* <WrapLink onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>週間予定</WrapLink> */}
          <PassChangeButton onClick={() => handleCreateNewOT()}>
            <span style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.5rem", paddingRight: "0.5rem" }}>＋ </span>
              新規作成
            </span>
          </PassChangeButton>
        </div>

        <div className="overtime__selectMonth">
          <div style={{ marginTop: "1rem" }}>
            <input type="month" className="inputMonth" value={calendarMonth} onChange={handleCalendarMonthChange} />
          </div>

          {/* <div className="overtime__title-btn"> */}
          <PassChangeButton onClick={() => history.push(appRouteEnums.PATH_WEEKLY_SCHEDULE)}>週間予定</PassChangeButton>

          {/* </div> */}
        </div>


        <div className="overtime__listItem">
          <div className="overtime__form">
            {/* <div className="wrapper__filter">
              <div className="overtime__filter"> */}
            {/* <div onClick={() => handleSelectGroup(defaultGroup.id)} className={clsx(!selectedGroup.length ? "active" : "", "overtime__filter-item")}>
                  <span className="title">{defaultGroup.name}</span>
                  <Box sx={{ display: "flex", alignItems: "center" }}>{!selectedGroup.length && <img src={tick_icon} alt="icon" />}</Box>
                </div> */}
            {/* <StyledGrayToggleButton isActive={!selectedStatus.length}
                  onClick={() => handleSelectGroup(defaultGroup.id)}>
                  {defaultGroup.name}
                </StyledGrayToggleButton>
                {filterOverTimeTitle &&
                  filterOverTimeTitle.map((item) => (

                    <StyledGrayToggleButton
                      key={item.id}
                      isActive={checkExistUserSelected(item.name, selectedStatus)}
                      onClick={() => handleSelectGroup(item.name)}
                    >
                      {item.name}
                    </StyledGrayToggleButton>

                  ))} */}
            {/* </div>
            </div> */}
            <div className="overtime__search">
              <div className="label">班・従業員</div>
              <div className="wrapperSelect">
                <AppCustomeSelect
                  placeholder="人・班を選択"
                  onChange={handleOnChange}
                  groupUserOptions={options}
                  options={options}
                  isClearable
                  value={inputValue?.type === "user" ? inputValue : inputGroupValue}
                />
              </div>
            </div>
            <div className="overtime__search">
              <div className="label">ステータス</div>
              <div className="wrapperSelect">
                <AppCustomeSelect
                  placeholder="ステータスを選択"
                  onChange={handleOnChangeStatus}
                  groupUserOptions={statusOptions}
                  options={statusOptions}
                  isClearable
                  value={inputStatusValue}
                />
              </div>
            </div>
          </div>
          {(overtimesLoading) ? (
            <div className="wrapperLoading">
              <CircularLoader loading={overtimesLoading} type="fullContent" />
            </div>
          ) : (
            <StyledWrapCalendarComponent>

              <div className="ot__calendar">
                <OverTimeCalendar handleOpenModal={handleOpenModal} handleOpenModalId={handleOpenModalId} handleCloseToggle={handleCloseToggle} dataListOT={dataListOT} month={calendarMonth} handleViewDetail={handleViewDetail} />
              </div>
              <div className="csvExportDiv">
                <span className="csvExportTitle">CSVエクスポート</span>
                <div className="ot__datePicker">

                  <div className="dateContainer">

                    <div className="label">作成日時</div>

                    <div className="dateSubContainer">
                      <div className="wrapperDatePicker" style={{ display: "none" }}>
                        <input
                          type="month"
                          value={date.toISOString().slice(0, 7)} // Format as YYYY-MM
                          onChange={handleChange}
                        />
                      </div>
                      <input type="date" className="inputDate" value={cvsStartDate} onChange={(e) => setCvsStartDate(e.target.value)} /><span>から</span>
                    </div>

                    <div className="dateSubContainer">
                      <input type="date" className="inputDate" value={cvsEndDate} onChange={(e) => setCvsEndDate(e.target.value)} /><span>まで</span>
                    </div>

                  </div>
                  <div>
                    <AppExportCsv type={"overtimes"} startDate={cvsStartDate} endDate={cvsEndDate} />
                  </div>
                </div>
              </div>
            </StyledWrapCalendarComponent>
          )}
        </div>
      </div>
    </StyledOverTimeView>
  );
};

// const WrapLink = styled.div`
//     font-weight: 700;
//     margin-top: 27px;

//     border: 2px solid orange;
//     color: orange;
//     border-radius: 10px;
//     display: flex;
//     align-items: center;
//     width: fit-content;
//     padding: 8px 10px;
//     :hover {
//         cursor: pointer;
//         color: #d88d02;
//         border: 2px solid #d88d02;
//     }
//     @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
//         margin-left: 40px;
//     }
// `;
