import * as React from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import { Box, InputAdornment, TextField } from "@mui/material";
import { ErrorMessage } from "formik";
import { Error } from "..";
import { StyledAppDateRangePicker } from ".";
import date_picker_icon from "@assets/Icons/date_picker_icon.svg";
import moment from "moment";
import { ja } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';



interface IDateRange {
    size?: string;
    icon?: any;
    handleChange(date: any): void;
    value: any;
    placeholder?: string;
    name?: string;
    noError?: boolean;
    titleStart?: string;
    titleEnd?: string;
}
const defaultDateValue = {
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").utc().toDate(),
};
export const AppRangeDatePicker = (props: IDateRange) => {
    const { value=[defaultDateValue.startDate,defaultDateValue.endDate], name, noError, handleChange, placeholder, titleStart, titleEnd } = props;
    return (
        <StyledAppDateRangePicker>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja} >
                <DateRangePicker
                    disableCloseOnSelect={false}
                    calendars={2}
                    value={value}
                    onChange={handleChange}
                    inputFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <div>
                                <label>{titleStart}</label>
                                <TextField
                                    {...startProps}
                                    variant="outlined"
                                    InputProps={{
                                        // disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img src={date_picker_icon} alt="icon" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label=""
                                    placeholder={placeholder}
                                />
                            </div>
                            <Box sx={{ mx: 2 }}> </Box>
                            <div>
                                <label>{titleEnd}</label>
                                <TextField
                                    {...endProps}
                                    variant="outlined"
                                    InputProps={{
                                        // disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img src={date_picker_icon} alt="icon" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label=""
                                    placeholder={placeholder}
                                />
                            </div>
                        </React.Fragment>
                    )}
                />
            </LocalizationProvider>
            {noError || (name && <ErrorMessage name={name} component={Error} />)}
        </StyledAppDateRangePicker>
    );
};
