export const COLOR_ICON_LIGHT_BLUE = "#43CDEC";
export const COLOR_ICON_GRAY = "#D5D5D5";

// Display MB by using byte
export const LIMTED_10MB = 10000000;

export const ERROR_SIZE_MORE_THAN_10MB = "画像サイズは10MB以下";
export const ERROR_MESSAGE_REQUIRE_FIELD = "この項目は必須です";
// popup
export const TITLE_RETURN_SCHEDULE = "入庫しますか？";
export const TITLE_BORROW_SCHEDULE = "出庫しますか?";

// maximin password characters
export const MAXIMIN_PASSWORD_CHARACTERS = 32;
export const MAXIMIN_1000_CHARACTERS = 1000;
export const MAXIMIN_255_CHARACTERS = 255;

// Greetting
export const GREETING_HELLO = "マイページへ";

// not found
export const PAGE_NOT_FOUND = "ページが見つかりませんでした";
export const PAGE_NOT_FOUND_404 = "404";

// amount of characters be shown in text
export const NUMBER_TEXT_DESCRIPTION = 29;
export const NUMBER_TEXT_USERNAME = 4;
export const NUMBER_TEXT_MENU = 20;

// maximum width content of body
export const MAX_WIDTH_BODY = 1440;

export const MAX_WIDTH_CONTENT = "1440px";

// api category params
export const API_CATEGORY_TYPE_INVENTORY = 1;
export const API_CATEGORY_TYPE_INSTRUMENT = 2;
export const API_CATEGORY_TYPE_VEHICLE = 3;
export const API_CATEGORY_TYPE_DOCUMENT = 4;
// toggle width
export const WIDTH_DRAWER_MOBILE = 310;
export const WIDTH_DRAWER_PC = 450;

// unable to view document
export const DOCUMENT_FAILED_TO_LOAD = "ドキュメントを表示できません";
export const DOCUMENT_EXCEL_FAILED_TO_LOAD = "Excelファイルのプレビューができません";

// OT status
export const OT_STATUS_REJECT = 2;
export const OT_STATUS_OK = 1;
export const OT_STATUS_PENDING = 0;

// error message data not found
export const MESSAGE_DATA_NOT_FOUND = "データが見つかりません";

// time message
export const ENDTIME_MUST_BE_GREATER = "終了時間は開始時間の後にする必要があります。";
export const ERROR_MESSAGE_START_TIME = "午前8時から午後5時までお選びください";
export const ERROR_MESSAGE_END_TIME = "午後5時からお選びください";
// day shify and night shift
export const TEXT_NIGHT_SHIFT = "夜勤";
export const TEXT_DAY_SHIFT = "夜勤";

// label timesheet
export const LABEL_TIME_SHEET_TYPE = "日報種別";
export const LABEL_SELECT_GROUP_INPUT = "グループを選択";
export const PLACEHOLDER_SELECT_GROUP_INPUT = "自分のグループを選択してください";
export const LABEL_START_TIME = "開始時間";
export const LABEL_STOP_TIME = "終了時間";
export const LABEL_INSPECTOR = "チェック者";
export const PLACEHOLDER_INSPECTOR = "チェック者選択します";
export const LABEL_DEVICE_CHECK = "アルコールチェック製品名";
// pdf
export const MESSAGE_LOADING_PDF = "太字pdf...";

//placeholder device check for alcohol
export const PLACEHOLDER_DEVICE_CHECK_ALCOLHO = "TANITAアルコールチェッカーEA-100";
export const PLACEHOLDER_SEARCH = "検索";
// label constractors
export const PLACEHOLDER_CONSTRACTOR = "調べる（請負業者)";
export const LABEL_CONTRACTOR_AMOUNT = "合計人数";
export const PLACEHOLDER_CONSTRUCTIONSITE = "Select 現場名";
export const LABEL_CONSTRUCTIONSITE = "現場名";
// button
export const BUTTON_SAVE = "保存";
export const VIEW_PDF = "休日カレンダー";

// contractor
export const LABEL_BOOK_MARKED = "ブックマークした";
export const LABEL_ALL_CONTRACTORS = "すべての請負業者";
export const LABEL_CONTRACTORS = "作業会社";
export const LABEL_LIVELET_EMPLOYEE = "LiveLet社員";

// note for reason overtime
export const OVERTIME_NOTE = "忌引き休暇の場合は続柄と（同居・別居）を入力してください。";
export const DEFAULT_OVERTIME_INPUT = "詳細を記入してください。";

//list document
export const LABEL_LIST_DOCUMENT = "個人の通知";


export const SHOW_CONSTRUCTION_SITE_DATA_IN_TIMESHEET_DETAILS = true;