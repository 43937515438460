import React from "react";
import Select from "@mui/material/Select";
import { Avatar, Box, MenuItem, Stack } from "@mui/material";

import { ArrowDropDownIcon } from "src/Assets/Icons/ArrowDropDown";
import { StyledAppSelect } from "../Common";

interface IProps {
    handleChange: (value: any, action?: any) => void;
    name?: string;
    options?: any;
    error?: string;
    disabled?: boolean;
    styleSelect?: any;
    multiple?: boolean;
    label?: string;
    placeholder?: string;
    value?: any;
    noError?: boolean;
    isClearable?: boolean;
    sx?: any;
}

export const AppSelect = React.memo((props: IProps) => {
    const { handleChange, name, value, error, options = [], placeholder, disabled, multiple, label, ...rest } = props;

    return (
        <StyledAppSelect>
            <Select
                style={{ padding: "8px" }}
                IconComponent={ArrowDropDownIcon}
                value={value}
                displayEmpty
                label={label}
                onChange={handleChange}
                defaultValue={value}
                multiple={multiple}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                name={name}
                disabled={disabled}
                fullWidth
                {...rest}
            >
                {options &&
                    options.length &&
                    options.map((item: any, index: number) => (
                        <MenuItem key={index} value={item?.value} className="test" sx={{ padding: "12px 0 !important" }}>
                            <Stack
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                flexDirection={"row"}
                                gap={0.5}
                                sx={{
                                    width: "100%",
                                    paddingLeft: "1rem",
                                    position: "relative",
                                    height: "100%",
                                }}
                            >
                                {item.avatar && (
                                    <Box
                                        sx={{
                                            paddingRight: "2rem",
                                            flexShrink: 0,
                                            position: "absolute",
                                            left: 12,
                                        }}
                                    >
                                        <Avatar src={item.avatar} />
                                    </Box>
                                )}
                                {item.color && <Box sx={{ backgroundColor: `${item.color}`, height: 10, maxHeight: 10, minHeight: 10, width: 10, minWidth: 10 }}></Box>}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        maxWidth: "95%",
                                        left: item.avatar ? "6rem" : 12,
                                        right: 0,
                                        overflow: "hidden",
                                        fontSize: 12,
                                    }}
                                >
                                    {item.label}
                                </Box>
                            </Stack>
                        </MenuItem>
                    ))}
            </Select>
            {error && <p style={{ color: "red", fontSize: "13px", margin: "8px" }}>{error}</p>}
        </StyledAppSelect>
    );
});
