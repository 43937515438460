import React from "react";
import { Grid } from "@mui/material";

import { StyledListItemInventory, InventoryItemComponent } from ".";
import { NotFoundItemView, CircularLoader } from "..";
import { useAppSelector, selectInventory } from "@redux";
import { useLocation } from "react-router-dom";

interface IProps {
  products?: any;
  loading?: boolean;
}
export const InventoryListItemComponent = (props: IProps) => {
  const { products, loading } = props;
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const categoryId = query.get("categoryId");
  const subCategoryId = query.get("subCategoryId");
  const dataCategory =
    categoryId && subCategoryId
      ? products?.filter((item) => Number(item.subCategoryId) === Number(subCategoryId))
      : products;
  // redux store
  const inventory = useAppSelector(selectInventory);
  const { dataResult } = inventory;
  return (
    <StyledListItemInventory>
      <div className="inventoryItems__title">
        {dataResult?.category && (
          <span>
            {dataResult?.category} ・ {dataResult?.subCategory}{" "}
          </span>
        )}
      </div>
      {loading ? (
        <div className="wrapperLoading">
          <CircularLoader loading={loading} type="fullContent" />
        </div>
      ) : (
        <>
          {products?.length ? (
            <div className="inventoryItems__content">
              <Grid container spacing={2}>
                {dataCategory?.map((item) => (
                  <Grid key={item.id} item xs={12} sm={12} md={12}>
                    <InventoryItemComponent item={item} />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <div className="error">
              <NotFoundItemView />
            </div>
          )}
        </>
      )}
    </StyledListItemInventory>
  );
};
