// import React, { useRef, useState } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { Avatar, Box, MenuItem } from "@mui/material";

// import { StyledAppSelect } from ".";
// import { Placeholder } from "./styles";
// // import { ErrorMessage } from "formik";
// import { ArrowDropDownIcon } from "src/Assets/Icons/ArrowDropDown";

// interface IProps {
//     handleChange: (value: any) => void;
//     name?: string;
//     options?: any;
//     error?: string;
//     disabled?: boolean;
//     styleSelect?: any;
//     multiple?: boolean;
//     label?: string;
//     placeholder?: string;
//     value?: any;
//     noError?: boolean;
// }
// export const AppSelect = (props: IProps) => {
//     const {
//         handleChange,
//         name,
//         value,
//         error,
//         options = [],
//         placeholder,
//         disabled,
//         multiple,
//         label,
//     } = props;
//     const [values, setValue] = useState(value);
//     const action = useRef<any>(null);

//     const handleOnChange = (event: SelectChangeEvent) => {
//         handleChange(event.target.value as string);
//     };
//     const RendePlaceholder = ({ children }: any) => {
//         return <Placeholder>{children}</Placeholder>;
//     };
//     return (
//         <StyledAppSelect>
//             <Select
//                 IconComponent={ArrowDropDownIcon}
//                 value={values}
//                 displayEmpty
//                 label={label}
//                 onChange={handleOnChange}
//                 placeholder={placeholder}
//                 defaultValue={values}
//                 multiple={multiple}
//                 onClick={(e) => {
//                     e.stopPropagation();
//                 }}
//                 name={name}
//                 disabled={disabled}
//                 fullWidth
//                 renderValue={
//                     values === ""
//                         ? () => <RendePlaceholder>{placeholder}</RendePlaceholder>
//                         : undefined
//                 }
//                 {...(values && {
//                     // When the user has selected a value, the button is displayed, and the select indicator is removed.
//                     endDecorator: (
//                         <div
//                             // size="sm"
//                             // variant="plain"
//                             color="neutral"
//                             onMouseDown={(event) => {
//                                 // stops the popup from appearing when this button is clicked
//                                 event.stopPropagation();
//                             }}
//                             onClick={() => {
//                                 setValue(null);
//                                 action.current?.focusVisible();
//                             }}
//                             style={{ zIndex: 1000000000 }}
//                         >
//                             x
//                         </div>
//                     ),
//                     indicator: null,
//                 })}
//             >
//                 {options &&
//                     options.length &&
//                     options.map((item: any, index: number) => (
//                         <MenuItem key={index} value={item?.value} className="test">
//                             <Box
//                                 sx={{
//                                     // display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "flex-start",
//                                     padding: "1.5rem 0",
//                                     width: "100%",
//                                     paddingLeft: "1rem",
//                                     position: "relative",
//                                     height: "40px",
//                                     marginBottom: "5px",
//                                     marginTop: "5px",
//                                 }}
//                             >
//                                 {item.avatar && (
//                                     <Box
//                                         sx={{
//                                             paddingRight: "2rem",
//                                             flexShrink: 0,
//                                             position: "absolute",
//                                             left: 12, // Đặt giá trị left = 0 để đặt ảnh ở bên trái
//                                         }}
//                                     >
//                                         <Avatar src={item.avatar} />
//                                     </Box>
//                                 )}
//                                 {item.color && (
//                                     <span
//                                         className="colorIcon"
//                                         style={{ backgroundColor: `${item.color}` }}
//                                     ></span>
//                                 )}
//                                 <Box
//                                     sx={{
//                                         display: "flex",
//                                         alignItems: "center",
//                                         maxWidth: "95%",
//                                         height: "40px !important",
//                                         position: "absolute",
//                                         left: item.avatar ? "6rem" : 12, // Nếu có Avatar thì đặt giá trị left để tránh bị chồng chéo
//                                         right: 0, // Đặt giá trị right = 0 để đặt nội dung bên phải
//                                         overflow: "hidden",
//                                     }}
//                                 >
//                                     {item.label}
//                                 </Box>
//                             </Box>
//                         </MenuItem>
//                     ))}
//             </Select>
//             {/* {noError || (name && <ErrorMessage name={name} component={Error} />)} */}
//             {error && <p style={{ color: "red", fontSize: "13px", margin: "8px" }}>{error}</p>}
//         </StyledAppSelect>
//     );
// };

import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Avatar, Box, MenuItem } from "@mui/material";

import { StyledAppSelect } from ".";
import { Placeholder } from "./styles";
import { ArrowDropDownIcon } from "src/Assets/Icons/ArrowDropDown";
import { IoIosClose } from "react-icons/io";

interface IProps {
  handleChange: (value: any, action?: any) => void;
  name?: string;
  options?: any;
  error?: string;
  disabled?: boolean;
  styleSelect?: any;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  value?: any;
  noError?: boolean;
  isClearable?: boolean;
}

export const AppSelect = React.memo((props: IProps) => {
  const {
    handleChange,
    name,
    value,
    error,
    options = [],
    placeholder,
    disabled,
    multiple,
    label,
    isClearable,
  } = props;

  const handleOnChange = (event: SelectChangeEvent) => {
    handleChange(event.target.value);
  };

  const handleClearValue = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.handleChange(null, "clear");
  };

  const RendePlaceholder = ({ children }: any) => {
    return <Placeholder>{children}</Placeholder>;
  };

  return (
    <StyledAppSelect>
      <Select
        IconComponent={ArrowDropDownIcon}
        value={value}
        displayEmpty
        label={label}
        onChange={handleOnChange}
        defaultValue={value}
        multiple={multiple}
        onClick={(e) => {
          e.stopPropagation();
        }}
        name={name}
        disabled={disabled}
        fullWidth
        renderValue={
          value === ""
            ? () => <RendePlaceholder>{placeholder}</RendePlaceholder>
            : undefined
        }
        endAdornment={
          !!value &&
          isClearable && (
            <Box
              sx={{
                position: "absolute",
                right: "36px",
                top: "54%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={handleClearValue}
            >
              <IoIosClose size={24} />
            </Box>
          )
        }
      >
        {options &&
          options.length &&
          options.map((item: any, index: number) => (
            <MenuItem key={index} value={item?.value} className="test">
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // padding: "1.5rem 0",
                  width: "100%",
                  paddingLeft: "1rem",
                  position: "relative",
                  height: "40px",
                  // marginBottom: "5px",
                  // marginTop: "5px",
                }}
              >
                {item.avatar && (
                  <Box
                    sx={{
                      paddingRight: "2rem",
                      flexShrink: 0,
                      position: "absolute",
                      left: 12,
                    }}
                  >
                    <Avatar src={item.avatar} />
                  </Box>
                )}
                {item.color && (
                  <span
                    className="colorIcon"
                    style={{ backgroundColor: `${item.color}` }}
                  ></span>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "95%",
                    height: "40px !important",
                    position: "absolute",
                    left: item.avatar ? "6rem" : 12,
                    right: 0,
                    overflow: "hidden",
                  }}
                >
                  {item.label}
                </Box>
              </Box>
            </MenuItem>
          ))}
      </Select>
      {error && <p style={{ color: "red", fontSize: "13px", margin: "8px" }}>{error}</p>}
    </StyledAppSelect>
  );
});
