import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";

import { timesheetApi, workScheduleApi } from "@api";
import { PLACEHOLDER_CONSTRACTOR } from "@configs";
import { checkExistUserSelected, linkImage } from "@utils";
import { AppCustomeSelect, CircularLoader } from "..";
import { selectAuth, useAppSelector } from "@redux";
import { StyledGrayToggleButton } from "../WeeklySchedule/styles";
import { TableWrapView } from "./TableWrapView";
import "../../App.css";

const defaultGroup = {
  id: 0,
  name: "全て",
};

// interface IProps {
//     handleCollapse: () => void;
// }
export const WorkScheduleWrap = () => {
  const auth = useAppSelector(selectAuth);
  const id = auth?.userInfo?.id;

  // const { handleCollapse } = props;
  // const [toggle, setToggle] = React.useState<number>(0);
  const [date, setDate] = React.useState(new Date());
  const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [dataWorkSchedule, setDataWorkSchedule] = React.useState<any>([]);
  const [optionEmployee, setOptionEmployee] = React.useState<any[]>([]);
  const [groups, setGroups] = React.useState<any>([]);
  const [selectSubContractor, setSelectSubContractor] = React.useState<any>("");
  const [users, setUsers] = React.useState<any>([]);
  const [employeeInput, setEmployeeInput] = React.useState<any>("");

  React.useEffect(() => {
    fetchListWorkSchedules();
    return () => {
      // do something with cleanup
    };
  }, [date, selectedGroup, selectSubContractor, employeeInput]);

  React.useEffect(() => {
    fetchListGroups();
    fetchListUsers();
    return () => {
      // do something with cleanup
    };
  }, []);

  const fetchListGroups = async () => {
    try {
      const responseGroups = await timesheetApi.listGroupsTimesheet();
      setGroups(responseGroups.data);
    } catch (error) {
    } finally {
      //
    }
  };

  const fetchListUsers = async () => {
    try {
      const responseListUsers = await timesheetApi.getListContractors();
      setUsers(responseListUsers?.data?.data?.contractors);
    } catch (error) {
    } finally {
      //
    }
  };
  const fetchListWorkSchedules = async () => {
    setLoading(true);

    try {
      setSelectedGroup(selectedGroup ? selectedGroup : 0);

      const response = await workScheduleApi.getOverViewWorkSchedule(
        {
          month: date && moment(date).format("YYYY-MM"),
          userId: selectSubContractor?.value,
          employeeId: employeeInput?.value,
        },
        selectedGroup
      );

      if (response?.data?.success) {
        setDataWorkSchedule(response?.data?.data);
        const responseEmployees = (!response?.data?.data || !Array.isArray(response.data.data)) ? [] : response?.data.data.map((item) => ({ id: item?.employee?.id, first_name: item?.employee?.first_name, last_name: item?.employee?.last_name, avatar: item?.avatar }));
        const filteredSortedEmployees = responseEmployees
          //.filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
          .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0));
        setOptionEmployee(filteredSortedEmployees);
        //setOptionEmployee(response?.data.data.map((item) => ({ id: item?.employee?.id, first_name: item?.employee?.first_name, last_name: item?.employee?.last_name, avatar: item?.avatar })));
        setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSelectGroup = (id) => {
    setSelectSubContractor("");
    if (id === 0) {
      setSelectedGroup([]);
      return;
    }
    setSelectedGroup(checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id]);
  };

  const handleChangeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    setDate(newDate);
    setSelectedGroup([]);
    setSelectSubContractor("");
  };

  const handleOnChange = (e) => {
    setEmployeeInput(e);
  };

  const handleOnChangeSubContracter = (id) => {
    setSelectSubContractor(id);
  };
  return (
    <div>
      <CircularLoader loading={loading} />

      <div className="workSchedule__form mb-5">
        <div className="container">
          <div className="workSchedule__datePicker">
          <input
            type="date"
            value={`${date.toISOString().slice(0, 7)}-01`} // Format as YYYY-MM-01
            onChange={handleChangeMonth}
            className="inputMonth"
          />
          </div>

          <div className="subContainer">
            <div className="workSchedule__select">
              <div className="wrapperSelect">
                {!!users?.length && (
                  <AppCustomeSelect
                    placeholder={PLACEHOLDER_CONSTRACTOR}
                    onChange={handleOnChangeSubContracter}
                    groupUserOptions={
                      users?.length &&
                      users.map((user) => {
                        return {
                          label: `${user.name}`,
                          value: user?.id?.toString(),
                          avatar: linkImage(user?.avatar?.path),
                        };
                      })
                    }
                    options={
                      users?.length &&
                      users.map((user) => {
                        return {
                          label: `${user.name}`,
                          value: user?.id?.toString(),
                          avatar: linkImage(user?.avatar?.path),
                        };
                      })
                    }
                    isClearable
                    value={selectSubContractor}
                  />
                )}
              </div>
            </div>
            <div className="workSchedule__emp">
              <label className="label">従業員</label>
              <div className="workSchedule__empSelect">
                <AppCustomeSelect
                  placeholder="人を選択する"
                  onChange={handleOnChange}
                  groupUserOptions={
                    optionEmployee?.length &&
                    [...optionEmployee]
                      .filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
                      .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0))
                      .map((user: any) => {
                        return {
                          label: `${user.last_name}${user.first_name}`,
                          value: user?.id?.toString(),
                          avatar: linkImage(user?.avatar?.path),
                        };
                      })
                  }
                  options={
                    optionEmployee?.length &&
                    [...optionEmployee]
                      .filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
                      .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0))
                      .map((user: any) => {
                        return {
                          label: `${user.last_name} ${user.first_name}`,
                          value: user?.id?.toString(),
                          avatar: linkImage(user?.avatar?.path),
                        };
                      })
                  }
                  isClearable
                  value={employeeInput}
                  isShowAvatar
                />
              </div>
            </div>

          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={9} xl={8}>
            <div className="workSchedule__filter">
              <div className="workSchedule__button">
                <StyledGrayToggleButton isActive={!selectedGroup.length}
                  onClick={() => handleSelectGroup(defaultGroup.id)}
                >
                  全て</StyledGrayToggleButton>
              </div>

              {groups &&
                groups.map((item) => (
                  <div className="workSchedule__button">
                    <StyledGrayToggleButton key={item.id}
                      isActive={checkExistUserSelected(item.id, selectedGroup)}
                      onClick={() => {
                        handleSelectGroup(item.id);
                      }}
                    >
                      {item.name}</StyledGrayToggleButton>
                  </div>

                ))}
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="workSchedule__content">
        {/* <div className="visible__desktop" style={{ marginTop: "40px" }}>
          <div className="wrapperDesktopContent">
            <TableWrapViewWorkSchedule hasCollapse={true} data={dataWorkSchedule} />

            <div className="wrapperDesktopContent__graphView">
              <ViewWrapGraphCalendar
                date={date}
                data={dataWorkSchedule}
                tableStyle={{
                  tableLayout: "auto",
                  width: "auto",
                }}
              />
            </div>
          </div>
        </div> */}
        <TableWrapView data={dataWorkSchedule} date={date} />
      </div>
    </div>
  );
};
