import { ISnackbar, IModal } from "@interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState {
    loading: boolean;
    snackbar: ISnackbar;
    menu?: any;
    modal: IModal;
}

const initialState: IState = {
    loading: true,
    snackbar: {
        show: false,
        message: "",
        messageType: undefined,
    },
    menu: {
        show: false,
    },
    modal: {
        message: "",
        messageType: undefined,
        show: false,
    },
};

const appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        resetSnackBar: (state) => {
            state.snackbar = initialState.snackbar;
        },
        updateSnackBar: (state, action: PayloadAction<ISnackbar>) => {
            state.snackbar = {
                ...state.snackbar,
                ...action.payload,
                show: true,
            };
        },
        updateModal: (state, action: PayloadAction<IModal>) => {
            state.modal = {
                ...state.modal,
                ...action.payload,
                show: true,
            };
        },
        resetModal: (state) => {
            state.modal = initialState.modal;
        },
    },
});

export const selectApp = (state: RootState) => state.app;
export const { setLoading, updateModal, resetModal, resetSnackBar, updateSnackBar } =
    appSlice.actions;
export default appSlice.reducer;
