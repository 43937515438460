import styled from "styled-components";

export const StyledErrorContentView = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .error {
        &__content {
            padding: 0 4rem;
            &-404 {
                font-size: 8rem;
                color: ${(p) => p.theme.colors.error};
            }
            &-text {
                font-size: 2.6rem;
                padding: 4rem 0;
            }
        }
        &__wrappButton {
            display: flex;
            justify-content: center;
            .btn {
                width: 80%;
            }
        }
    }
`;

export const StyledNotFoundItem = styled.div`
    .wrapContent {
        height: 100%;
        .message {
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            font-size: 1.6rem;
            color: #d4d4d4;
        }
    }
`;
