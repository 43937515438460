import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { AppCustomeSelect, CircularLoader } from "../Common";
import { StyledWorkScheduleListConstructionSite } from "./styles";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { workScheduleApi } from "@api";
import { useDispatch } from "react-redux";
import { updateSnackBar } from "@redux";
import { useConstructionSites } from "@utils";
import { noop } from "lodash";

interface IProps {
  options?: any;
}

const WorkScheduleListConstructionSite = (props: IProps) => {
  const { options } = props;
  const dispatch = useDispatch();
  const { sortConstructionSites } = useConstructionSites();
  const [data, setData] = React.useState<any>("");
  const [selectValue, setSelectValue] = React.useState<any>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  // const [date, setDate] = React.useState(new Date());
  interface Column {
    id: "no" | "construction" | "lead_engineer" | "responsible_person" | "address" | "prime_constructor" | "start_date" | "end_date";
    label: string;
    minWidth?: number;
    align?: "center";
    fontSize: 14;
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "no", label: "NO.", minWidth: 60, fontSize: 14 },
    { id: "construction", label: "工事現場名", minWidth: 100, fontSize: 14 },
    {
      id: "lead_engineer",
      label: "主任技術者",
      minWidth: 170,
      align: "center",
      fontSize: 14,
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "responsible_person",
      label: "担当",
      minWidth: 170,
      align: "center",
      fontSize: 14,
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "address",
      label: "住所",
      minWidth: 170,
      align: "center",
      fontSize: 14,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "prime_constructor",
      label: "元請け",
      minWidth: 170,
      align: "center",
      fontSize: 14,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "start_date",
      label: "開始日",
      minWidth: 170,
      align: "center",
      fontSize: 14,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "end_date",
      label: "終了日",
      minWidth: 170,
      align: "center",
      fontSize: 14,
      format: (value: number) => value.toFixed(2),
    },
  ];
  const handleOnChangeSelect = (id) => {
    setSelectValue(id);
  };

  React.useEffect(() => {
    fetchListConstructionSite();
    return () => {
      // do something with cleanup
    };
  }, [selectValue]);

  const fetchListConstructionSite = async () => {
    setLoading(true);
    try {
      // const res = await workScheduleApi.getOverViewWorkSchedule(
      //     {
      //         month: moment(new Date()).format("YYYY-MM"),
      //         userId: selectValue?.value ? selectValue.value : "2",
      //     },
      //     []
      // );
      const res = await workScheduleApi.getListConstructionSite(
        selectValue?.value ? selectValue.value : ""
      );
      setData(sortConstructionSites(res?.data?.data));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if (err.response?.status === 404) {
        setData(null);
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: err.response?.data?.message,
          })
        );
      } else {
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: err.response?.data?.message,
          })
        );
      }
    }
  };

  function createData(no: string, construction?: string, lead_engineer?: string, responsible_person?: string, address?: string, prime_constructor?: string, start_date?: string, end_date?: string) {
    return {
      no,
      construction,
      lead_engineer,
      responsible_person,
      address,
      prime_constructor,
      start_date,
      end_date,
    };
  }
  const rows = useMemo(() => {
    if (!data) {
      return [];
    } else {
      return data?.map((items, index) =>
        createData(
          `${data?.length - index}`,
          `${items?.name ? items?.name : "--"}`,
          `${items?.lead_engineer ? items?.lead_engineer : "--"}`,
          `${items?.in_charge?.first_name && items?.in_charge?.last_name ? items?.in_charge?.last_name + " " + items?.in_charge?.first_name : "--"}`,
          `${items?.address ? items?.address : "--"}`,
          `${items?.note ? items?.note : `--`}`,
          `${items?.start_date ? items?.start_date : "--"}`,
          `${items?.end_date ? items?.end_date : "--"}`
        )
      );
    }
  }, [data]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledWorkScheduleListConstructionSite>
      <div className="">
        {/* <TitleMenuComponent text={menuTitleEnums.TITLE_WORK_SCHEDULE_LIST_CONSTRUCTION} /> */}
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="input">
                <AppCustomeSelect
                  placeholder={"検索"}
                  onChange={handleOnChangeSelect}
                  className="search-list"
                  options={
                    options?.constructions?.length &&
                    options?.constructions.map((item) => {
                      return {
                        label: item.name,
                        value: item.id.toString(),
                      };
                    })
                  }
                  isClearable
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="rowsPerPage">
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="1ページあたりの表示数"
                page={page}
                onPageChange={noop}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => ""}
                ActionsComponent={() => null}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <CircularLoader loading={loading} />
            <Paper className="paperStyle" sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440, overflow: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontSize: column.fontSize,
                          }}
                          className="header"
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows?.length > 0 &&
                      rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.construction}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align} style={{ fontSize: "12px" }}>
                                  {column.format && typeof value === "number" ? column.format(value) : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={noop}
              style={{ fontSize: "15px" }}
            />
          </Grid>
        </Grid>
      </div>
    </StyledWorkScheduleListConstructionSite>
  );
};

export default WorkScheduleListConstructionSite;
