import styled from "styled-components";

export const StyledAlcohol = styled.div`
    text-align: left;
    background-color:${(p) => p.theme.colors.white02};
    height: 100vh;
    // padding-bottom: 4rem;
    .al-detail {
        padding: 1rem 1rem;

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
        }
            margin-top:7rem;
            padding-bottom:12rem;
        &__title {
            padding-bottom: 2rem;
            border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
        }
        &__datePicker {
            padding:1rem 0;
            display: flex;
            .label {
                display: flex;
                align-items: center;
                padding-right: 2rem;
            }
            .wrapperDatePicker {
                width: 18rem;
            }
        }
        &__form {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                justify-content: space-between;
            }
        }
        &__filter {
            padding: 2rem 0;
            display: flex;
            overflow-y: auto;
            column-gap: 0.5rem;
            margin-bottom: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                grid-template-columns: auto auto auto auto;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                max-width: 80rem;
                margin-bottom: 0;
            }
            .active {
                border: 1px solid ${(p) => p.theme.colors.secondary} !important;
                .title {
                    color: ${(p) => p.theme.colors.secondary}!important;
                }
            }
            &-item {
                display: flex;
                padding: 0.5rem 1rem;
                border-radius: 1.6rem;
                border: 1px solid ${(p) => p.theme.colors.darkBlack};
                justify-content: center;
                cursor: pointer;
                min-width: 12rem;
                .title {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    padding-right: 1rem;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    height: 20px;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &__search {
            display: flex;
            align-items: center;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 30rem;
            }
            .wrapperSelect {
                width: 100%;
            }
            z-index:1000;

        }
        &__calendar {
            padding-top: 2rem;
        }
    }
`;


export const StyledAlcoholTable = styled.div`
  background-color:${(p)=>p.theme.colors.white02};

  .al-table {
    background-color:${(p)=>p.theme.colors.white00};
    border-radius:6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top:1rem;
  }

  .subTitle {
    background-color:${(p)=>p.theme.colors.lightgray09};
    border-top-right-radius:6px;
    border-top-left-radius:6px;
    padding:1rem;

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
    display:none;
    }
  }

  .al-content {
    padding:1rem;

  }

  .userContainer{
    display:flex;
    align-items:center;
    gap:0.5rem;
  }

  .recordName {

    &__text{
      color:${(p)=>p.theme.colors.gray03};

      @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      display:none;
      }
    }
  }



`;

export const StyledAlcoholChecker = styled.div`
margin-top:40px;
overflow-x:auto;
width:100%
.table-container {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
    min-width: 500px;
}

th,
td {
  padding: 8px;
  border: 1px solid #ccc;
}

th {
  background-color: #f2f2f2;
}

`;

export const StyledAlcoholInformationHistory = styled.div`
  width: 100%;
  margin-top: 40px;
  overflow-x: auto;

  .table-container {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 500px !important;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
  }

  th {
    background-color: #f2f2f2;
  }
`;
