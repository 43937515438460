import { TimeSheetFormContextProvider } from "../../context";
import { TimeSheetNightAndDay } from "./time-sheet-night-and-day";

export function TimeSheetNightAndDayWrapper() {
    return (
        <TimeSheetFormContextProvider>
            <TimeSheetNightAndDay />
        </TimeSheetFormContextProvider>
    );
}
