import React from "react";
import { TimeSheetView } from "@components";

export const Timesheet = () => {
    return (
        <div>
            <TimeSheetView/>
        </div>
    );
};
