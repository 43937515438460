import styled from "styled-components";

export const StyledAppCalendar = styled.div`
    width: 100%;
    height: 100%;
    height: 37rem;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        width: 60rem;
        /* height: 50rem; */
    }
    .rbc-month-row + .rbc-month-row {
        border-top: 1px solid transparent !important;
    }
    .rbc-day-bg + .rbc-day-bg {
        border-left: 1px solid transparent !important;
    }
    .rbc-header + .rbc-header {
        border-left: 1px solid transparent !important;
    }
    .rbc {
        &-month-header {
            max-height: 3rem;
            position: relative !important;
        }
        &-month-row {
            z-index: 1;
            max-height: 5rem;
            border: none;
        }
        &-row-bg {
            position: absolute;
            z-index: 0;
        }
        &-row-content {
            position: relative;
            z-index: 1;
        }
        &-row {
            height: 100%;
        }
        &-row:first-child {
            position: absolute;
            z-index: 0;
            width: 100%;
        }
        &-button-link {
            font-size: 1.4rem;
            user-select: none;
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                background-color: transparent;
            }
        }
        &-date-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
        }
        &-event {
            height: 100%;
            border-radius: 2rem;
        }
        &-event-content {
            .MuiButton-root {
                height: 100% !important;
                padding: 0;
            }
        }
        &-background-event {
            padding: 0;
        }
        &-row-segment {
            padding: 0;
        }
        &-off-range {
            color: ${(p) => p.theme.colors.darkBlack};
        }
        &-selected-sell {
            background-color: gray !important;
        }
    }
    .rbc-row-content {
        height: 100%;
    }
    .rbc-month-view {
        border: 1px solid transparent !important;
    }
    .rbc-event-content {
        height: 100%;
    }
    /* .rbc-calendar {
        width: 400px;
    } */
    .rbc-row-bg {
        height: 100%;
    }
    .rbc-row-content {
        max-height: 5rem;
    }
    .rbc-event {
        background: transparent !important;
    }
    .MuiButton-root {
        width: 100%;
    }
    .label-date {
        display: none;
    }
    .back-next-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        .btn-current {
            display: flex;
            align-items: center;
        }
        .MuiIconButton-root {
            padding: 0;
        }
    }
    .selected-day {
        background-color: ${(p) => p.theme.colors.secondary};
    }
    .rbc-row-bg .special-day {
        background-color: ${(p) => p.theme.colors.secondary};
        color: white !important;
        &:first-child {
            border-top-left-radius: 2rem !important;
            border-bottom-left-radius: 2rem !important;
        }
        &:last-child {
            border-top-right-radius: 2rem !important;
            border-bottom-right-radius: 2rem !important;
        }
    }
`;
export const StyledToolTipCalendar = styled.div`
    display: flex;
    .MuiAvatar-root {
        width: 3.6rem;
        height: 3.6rem;
    }
    .title {
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        display: flex;
        align-items: center;
        padding-left: 1rem;
        font-size: 1.6rem;
    }
    .content {
        height: 100%;
        width: 100%;
    }
    .MuiButton-root {
        height: 4rem;
        width: 4rem;
    }
`;
