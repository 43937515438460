import { FormControl, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { AppInput, AppTextArea, Error } from "../Common";
interface IProps {
  values: any;
  handleChange: any;
  touched: any;
  errors: any;
  handleBlur: any;
}

const OTNewBereavement = (props: IProps) => {
  const { values, handleChange, touched, errors, handleBlur } = props;

  return (
    <div className="newOT__time">
      {/* <Grid container spacing={4}>
                <Grid item xs={12} md={12}> */}
      <div className="user__title">
        <div className="input">
          <label className="label">連絡先</label>
          <AppTextArea
            name="phoneNumber"
            handleChange={handleChange("phoneNumber")}
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            handleBlur={handleBlur("phoneNumber")}
          // isNumber
          />
        </div>
      </div>
      <div className="user__title">
        <div className="input">
          <AppInput
            label="備考"
            value={values.memo}
            name="memo"
            error={touched.memo ? errors.memo : undefined}
            touched={touched.memo}
            handleChange={handleChange("memo")}
          />
        </div>
      </div>
      {/* </Grid>
                <Grid item xs={12} md={12}> */}
      <label className="label" >続柄</label>
      <div className="input">
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            onChange={handleChange("family_living")}
            name="family_living"
            defaultValue={values.family_living}
          >
            <FormControlLabel
              value={0}
              control={<Radio size="medium" />}
              label="同居"
            />
            <FormControlLabel
              value={1}
              control={<Radio size="medium" />}
              label="別居"
            />
          </RadioGroup>
        </FormControl>
        <div>
          {!!errors.family_living && !!touched.family_living && (
            <Error>{errors.family_living}</Error>
          )}
        </div>
      </div>
      <div className="input">
        <AppTextArea
          name="family_relationship"
          handleChange={handleChange("family_relationship")}
          value={values.family_relationship}
          error={errors.family_relationship}
          touched={touched.family_relationship}
          handleBlur={handleBlur("family_relationship")}
        // isNumber
        />
      </div>
      {/* </Grid>
            </Grid> */}
    </div>
  );
};

export default OTNewBereavement;
