import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
// import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
// import unchecked_status_icon from "@assets/Icons/OT/un/checked_status_icon.svg";
import { CircularLoader } from "..";
import { StyledDetailView, OTApprovalProcess } from ".";
import { appRouteEnums, OVERTIME_TYPE_VALUE } from "@enums";
import { notiApi, overtimeApi } from "@api";
import { linkImage } from "@utils";
import { selectNoti, updateNumberNoti, updateSnackBar } from "@redux";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
// import { FormControlLabel } from "@material-ui/core";
import OTDetailVacation from "./OTDetailType/OTDetailVacation";
import OTDetailOvertime from "./OTDetailType/OTDetailOvertime";
import OTDetailBereavement from "./OTDetailType/OTDetailBereavement";
import OTDetailDayAndNight from "./OTDetailType/OTDetailDayAndNight";
import OTDetailHalfDay from "./OTDetailType/OTDetailHalfday";
import OTDetailHoliday from "./OTDetailType/OTDetailHoliday";
import checkIcon from "@assets/checkIcon.png";
import xIcon from "@assets/xIcon.png";

export const OTDetailView = () => {
  const { id } = useParams<{ id: any }>();
  const notifications = useSelector(selectNoti);
  const urlParams = new URLSearchParams(window.location.search);
  const idNoti = Number(urlParams?.get("noti"));
  const statusNoti = Number(urlParams?.get("status"));
  // component states
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>();
  const [check, setCheck] = React.useState<any>();
  const [reloadPage, setReloadPage] = React.useState<boolean>(false);
  const [isSectionChief, setIsSectionChief] = React.useState<boolean>();

  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // fetch api
  const fetchViewDetail = async (id: any) => {
    setLoading(true);
    try {
      const res = await overtimeApi.viewOTDetail({
        id,
      });
      setData(res.data.data);
      const roleUserRes = await overtimeApi.getRoleOfUser(res?.data?.data?.overtimeData?.employee?.id);
      setIsSectionChief(roleUserRes?.data?.data?.isSectionChief);
      setCheck(res.data.data?.checkConfirm);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response.data.message,
        })
      );
      history.push(appRouteEnums.PATH_OVERTIME);
    }
  };
  // fetch api approval status
  const fetchApprovalStatusOT = async (values: any, resetForm: any) => {
    setLoading(true);
    try {
      await overtimeApi.confirmApprovalStatus({
        overtime_id: id,
        status: values.radioValue,
        comment: values.inputValue || "",
      });
      setLoading(false);
      setReloadPage(!reloadPage);
      resetForm();
    } catch (err: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response.data.data,
        })
      );
    }
  };
  React.useEffect(() => {
    if (!check && statusNoti === 0) {
      handleOnSelectEmployeeNoti();
    }
    fetchViewDetail(id);
    return () => {
      // do something with cleanup
    };
  }, [id, reloadPage]);
  // React.useEffect(() => {
  //     fetchViewDetail(id);
  //     return () => {
  //         // do something with cleanup
  //     };
  // }, [id, reloadPage]);

  // events

  const handleOnSelectEmployeeNoti = async () => {
    const res = await notiApi.updateStatusNotification(idNoti);
    if (res.status === 200) {
      dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
    }
  };
  // const handleOnSelectManagerNoti = async () => {
  //   const res = await notiApi.updateStatusNotification(idNoti);
  //   if (res.status === 200) {
  //     dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
  //     history.push(`/${NotificationLinkEnums.REQUEST_OT}`);
  //   }
  // };

  const handleSubmitConfirmApprovalStatus = (values: any, resetForm: any) => {
    // handleOnSelectManagerNoti();
    fetchApprovalStatusOT(values, resetForm);
  };
  let detailComponent;
  switch (data?.overtimeData?.title) {
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
      detailComponent = <OTDetailVacation data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
      detailComponent = <OTDetailVacation data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
      detailComponent = <OTDetailOvertime data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
      detailComponent = <OTDetailBereavement data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
      detailComponent = <OTDetailHalfDay data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
      detailComponent = <OTDetailHoliday data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
      detailComponent = <OTDetailVacation data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
      detailComponent = <OTDetailVacation data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
      detailComponent = <OTDetailVacation data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
      detailComponent = <OTDetailOvertime data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
      detailComponent = <OTDetailDayAndNight data={data} />;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
      detailComponent = <OTDetailDayAndNight data={data} />;
      break;
    default:
      break;
  }
  return (
    <StyledDetailView>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading} />
      <div className="detail">
        <div className="detail__top">
          {/* <p className="detail__top-icon" onClick={() => history.push(appRouteEnums.PATH_OVERTIME)}>
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p> */}
          <p className="detail__top-title">
            {/* {data?.overtimeData?.employee?.last_name}
            {data?.overtimeData?.employee?.first_name} */}
            申請項目
          </p>
          <div className="detail__top-status">
            {data?.status === OT_STATUS_OK && (
              <div className="detail__top-checked">
                <span className="text">OK</span>
                <img className="logoutIcon" src={checkIcon} alt="icon" />
              </div>
            )}
            {data?.status === OT_STATUS_PENDING && (
              <div className="detail__top-pending">
                <span className="text">未決</span>
              </div>
            )}
            {data?.status === OT_STATUS_REJECT && (
              <div className="detail__top-unChecked">
                <span className="text">NG</span>
                <img className="logoutIcon" src={xIcon} alt="icon" />
              </div>
            )}
          </div>
        </div>



        <div className="wrapper">
          <div className="detail__content">
            {/* {data?.status === OT_STATUS_OK && */}

            {/* F2-017 start */}
            {/* {data?.checkConfirm ? (
                            <div className="detail__content-info">
                                <OTApprovalProcess handleOnSubmit={handleSubmitConfirmApprovalStatus} showForm={data?.checkConfirm} data={data} />
                            </div>
                        ) : (
                            data?.confirmation &&
                            data?.confirmation?.length > 0 && (
                                <div className="detail__content-info">
                                    <OTApprovalProcess handleOnSubmit={handleSubmitConfirmApprovalStatus} showForm={data?.checkConfirm} data={data} />
                                </div>
                            )
                        )} */}
            {/* F2-017 end */}
            {/* ) : (
                            ""
                        )} */}
            <div className="detail__content-info">
              <div className="detail__content-top__title">
                {/* <span>題名</span> */}
                <span>{data?.overtimeData?.title}</span>
                <span
                  style={{
                    color: `${data?.overtimeData?.type_of_overtime ? "black" : "red"}`,
                  }}
                >
                  {data?.overtimeData?.type_of_overtime ? "本届" : "予定届"}
                </span>
              </div>

              <div className="detail__content-main">

                <div className="user">
                  <div className="user__name">
                    {/* <p className="user__title">氏名</p> */}
                    <div className="user__avatar">
                      <span className="user__profile">
                        <Avatar src={linkImage(data?.overtimeData?.employee?.avatar?.path)} />
                      </span>

                      <div>
                        <span className="user__text-main">
                          {data?.overtimeData?.employee?.last_name}
                          {data?.overtimeData?.employee?.first_name}
                        </span>
                        {!isSectionChief && (
                          <div className="user">
                            <div className="user__name">
                              <span className="user__title">所属</span>
                              <p className="user__text-main">{data?.overtimeData?.group?.name}</p>
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  </div>
                </div>

                <div className="user__contents">
                  <p className="user__title">作成日時</p>
                  <p className="user__timesheet">{data?.overtimeData?.created_at}</p>
                </div>

                <div className="user__contents">
                  <p className="user__title">シングルタイプ</p>
                  {data?.overtimeData?.type_of_overtime?.toString() === "1" ? (
                    <p className="user__title">本届</p>
                  ) : (
                    <p className="user__title">予定届</p>
                  )}

                </div>

                {/* <RadioGroup>
                  {data?.overtimeData?.type_of_overtime?.toString() === "1" ? (
                    <FormControlLabel
                      // disabled
                      control={<Radio checked={true} />}
                      label="本届"
                    />
                  ) : (
                    <FormControlLabel
                      // disabled
                      control={<Radio checked={true} />}
                      label="予定届"
                    />
                  )}
                </RadioGroup> */}

                <div className="user__contents">
                  <p className="title">開始</p>
                  <p className="content">{data?.overtimeData?.from}</p>
                </div>

                <div className="user__contents">
                  <p className="title">終了</p>
                  <p className="content">{data?.overtimeData?.to}</p>
                </div>


                {/* <div className="date"> */}
                {/* <div className="user-left">
                                    <p className="user__title">題名</p>
                                    <p className="user__timesheet">{data?.overtimeData?.title}</p>
                                </div> */}
                {/* <div className="user-right">
                                    <p className="user__title">作成日時</p>
                                    <p className="user__timesheet">
                                        {data?.overtimeData?.created_at}
                                    </p>
                                </div> */}
                {/* <div className="date__from date__left">
                    <p className="title">開始</p>
                    <p className="content">{data?.overtimeData?.from}</p>
                  </div>
                  <div className="date__from date__right">
                    <p className="title">終了</p>
                    <p className="content">{data?.overtimeData?.to}</p>
                  </div> */}
                {/* </div> */}

                {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY && (
                  <div className="user__contents">
                    <p className="user__title">振替休日予定</p>
                    {data?.overtimeData?.compensatory_leave?.toString() === "1" ? (

                      <p className="user__title">はい</p>
                    ) : data?.overtimeData?.compensatory_leave?.toString() === "2" ? (

                      <p className="user__title">未定</p>
                    ) : (
                      <p className="user__title">いいえ</p>
                    )}
                  </div>

                )}
                {!!data?.overtimeData?.detail && (
                  <div className="user__contents">
                    <p>申請内容</p>
                    <p className="des__content">{data?.overtimeData?.detail}</p>
                  </div>
                )}


                {/* <div className="user__id"> */}
                {/* {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY && (
                    <div className="user-content">
                      <div className="user-content__left">
                        <p className="user__title">代休予定</p>
                        <RadioGroup>
                          {data?.overtimeData?.compensatory_leave?.toString() === "1" ? (
                            <FormControlLabel
                              // disabled
                              control={<Radio checked={true} />}
                              label="はい"
                            />
                          ) : data?.overtimeData?.compensatory_leave?.toString() === "2" ? (
                            <FormControlLabel
                              // disabled
                              control={<Radio checked={true} />}
                              label="未定"
                            />
                          ) : (
                            <FormControlLabel
                              // disabled
                              control={<Radio checked={true} />}
                              label="いいえ"
                            />
                          )}
                        </RadioGroup>
                        {!!data?.overtimeData?.detail && (
                          <div>
                            <p>申請内容</p>
                            <p className="des__content">{data?.overtimeData?.detail}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )} */}
                {/* {data?.overtimeData?.reason?.name && (
                                    <div className="des user-content__right">
                                        <p className="des__title">事由</p>
                                        <p className="des__content">
                                            {data?.overtimeData?.reason?.name}
                                        </p>
                                    </div>
                                )}
                                {data?.overtimeData?.note && (
                                    <div className="des user-content__right">
                                        <p className="des__title">事由</p>
                                        <p className="des__content">{data?.overtimeData?.note}</p>
                                    </div>
                                )} */}
                {/* {data?.overtimeData?.title ===
                                    OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT && (
                                    <div className="des">
                                        <Divider />
                                        <h3>日勤</h3>
                                    </div>
                                )}
                                {data?.overtimeData?.title ===
                                    OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY && (
                                    <div className="des">
                                        <Divider />
                                        <h3>夜勤</h3>
                                    </div>
                                )} */}
                {/* {data?.overtimeData?.constructionId?.name && (
                                    <div className="des">
                                        <p>工事名</p>
                                        <p className="des__content">
                                            {data?.overtimeData?.constructionId?.name}
                                        </p>
                                    </div>
                                )} */}
                {/* {data?.overtimeData?.memo && (
                                    <div className="des">
                                        <p className="">備考</p>
                                        <p className="des__content">{data?.overtimeData?.memo}</p>
                                    </div>
                                )} */}
                {/* </div> */}
                {detailComponent}
                {/* {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
                                <div className="date des">
                                    <div className="date__from date__left">
                                        <p className="title">開始時間</p>
                                        <p className="content">{data?.overtimeData?.start_at}</p>
                                    </div>
                                    <div className="date__from date__right">
                                        <p className="title">終了時間</p>
                                        <p className="content">{data?.overtimeData?.stop_at}</p>
                                    </div>
                                </div>
                            )} */}
                {/* {data?.overtimeData?.break_time && (
                                <div className="des">
                                    <p className="">休憩 (hour)</p>
                                    <p className="des__content">{data?.overtimeData?.break_time}</p>
                                </div>
                            )}
                            {data?.overtimeData?.work_time && (
                                <div className="des">
                                    <p className="">稼働時間 (hour)</p>
                                    <p className="des__content">{data?.overtimeData?.work_time}</p>
                                </div>
                            )}
                            {data?.overtimeData?.work_detail && (
                                <div className="des">
                                    <p className="">業務内容</p>
                                    <p className="des__content">
                                        {data?.overtimeData?.work_detail}
                                    </p>
                                </div>
                            )} */}
                {/* {data?.overtimeData?.phone_number && (
                                <div className="des">
                                    <p className="">連絡先</p>
                                    <p className="des__content">
                                        {data?.overtimeData?.phone_number}
                                    </p>
                                </div>
                            )} */}
                {/* {data?.overtimeData?.title ===
                                OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT &&
                                (data?.overtimeData?.family_living ||
                                    data?.overtimeData?.family_relationship) && (
                                    <div className="des">
                                        <p className="">続柄</p>
                                        <p className="des__content">
                                            {data?.overtimeData?.family_living === 0
                                                ? "同居"
                                                : "別居"}{" "}
                                            - {data?.overtimeData?.family_relationship}
                                        </p>
                                    </div>
                                )} */}
                {/* {data?.overtimeData?.contractor.length > 0 && (
                                <div className="des">
                                    <p className="">業者名</p>
                                    {data?.overtimeData?.contractor?.map((item) => {
                                        return <p className="des__content">{item?.name}</p>;
                                    })}
                                </div>
                            )} */}
                {/* form extra */}
                {/* {data?.overtimeData?.title ===
                                OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT && (
                                <div className="des">
                                    <Divider />
                                    <h3>夜勤</h3>
                                </div>
                            )}
                            {data?.overtimeData?.title ===
                                OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY && (
                                <div className="des">
                                    <Divider />
                                    <h3>日勤</h3>
                                </div>
                            )}
                            <div>
                                {data?.overtimeData?.constructionIdExtra?.name && (
                                    <div className="des">
                                        <p>工事名</p>
                                        <p className="des__content">
                                            {data?.overtimeData?.constructionIdExtra?.name}
                                        </p>
                                    </div>
                                )}
                                {data?.overtimeData?.memo_extra && (
                                    <div className="des">
                                        <p className="">備考</p>
                                        <p className="des__content">
                                            {data?.overtimeData?.memo_extra}
                                        </p>
                                    </div>
                                )}
                            </div>
                            {data?.overtimeData?.start_at_extra &&
                                data?.overtimeData?.stop_at_extra && (
                                    <div className="date des">
                                        <div className="date__from date__left">
                                            <p className="title">開始時間</p>
                                            <p className="content">
                                                {data?.overtimeData?.start_at_extra}
                                            </p>
                                        </div>
                                        <div className="date__from date__right">
                                            <p className="title">終了時間</p>
                                            <p className="content">
                                                {data?.overtimeData?.stop_at_extra}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            {data?.overtimeData?.break_time_extra && (
                                <div className="des">
                                    <p className="">休憩 (hour)</p>
                                    <p className="des__content">
                                        {data?.overtimeData?.break_time_extra}
                                    </p>
                                </div>
                            )}
                            {data?.overtimeData?.work_time_extra && (
                                <div className="des">
                                    <p className="">稼働時間 (hour)</p>
                                    <p className="des__content">
                                        {data?.overtimeData?.work_time_extra}
                                    </p>
                                </div>
                            )}
                            {data?.overtimeData?.work_detail_extra && (
                                <div className="des">
                                    <p className="">業務内容</p>
                                    <p className="des__content">
                                        {data?.overtimeData?.work_detail_extra}
                                    </p>
                                </div>
                            )}
                            {data?.overtimeData?.contractorextra.length > 0 && (
                                <div className="des">
                                    <p className="">業者名</p>
                                    {data?.overtimeData?.contractorextra?.map((item) => {
                                        return <p className="des__content">{item?.name}</p>;
                                    })}
                                </div>
                            )} */}
              </div>
            </div>

            {data?.checkConfirm ? (
              // <div className="detail__content-info">
              <OTApprovalProcess handleOnSubmit={handleSubmitConfirmApprovalStatus} showForm={data?.checkConfirm} data={data} />
              // </div>
            ) : (
              data?.confirmation &&
              data?.confirmation?.length > 0 && (
                // <div className="detail__content-info">
                <OTApprovalProcess handleOnSubmit={handleSubmitConfirmApprovalStatus} showForm={data?.checkConfirm} data={data} />
                // </div>
              )
            )}
          </div>
        </div>
      </div>
    </StyledDetailView>
  );
};
