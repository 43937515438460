export const ArrowDropDownIcon = () => {
    return (
        <>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.2807 5.96667L8.93404 10.3133C8.4207 10.8267 7.5807 10.8267 7.06737 10.3133L2.7207 5.96667"
                    stroke="black"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
