import React from "react";

import { menuTitleEnums } from "@enums";
import { timesheetApi } from "@api";
import { StyledWorkScheduleScreen, WorkScheduleWrap } from ".";
import { CircularLoader, StyledToggleButton, TitleMenuComponent } from "..";
import WorkScheduleListConstructionSite from "./WorkScheduleListConstructionSite";
import { useDispatch } from "react-redux";
import { selectPage, setPage, useAppSelector } from "@redux";

export const NewWorkScheduleScreen = () => {
  // hooks
  const dispatch = useDispatch();
  const page = useAppSelector(selectPage)?.page;

  // component states

  const [loading, setLoading] = React.useState(false);
  const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();

  React.useEffect(() => {
    fetchListConstructions();
    return () => {
      // do something with cleanup
    };
  }, []);

  const fetchListConstructions = async () => {
    setLoading(true);
    try {
      const result = await timesheetApi.getListConstructions();
      if (result?.data?.success) {
        setOptionSiteConstructions(result.data.data);
        setLoading(false);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const pageTitle = page === 1 ? menuTitleEnums.TITLE_WORK_SCHEDULE_LIST_CONSTRUCTION : menuTitleEnums.TITLE_WORK_SCHEDULE;

  return (
    <StyledWorkScheduleScreen>
      <CircularLoader loading={loading} />

      <div className="workSchedule">

        <div className="topContents">
          <TitleMenuComponent text={pageTitle} />

          <div className="buttons">
            <StyledToggleButton onClick={() => dispatch(setPage(1))} isActive={page === 1}>
              {menuTitleEnums.TITLE_WORK_SCHEDULE_LIST_CONSTRUCTION}
            </StyledToggleButton>
            <StyledToggleButton onClick={() => dispatch(setPage(2))} isActive={page === 2}>
              {menuTitleEnums.TITLE_WORK_SCHEDULE}
            </StyledToggleButton>
          </div>
        </div>



        {page === 1 ? <WorkScheduleListConstructionSite options={optionSiteConstructions} /> : <WorkScheduleWrap />}
      </div>
    </StyledWorkScheduleScreen>
  );
};
