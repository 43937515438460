import React from "react";
import { Formik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CircularLoader, AppModal, PassChangeButton } from "..";
import { StyledInstrumentCalendar, InstrumentEditTime } from ".";
// import arrow_left from "@assets/Icons/arrow_left.svg";
// import { appRouteEnums } from "@enums";
import { getListDateEvents, groupDateResponse } from "@utils";
import { setSelectedDateInstrument, updateSnackBar } from "@redux";
import { instrumentApi } from "@api";
import moment from "moment";
import AppDragAndDrop from "../Calendar/AppDragAndDropCalendar";

export const InstrumentCalendarComponent = () => {
  const { id } = useParams<{ id: any }>();
  // component state
  const [selected, setSelected] = React.useState<any>([]);
  const [calendarData, setCalendarData] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [dataEdit, setDataEdit] = React.useState<any>();
  const [reloadPage, setReloadPage] = React.useState<boolean>(false);
  const initialValuesPackage = {
    selectValue: "",
  };
  const [slots, setSlots] = React.useState<any>();
  // hooks
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  // const categoryId = query.get("categoryId");
  // const subCategoryId = query.get("subCategoryId");
  const resId = query.get("resId");
  const history = useHistory();
  const dispatch = useDispatch();
  // api show calendar data
  const fetchCalendaData = async (id) => {
    setLoading(true);
    try {
      const response = await instrumentApi.instrumentItemCalendar({
        id: id,
      });
      setLoading(false);
      setCalendarData(response.data.data);
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response?.data?.message,
        })
      );
    }
  };
  React.useEffect(() => {
    dispatch(setSelectedDateInstrument(selected));
  }, [selected]);
  React.useEffect(() => {
    fetchCalendaData(id);
    return () => {
      // do clean up function
    };
  }, [reloadPage]);
  const handleOpenEditModal = (values: any) => {
    setDataEdit(values.events);
    setSlots(values.selectedDate);
    setOpenModal(true);
  };
  const handleCloseEditModal = () => {
    setDataEdit("");
    setOpenModal(false);
  };
  const fetchUpdateBorrowingItem = async (values: any) => {
    setLoading(true);
    try {
      const res = await instrumentApi.updateBorrowItem(
        {
          from: moment(values?.dateRate.startDate).format("YYYY-MM-DD"),
          to: moment(values?.dateRate.endDate).format("YYYY-MM-DD"),
        },
        dataEdit?.borrowingId
      );
      setReloadPage(!reloadPage);
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "success",
          message: res.data.message,
        })
      );
      setOpenModal(false);
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response?.data?.data,
        })
      );
    }
  };
  const handleEditSubmit = (values: any) => {
    fetchUpdateBorrowingItem(values);
  };
  return (
    <StyledInstrumentCalendar>
      {/* <AppTopBar /> */}
      <AppModal open={openModal} handleClose={handleCloseEditModal}>
        <InstrumentEditTime
          data={dataEdit}
          slots={slots}
          handleEditSubmit={handleEditSubmit}
        />
      </AppModal>
      <CircularLoader loading={loading} />
      <div className="InstrumentDetail">
        <div className="InstrumentDetail__top">
          {/* <span
            className="InstrumentDetail__back"
            onClick={() => {
              categoryId
                ? history.push(
                  appRouteEnums.PATH_INSTRUMENTS +
                  `?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
                )
                : history.push(appRouteEnums.PATH_INSTRUMENTS);
            }}
          >
            <img src={arrow_left} alt="<" />
          </span> */}
          <div className="InstrumentDetail__top-title">
            <p className="main">機器</p>
            {/* <p className="main">{calendarData?.title}</p>
            {calendarData?.category ? (
              <p className="subTitle">{calendarData?.category}</p>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <Formik
          initialValues={initialValuesPackage}
          onSubmit={() => {
            resId ? history.push(`/instruments/${id}?resId=${resId}`) :
              history.push(`/instruments/${id}`);
          }}
        >
          {({ handleSubmit }) => {
            return (
              <div className="InstrumentDetail__wrapperContent">
                <div className="InstrumentDetail__selectWrapper"></div>
                {/* <div className="InstrumentDetail__wrapperContent"> */}
                <div className="InstrumentDetail__calendarWrapper">
                  <div className="calendarTitle">
                    <p className="main">{calendarData?.title}</p>
                    {calendarData?.category ? (
                      <p className="subTitle">{calendarData?.category}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="calendar">
                    <div className="calendar-content">
                      {calendarData && (
                        <AppDragAndDrop
                          selected={selected}
                          events={getListDateEvents(
                            calendarData?.history
                          )}
                          setSelected={setSelected}
                          listEventsDate={groupDateResponse(
                            getListDateEvents(calendarData?.history)
                          )}
                          handleOpenEditModal={handleOpenEditModal}
                        />
                      )}
                    </div>
                    {selected && (
                      <div className="wrappBtn">
                        {/* <div className="btn"> */}
                        <PassChangeButton onClick={() => handleSubmit()}>次へ</PassChangeButton>
                        {/* <AppButton onClick={handleSubmit} text="次へ" /> */}
                        {/* </div> */}
                      </div>
                    )}

                  </div>
                </div>
                {/* </div> */}
                {/* {selected && ( */}
                {/* <div className="wrappBtn"> */}
                {/* <div className="btn"> */}
                {/* <PassChangeButton onClick={()=>handleSubmit()}>次へ</PassChangeButton> */}
                {/* <AppButton onClick={handleSubmit} text="次へ" /> */}
                {/* </div> */}
              </div>
              // )}
              // </div>
            );
          }}
        </Formik>
      </div>
    </StyledInstrumentCalendar>
  );
};
