import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { overtimeApi } from "@api";
import { RootState } from ".";

interface IState {
    loadingOvertimes: boolean;
    overtimes: Array<{ month: string; data: any }>; // Array to store overtimes by month
    fetchedMonths: string[];
}

const initialState: IState = {
    loadingOvertimes: false,
    overtimes: [],
    fetchedMonths: [],
};

// Async Thunks for fetching data
export const fetchOvertimes = createAsyncThunk(
    "overtime/fetchOvertimes",
    async ({ month, groups = [], preFetch = false }: { month: string; groups: any[], preFetch?: boolean; }, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;

        function getAdjacentMonths(month: string) {
            const adjacentMonths: string[] = [];
            const date = new Date(month + "-15");
            const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).toISOString().slice(0, 7);
            const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()).toISOString().slice(0, 7);
            adjacentMonths.push(prevMonth);
            adjacentMonths.push(nextMonth);
        
            for (const adjacentMonth of adjacentMonths) {
                if (!state.overtime.fetchedMonths.includes(adjacentMonth)) {
                    dispatch(fetchOvertimes({ month: adjacentMonth, groups: groups, preFetch: true }));
                }
            }
        }
    

        // Check if the requested month is already fetched or is currently being fetched
        const existingMonth = state.overtime.overtimes.find((overtime) => overtime.month === month);
        const loadingMonth = state.overtime.fetchedMonths.includes(month);

        // The data exists (or is being fetched) so return it
        if (existingMonth || loadingMonth) {
            // Fetch adjacent months if not a prefetch
            if (!preFetch) { getAdjacentMonths(month); }

            return { data: existingMonth?.data, month, preFetch };
        } else {
            // Need to fetch the data
            try {
                const response = await overtimeApi.getListByTypeFilter({ month: month, typeTitles: groups });
                const overtimeData = { data: response.data, month };

                // Fetch adjacent months if not a prefetch
                if (!preFetch) { getAdjacentMonths(month); }

                return overtimeData;
            } catch (err: any) {
                return rejectWithValue(err.response.data);
            }
        }
    }    
);

const overtimeSlice = createSlice({
    name: "overtime",
    initialState: initialState,

    reducers: {
        resetOvertimes: (state) => {
            state.overtimes = [];
            state.fetchedMonths = [];
        },
    },
    extraReducers: (builder) => {
        // Fetch overtimes
        builder.addCase(fetchOvertimes.pending, (state) => {
            state.loadingOvertimes = true;
        });
        builder.addCase(fetchOvertimes.fulfilled, (state, action: { payload: any }) => {
            state.loadingOvertimes = false;
            state.overtimes.push(action.payload);
            state.fetchedMonths.push(action.payload.month);
        });
        builder.addCase(fetchOvertimes.rejected, (state) => {
            state.loadingOvertimes = false;
        });
    },
});

export default overtimeSlice.reducer;

export const { resetOvertimes } = overtimeSlice.actions;

export const selectOvertimeLoading = (state: RootState) => state.overtime.loadingOvertimes;
export const selectOvertimes = (state: RootState) => state.overtime.overtimes;
export const selectOvertimeByMonth = (month: string) => (state: RootState) => state.overtime.overtimes.find((overtime) => overtime.month === month)?.data;