import React from "react";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import { ArrowLeftIcon } from "@assets";
import { AppButton, AppInput, CircularLoader } from "@components";
import { resetPasswordSchema } from "@utils";
import { StyledChangePassword } from ".";
import yellow_lines_icon from "@assets/Images/yellow_lines.png";
import { authRouteEnums } from "@enums";
import {
    resetPassword,
    useAppSelector,
    selectAuth,
    resetAuth,
    resetModal,
    selectApp,
} from "@redux";
import { authApi } from "@api";
import { AppAlert, AppModal } from "..";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";

export const ResetPasswordView = () => {
    // redux store
    const auth = useAppSelector(selectAuth);
    const app = useAppSelector(selectApp);
    const { modal } = app;
    const { isLoading } = auth;
    const [showUI, setShowUI] = React.useState(false);
    // hooks
    const history = useHistory();
    const dispatch = useDispatch();
    // init value of form
    const initialValuesPackage = {
        password: "",
        new_password_confirmation: "",
    };
    const params = queryString.parse(location.search);

    const checkTokenExpired = async () => {
        if (!params?.token || !params?.email) {
              history.push("error");
              setShowUI(false);
            return;
        }
        try {
            const res = await authApi.tokenExpired({
                email: params?.email,
                token: params?.token,
            });
            if (res) {
                resetAuth();
                setShowUI(true);
                return true;
            }
        } catch (err) {
            dispatch(resetAuth());
            history.push("error");
            setShowUI(false);
        }
    };
    React.useLayoutEffect(() => {
        checkTokenExpired();
    }, []);
    const handleCloseModal = () => {
        dispatch(resetModal());
        dispatch(resetAuth());
        history.push(authRouteEnums.PATH_SIGNIN);
    };
    return (
        <StyledChangePassword>
            <CircularLoader loading={isLoading} />
            <AppModal open={modal.show} handleClose={handleCloseModal}>
                <AppAlert
                    icon={<img src={hot_yellow_frame} alt="icon" />}
                    text={modal.message}
                    handleClose={handleCloseModal}
                />
            </AppModal>
            {showUI ? (
                <div className="forgotPassword__wrapper">
                    <div className="backToPrevious">
                        <span
                            className="backToPrevious__btn"
                            onClick={() => history.push(authRouteEnums.PATH_SIGNIN)}
                        >
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                            >
                                <ArrowLeftIcon />
                            </IconButton>
                        </span>
                        <span className="backToPrevious__text">ログイン</span>
                    </div>
                    <div className="fillForm">
                        <div className="fillForm__title">
                            <span className="text">パスワードを忘れの場合</span>

                            <div className="line">
                                <img className="line__icon" src={yellow_lines_icon} />
                            </div>
                        </div>
                        <div className="fillForm__content">
                            <Formik
                                initialValues={initialValuesPackage}
                                onSubmit={(values) => {
                                    dispatch(
                                        resetPassword({
                                            dispatch,
                                            history,
                                            values: {
                                                ...values,
                                                password_confirmation:
                                                    values.new_password_confirmation,
                                                email: params?.email,
                                                token: params?.token,
                                            },
                                        })
                                    );
                                }}
                                validationSchema={resetPasswordSchema}
                            >
                                {({
                                    handleSubmit,
                                    values,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    errors,
                                }) => {
                                    return (
                                        <div className="fillForm__wrapper">
                                            <div className="fillForm__input">
                                                <AppInput
                                                    value={values.password}
                                                    name="password"
                                                    handleChange={handleChange("password")}
                                                    label="新しいパスワード"
                                                    isPassword
                                                    handleBlur={handleBlur("password")}
                                                    error={errors.password}
                                                    touched={touched.password}
                                                />
                                            </div>
                                            <div className="fillForm__input">
                                                <AppInput
                                                    value={values.new_password_confirmation}
                                                    name="new_password_confirmation"
                                                    handleChange={handleChange(
                                                        "new_password_confirmation"
                                                    )}
                                                    label="新しいパスワードを確認"
                                                    handleBlur={handleBlur(
                                                        "new_password_confirmation"
                                                    )}
                                                    isPassword
                                                    error={errors.new_password_confirmation}
                                                    touched={touched.new_password_confirmation}
                                                />
                                            </div>
                                            <div className="fillForm__btn">
                                                <AppButton text="変える" onClick={handleSubmit} />
                                            </div>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </StyledChangePassword>
    );
};
