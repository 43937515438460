import { TypeOf, z } from "zod";
// import dayjs from "dayjs";

export const timeSheetNightAndDayFormSchema = z.object({
    list_constructions: z
        .array(
            z
                .object({
                    construction_id: z.number(),
                    start_at: z.string().or(z.date()),
                    stop_at: z.string().or(z.date()),
                    break_time: z.number(),
                    weather_id: z.string(),
                    memo: z.string().optional(),
                    livelet_employee: z
                        .array(
                            z.object({
                                employee_id: z.string().or(z.number()),
                                employee_name: z.string().or(z.number()),
                                employee_memo: z.string(),
                            })
                        )
                    ,
                    contractors: z
                        .array(
                            z.object({
                                contractor_id: z.string().or(z.number()),
                                number_of_people: z.number(),
                                work_type_1: z.array(z.string()),
                                work_type_2: z.array(z.string()),
                                work_type_3: z.array(z.string()),
                            })
                        )
                    // ,
                })
            // .refine(
            //     (values) => {
            //         if (!values.start_at) return true;
            //         const startAt = dayjs(values.start_at, "HH:mm");
            //         const stopAt = dayjs(values.stop_at, "HH:mm");
            //         const isValid = dayjs(stopAt).isAfter(startAt);
            //         return isValid;
            //     },
            //     {
            //         message: "終了時間 より大きくなければなりません 開始時間",
            //         path: ["stop_at"],
            //     }
            // )
        )
    ,
    alcohol_check: z.boolean().refine((val) => val !== false, {
        message: "この項目は必須です",
    }), check_date: z.string(),
    inspector_id: z.string().or(z.number()),
    driver_id: z.string().or(z.number()),
    vehicle_id: z.string().or(z.number()),
    device_check: z
        .string()
        .or(z.number()),
    start_time_check_alcohol: z.string().min(1, "この項目は必須です").or(z.date()),
    end_time_check_alcohol: z.string().min(1, "この項目は必須です").or(z.date()),
    start_capacity_alcohol: z.string().min(1, "この項目は必須です").or(z.number()),
    end_capacity_alcohol: z.string().min(1, "この項目は必須です").or(z.number()),
    alcohol_default_check: z.boolean(),
    inspector_default_check: z.boolean(),
    driver_default_check: z.boolean(),
    vehicle_default_check: z.boolean(),
    device_default_check: z.boolean(),
});

export type TimeSheetNightAndDayFormSchemaType = TypeOf<typeof timeSheetNightAndDayFormSchema>;
