import styled from "styled-components";
export const StyledSignIn = styled.div`
  .login {
    height:100vh;
    width:100vw;
    background-color: ${(p) => p.theme.colors.white02};
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    &__bigContainer{

      width:100%;

      @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        max-width: 100%;

      }

    }

    &__logo{
      width:10rem;
      height:10rem;
      object-fit:contain;
      margin-bottom:1rem;
    }

    $__title {
      color: ${(p) => p.theme.colors.black01};
    }

    &__middleContainer {
      margin:0 auto;
      width:50rem;

       @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        width: 100%;
        max-width: calc(100% - 1rem);
        box-sizing: border-box;
      }

    }

    &__smallContainer {
      display:flex;
      flex-direction:column;
      align-items:center;
      margin:0 auto;
      width:50rem;
      padding:2rem 6rem;
      background-color: ${(p) => p.theme.colors.white00};
      border-radius:6px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

      @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding:2rem 1.5rem;
        width: 100%;
        max-width: calc(100% - 2rem);
        box-sizing: border-box;
      }

    }

    &__input {
      padding:1.5rem 0;

    }

    &__check {
      padding: 0 0 2rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;

    }

    &__forgot {
     cursor: pointer;

    }


  }


    .SignIn {
        overflow: hidden;
        position: relative;
        max-height: 100vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
            max-height: 100%;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            max-height: 100%;
        }
        /* @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            max-height: 100vh;
        } */
        &__iconBg {
            z-index: -1;
            position: absolute;
            right: -0.5rem;
            top: 13.2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                right: 4rem;
                top: 10rem;
            }
        }
        .link {
            font-size: 12px;
            color: ${(p) => p.theme.colors.secondary};
            margin-top: 60px;
            margin-bottom: 20px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-top: 120px;
                text-align: left;
            }
            span {
                word-spacing: -1px;
            }
        }
        &__left {
            width: 100%;
            height: 100vh;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                height: 100%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                height: 100vh;
            }

            &-bg {
                width: 100%;
                height: 100%;
            }
        }
        &__content {
            display: flex;
            justify-content: center;
            padding: 0 60px;
            flex-direction: column;
            height: 100vh;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding: 60px 160px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
                text-align: left;
                padding-top: 160px;
                padding: 60px 80px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                height: 100%;
                padding: 60px 160px;
                padding-top: 160px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                padding: 60px 200px;
                height: 100%;
            }
        }
        &__form {
            width: 100%;
            &-title {
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                font-size: 52px;
                padding-top: 2rem;
            }
            &-wrapper {
                width: 100%;
            }
            &-input {
                margin-top: 24px;
                width: 100%;
            }
            &-btn {
                padding-top: 45px;
            }
            &-checkbox {
                text-align: left;
                margin-top: 24px;
                .Mui-checked {
                    color: ${(p) => p.theme.colors.primary} !important;
                }
                svg {
                    width: 2em;
                    height: 2em;
                }

                .MuiFormControlLabel-label {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
                    font-size: 12px;
                    color: ${(p) => p.theme.colors.gray1};
                }
            }
        }
    }
`;
