
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState{
    listNoti: Array<any>;
    numberNoti: number;
}
const initialState: IState = {
    listNoti:[],
    numberNoti: 0
};

const notificationSlice = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        updateNoti:(state,action:{payload:any})=>{
            state.listNoti = [...state.listNoti,...action.payload];
        },
        resetNoti:(state)=>{
            state.listNoti = [];
        },
        updateNumberNoti:(state,action:{payload:number})=>{
            state.numberNoti = action.payload;
        },
        updateNotificationStatus: (state, action: { payload: { id: string, status: number } }) => {
            const index = state.listNoti.findIndex(notification => notification.id === action.payload.id);
            if (index !== -1) {
              state.listNoti[index].status = action.payload.status;
            }
        },
    },
});
export const selectNoti = (state: RootState) => state.notification;
export const { updateNoti,resetNoti,updateNumberNoti,updateNotificationStatus } =  notificationSlice.actions;
export default notificationSlice.reducer;