import { Grid } from "@mui/material";
import React from "react";

import { StyledListItemDocument, DocumentItemComponent } from ".";
import { NotFoundItemView, CircularLoader } from "..";
import { technicalDocumentType } from "src/types";
interface IProps {
  technicalDocuments: technicalDocumentType[];
  loading?: boolean;
  title?: any;
  handleOpenPDF: (value: any) => void;
  handleDownloadPDF: (url: any) => void;
  handleClosePDF: () => void;
}
export const DocumentListItemComponent = (props: IProps) => {
  const {
    technicalDocuments,
    loading,
    title,
    handleClosePDF,
    handleOpenPDF,
    handleDownloadPDF,
  } = props;
  return (
    <StyledListItemDocument>
      <div className="documentList__title">
        {title?.subCategory && (
          <span>
            {title?.category} ・ {title?.subCategory}
          </span>
        )}
      </div>
      {loading ? (
        <div className="wrapperLoading">
          <CircularLoader loading={loading} type="fullContent" />
        </div>
      ) : (
        <>
          {technicalDocuments?.length ? (
            <div className="documentList__content">
              <Grid container spacing={2}>
                {technicalDocuments?.map((technicalDocument) => (
                  <Grid key={technicalDocument.id} item xs={12} sm={12} md={12}>
                    <DocumentItemComponent
                      handleOpenPDF={handleOpenPDF}
                      technicalDocument={technicalDocument}
                      handleDownloadPDF={handleDownloadPDF}
                      handleClosePDF={handleClosePDF}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <div className="error">
              <NotFoundItemView />
            </div>
          )}
        </>
      )}
    </StyledListItemDocument>
  );
};
