import React from "react";
import { Divider } from "@mui/material";

import { useAppSelector, selectNoti, updateNoti, updateNumberNoti, resetNoti, selectAuth, updateNotificationStatus } from "@redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { StyledNotificationComponent, NotificationItem, NextButton, StyledToggleButtonShort } from ".";
import { CircularLoader, DocumentBorder } from "..";
import { appRouteEnums, ApproveTypeEnums, FilterNotificationEnums, NotificationLinkEnums, NotificationTypeEnums, FilterNotificationTypeEnums, FilterNotificationTypeNameEnums, FilterNotificationStatusEnums } from "@enums";
import { notiApi } from "@api";
import { MESSAGE_DATA_NOT_FOUND } from "@configs";

/*
 * 各通知レコードのクリック処理のリファクタリングについて
 * 
 * 各通知レコードのクリック処理は、dataカラムのtypeキーの値によって分岐する。
 * また、クリックしたレコードのstatusが0（未読）か1（既読）かによっても処理が分岐していた。
 * 
 * このため、各typeごとにstatusが0（未読）と1（既読）の場合の処理を持たせられる型(NotificationHandler)を用意した。
 * 
 * statusが0（未読）の場合は、typeに関係なく共通してAPIを叩いてDBのstatusを1（既読）に
 * 変更する処理を行っていたため、この部分は共通化している。
 * 
 * 将来的に新しいtypeが追加された場合、そのtypeに対応するハンドラーを追加するだけで対応できる設計になっている。
 */
// ハンドラー型定義（未読・既読の場合の処理）
type NotificationHandler = (values: any) => void;

// 通知タイプごとのハンドラーマップ型定義
interface NotificationHandlers {
  [key: string | number]: {              // 注：keyは動的プロパティ名
    read: NotificationHandler;           // 既読の場合の処理
    unread: NotificationHandler;         // 未読の場合の処理
  };
}

/**
 * 通知処理を行うカスタムフック
 * 
 * このフックは通知のクリックイベントを処理し、通知の種類（type）と状態（status）に応じて処理を実行する。
 * 
 * 主な機能：
 * - 通知のステータス更新（未読→既読）
 * - 通知タイプに応じた処理
 * - 未読通知数の更新
 * 
 * 使用方法：
 * const { handleNotification } = useNotificationHandler();
 * handleNotification(notificationData, notificationHandlers);
 */
const useNotificationHandler = () => {
  const dispatch = useDispatch();
  const notifications = useAppSelector(selectNoti);

  // 当該レコードのstatusを1（既読）に変更する関数
  // DBのstatusを1に変更してから、Reduxのstatusも1に更新する。さらにvaluesのstatusも1に更新する。
  const updateStatus = async (values: any) => {
    const notificationId = values.id;
    const res = await notiApi.updateStatusNotification(notificationId);
    if (res.status === 200) {
      dispatch(updateNotificationStatus({ id: notificationId, status: 1 }));  // 当該レコードのみを「読んだ」状態に変更する
      values.status = 1;
      dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
      
      return true;
    }
    return false;
  };

  // クリック時の処理を行う関数
  // 通知のtypeに応じて各タイプの処理を行う（未読の場合はDBを既読に変更してから）。
  const handleNotification = async (values: any, handlers: NotificationHandlers) => {
    // 通知のタイプの取得。普通に存在すればその値。
    // 通知のtypeが空の場合は'no_type'とする。DBのレコードとしてはdataカラムが[]となっている。
    // それ以外の場合は'unexpected_type'とする。これはありえないはずである。
    const type = values.type?.type || (values.type.length === 0 ? 'no_type' : 'unexpected_type');
    if (type === 'unexpected_type') {
      console.error('通知のtypeが予期せぬ値になっています。', values);
      return;
    }
    
    if (values.status === FilterNotificationStatusEnums.ENUMS_STATUS_READ) {
      handlers[type].read(values);
    } else {
      // 未読の場合は、先にDBを既読に変更してから各タイプの処理を行う。
      await updateStatus(values);
      handlers[type].unread(values);
    }
  };

  return { handleNotification };
};

export const NotificationView = () => {
  // redux state
  const notifications = useAppSelector(selectNoti);
  const user = useAppSelector(selectAuth);
  const { userInfo } = user;
  // component state
  const [radioValue, setRadioValue] = React.useState<any>(FilterNotificationStatusEnums.ENUMS_STATUS_UNREAD);
  const [typeValue, setTypeValue] = React.useState<number>(FilterNotificationTypeEnums.TYPE_ALL);
  const [loadMore, setLoadMore] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [reload, setReload] = React.useState<any>();
  const [selectedNotifications, setSelectedNotifications] = React.useState<any>([]);

  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleNotification } = useNotificationHandler();
  
  /*
   * 通知タイプごとのハンドラー定義
   * もし新たな通知タイプが追加された場合は、ここにハンドラーを追加する。
   */
  const notificationHandlers: NotificationHandlers = {
    // 計器
    [NotificationTypeEnums.TYPE_INSTRUMENT]: {
      read: (values) => {
        history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
      },
      unread: (values) => {
        history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
      }
    },
    // 車両
    [NotificationTypeEnums.TYPE_VEHICLE]: {
      read: (values) => {
        history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
      },
      unread: (values) => {
        history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
      }
    },
    // 申請
    [NotificationTypeEnums.TYPE_REQUEST_OT]: {
      read: (values) => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otID}?noti=${values.id}&status=${values.status}`);
      },
      unread: (values) => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otID}?noti=${values.id}&status=${values.status}`);
      }
    },
    // 日報
    [NotificationTypeEnums.TYPE_REQUEST_TIMESHEET]: {
      read: (values) => {
        if (values.type?.creator === userInfo?.id) {
          if (values.type?.status === ApproveTypeEnums.REJECTED) {
            history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
          } else {
            history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
          }
        } else {
          history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
        }
      },
      unread: (values) => {
        if (values.type?.creator === userInfo?.id) {
          if (values.type?.status === ApproveTypeEnums.REJECTED) {
            history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
          } else {
            history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
          }
        } else {
          history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
        }
      }
    },
    // 臨時の残業らしい
    'change_temporary_to_offical': {
      read: (values) => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values?.type?.otID}`);
      },
      unread: (values) => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/${values?.type?.otID}`);
      }
    },
    // 車両返却なんだろう
    'return_vehicle': {
      read: (values) => {
        history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
      },
      unread: (values) => {
        history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
      }
    },
    // dataカラムが[]の場合。実は色んな種類がある
    'no_type': {
      read: () => {
        // 何もしない（Linterが怒るので記載）
      },
      unread: () => {
        // 何もしない（Linterが怒るので記載） 
      }
    },
  };
  
  //what: events
  const handleOnSelectNoti = async (values) => {
    await handleNotification(values, notificationHandlers);
  };

  const handleDeleteNoti = async (id: any) => {
    try {
      await notiApi.deleteNotification({
        id: id,
      });
      dispatch(resetNoti());
      setReload(!reload);
    } catch (err) { }
  };
  const handleToggleSelectNoti = (id: string) => {
    setSelectedNotifications((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If the ID is already in the selected list, remove it
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        // If the ID is not in the selected list, add it
        return [...prevSelected, id];
      }
    });
  };
  const handleDeleteMultipleNoti = async () => {
    if (selectedNotifications.length === 0) {
      return;
    }
    try {
      await notiApi.deleteMultipleNotification({
        ids: selectedNotifications,
      });

      setReload(!reload);
      setIsSelected(false);
      dispatch(resetNoti());
      setSelectedNotifications([]);
    } catch (err) { }
  };
  const fetchListNoti = async () => {
    try {
      setLoading(true);
      const res = await notiApi.viewListNotification({
        page: page,
        status: parseInt(radioValue) === FilterNotificationStatusEnums.ENUMS_STATUS_ALL ? "" : radioValue,
        type_noti: typeValue === FilterNotificationTypeEnums.TYPE_ALL ? "" : typeValue,
      });
      if (page === 1) {
        dispatch(resetNoti());
      }
      dispatch(updateNoti(res.data.data.data));
      dispatch(updateNumberNoti(res.data.data.amount));
      setLoading(false);
      if (!res.data.data.paging.next_page_url) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleLoadMore = () => {
    setPage(page + 1);
  };
  React.useEffect(() => {
    fetchListNoti();
    return () => {
      //    do something cleanup
    };
  }, [page, radioValue, reload, typeValue]);
  React.useEffect(() => {
    return () => {
      dispatch(resetNoti());
    };
  }, []);
  const [isSelected, setIsSelected] = React.useState(false);

  React.useEffect(() => {
    if (isSelected) {
      setSelectedNotifications(notifications.listNoti.map((noti) => noti.id));
    } else {
      setSelectedNotifications([]);
    }
  }, [notifications.listNoti, isSelected]);
  
  const handleSelectAll = (event) => {
    setIsSelected(event.target.checked);
  };


  return (
    <StyledNotificationComponent>
      {/* <AppTopBar /> */}
      <Divider />
      <CircularLoader loading={loading} />
      <div style={{ textAlign: "left" }}>
        <h1 className="title-text">通知</h1>
        <DocumentBorder />
      </div>


      <div className="wrapperForm">
        {/* <p className="top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
          <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
        </p> */}
        <div className="form" style={{ width: "100%" }}>
        <div style={{ textAlign: 'left', marginTop: '0.5rem', marginLeft: '5px'}}><strong>種別:</strong></div>
          <div className="form__buttons">
           
              
                    <StyledToggleButtonShort
                        isActive={typeValue === FilterNotificationTypeEnums.TYPE_ALL}
                        onClick={() => {
                            setTypeValue(FilterNotificationTypeEnums.TYPE_ALL);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationTypeNameEnums.TYPE_ALL_DISPLAY}
                    </StyledToggleButtonShort>
                    <StyledToggleButtonShort
                        isActive={typeValue === FilterNotificationTypeEnums.TYPE_REQUEST_OT}
                        onClick={() => {
                            setTypeValue(FilterNotificationTypeEnums.TYPE_REQUEST_OT);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationTypeNameEnums.TYPE_REQUEST_OT_DISPLAY}
                    </StyledToggleButtonShort>
                    <StyledToggleButtonShort
                        isActive={typeValue === FilterNotificationTypeEnums.TYPE_REQUEST_TIMESHEET}
                        onClick={() => {
                            setTypeValue(FilterNotificationTypeEnums.TYPE_REQUEST_TIMESHEET);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationTypeNameEnums.TYPE_REQUEST_TIMESHEET_DISPLAY}
                    </StyledToggleButtonShort>
                    <StyledToggleButtonShort
                        isActive={typeValue === FilterNotificationTypeEnums.TYPE_RESERVATION}
                        onClick={() => {
                            setTypeValue(FilterNotificationTypeEnums.TYPE_RESERVATION);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationTypeNameEnums.TYPE_RESERVATION_DISPLAY}
                    </StyledToggleButtonShort>
                    <StyledToggleButtonShort
                        isActive={typeValue === FilterNotificationTypeEnums.TYPE_OTHERS}
                        onClick={() => {
                            setTypeValue(FilterNotificationTypeEnums.TYPE_OTHERS);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationTypeNameEnums.TYPE_OTHERS_DISPLAY}
                    </StyledToggleButtonShort>
        
         </div>

         <div style={{ textAlign: 'left', marginTop: '0.5rem', marginLeft: '5px'}}><strong>ステータス:</strong></div>
                
         <div className="form__buttons">
                    <StyledToggleButtonShort
                        isActive={radioValue === FilterNotificationStatusEnums.ENUMS_STATUS_ALL}
                        onClick={() => {
                            setRadioValue(FilterNotificationStatusEnums.ENUMS_STATUS_ALL);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationEnums.ENUMS_FILTER_ALL}
                    </StyledToggleButtonShort>
                    <StyledToggleButtonShort
                        isActive={radioValue === FilterNotificationStatusEnums.ENUMS_STATUS_UNREAD}
                        onClick={() => {
                            setRadioValue(FilterNotificationStatusEnums.ENUMS_STATUS_UNREAD);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationEnums.ENUMS_FILTER_UNREAD}
                    </StyledToggleButtonShort>
                    <StyledToggleButtonShort
                        isActive={radioValue === FilterNotificationStatusEnums.ENUMS_STATUS_READ}
                        onClick={() => {
                            setRadioValue(FilterNotificationStatusEnums.ENUMS_STATUS_READ);
                            setPage(1);
                        }}
                    >
                        {FilterNotificationEnums.ENUMS_FILTER_READ}
                    </StyledToggleButtonShort>
            
            
          </div>

          {/* <div className="form__checkbox"> */}
          {/* <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={radioValue} onChange={handleRadioChange}>
              <FormControlLabel value={3} className={clsx(radioValue === 3 ? "active" : "")} control={<Radio />} label={FilterNotificationEnums.ENUMS_FILTER_ALL} />
              <FormControlLabel value={0} control={<Radio />} label={FilterNotificationEnums.ENUMS_FILTER_UNREAD} className={clsx(radioValue === 0 ? "active" : "")} />
              <FormControlLabel value={1} className={clsx(radioValue === 1 ? "active" : "")} control={<Radio />} label={FilterNotificationEnums.ENUMS_FILTER_READ} />
            </RadioGroup> */}
          {/* </div> */}
        </div>


        <div>
          {(selectedNotifications.length > 0 || true) && (
            <span onClick={handleDeleteMultipleNoti}
              style={{ display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "1rem", filter: selectedNotifications.length === 0 ? "brightness(0.5) grayscale(1)" : "" }}
            >
              <NextButton>
                選択した通知を削除
              </NextButton>
            </span>

          )
          }
          <div style={{ display: "flex", alignItems: "center", paddingTop: "1rem" }}>

            <div
              style={{
                margin: "0px 0px 10px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  paddingTop: "5px",
                  width: "15px",
                  height: "15px",
                  cursor: "pointer",
                }}
                type="checkbox"
                checked={isSelected}
                onChange={handleSelectAll}
              />
              <div style={{ marginLeft: "10px" }}>全てを選択</div>
            </div>

          </div>

        </div>

      </div>


      <div className="noti" style={{ marginTop: '2rem' }}>
        <div className="noti__wrapper">
          <div className="noti__content">
            {notifications.listNoti?.length
              ? notifications.listNoti.map((noti, index) => (
                <div key={index}>
                  <NotificationItem
                    handleOnSelectNoti={handleOnSelectNoti}
                    data={noti}
                    handleDeleteNoti={() => handleDeleteNoti(noti.id)}
                    isSelected={selectedNotifications.includes(noti.id)}
                    handleToggleSelect={() => handleToggleSelectNoti(noti.id)}
                  />
                </div>
              ))
              : !loading && <p className="messageNotFound">{MESSAGE_DATA_NOT_FOUND}</p>}
          </div>
        </div>
      </div>
      {/* <div className="wrapperLoading">
                <CircularLoader loading={loading} size={15} type="fullContent" />
            </div> */}
      {!loading && loadMore && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
          <span className="viewMore__text" onClick={() => handleLoadMore()}>
            <NextButton>
              {/* <img className="viewMore__icon" src={active_arrow_down} alt="icon" onClick={() => handleLoadMore()} /> */}
              ↓続きを見る
            </NextButton>
          </span>
        </div>
      )}
    </StyledNotificationComponent>
  );
};

// const DeleteMultiButton = styled.button`
//     margin: 10px 0px;
//     padding: 10px;
//     background-color: black;
//     color: white;
//     text-align: center;
//     border-radius: 10px;
//     cursor: pointer;
// `;
