/* eslint-disable no-console */
// import { Box } from "@mui/system";
import styled from "styled-components";

import {
  LABEL_CONTRACTORS,
  LABEL_CONTRACTOR_AMOUNT,
  LABEL_LIVELET_EMPLOYEE,
  LABEL_START_TIME,
  LABEL_STOP_TIME,
} from "@configs";
import { formatDate } from "@utils";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  AppCustomeSelect,
  AppCustomeSelectLiveLetEmployee,
  AppInput,
  AppSelect,
  AppTextArea,
  AppTimePicker,
} from "src/Components/Common";
import { useTimeSheetContext } from "../../context";
import { TimeSheetNightShiftFormSchemaType } from "../../validation";
import { ContractorItemFormWidget } from "./contractor-item-form.widget";
import { Divider } from "@mui/material";
import { LiveLetEmployeeItemFormWidget } from "./form-livelet-employee";
import useContractors from "src/Utils/Hooks/useContractors";

dayjs.extend(customParseFormat);

type TypeOfWorkType = "type_1" | "type_2" | "type_3";

export type WorkTypeResponse = {
  [key in TypeOfWorkType]: {
    id: number;
    name: string;
    type: number;
  }[];
};

interface SiteConstructionsFormWidgetProps {
  optionSiteConstructions: any[];
}

export function SiteConstructionsFormWidget({
  optionSiteConstructions,
}: SiteConstructionsFormWidgetProps) {
  const form = useFormContext<TimeSheetNightShiftFormSchemaType>();

  const { optionWeather, optionContractors, workType, liveletEmployees } = useTimeSheetContext();

  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = form;

  const { fields } = useFieldArray({
    control,
    name: "list_constructions",
  });
  const watchContractors = useWatch({ name: `list_constructions` });
  const watchEmployee = useWatch({ name: `list_constructions` });
  const { updateContractorHistory } = useContractors();
  return (
    <StyledSiteConstruction>
      {fields.map((field, index) => {
        if (!watchContractors) return;
        const contractorsSelect = watchContractors[index].contractors.map(
          (item) => item.contractor_id
        );
        const contractorValueSelect = optionContractors?.filter((optionContractor) =>
          contractorsSelect.some((contractor) => optionContractor.value === contractor)
        );

        const constructionMatch = optionSiteConstructions?.find(
          (construction) => construction.value === watchContractors[index].construction_id
        );

        const liveletEmployeeSelect = watchEmployee[index]?.livelet_employee?.map((item) =>
          item.employee_id === 0 ? item.employee_name : item.employee_id
        );
        const liveletEmployeeValueSelect = liveletEmployees?.filter(
          (optionliveletEmployee) =>
            liveletEmployeeSelect?.some(
              (liveletEmployee) => optionliveletEmployee.value === liveletEmployee
            )
        );
        return (
          <div key={field.id} className="siteDetail">
            <Divider
              style={{ marginBottom: "5px", backgroundColor: "black", height: "3px" }}
            />
            <div className="all__input">
              <p className="siteDetail__title" >
                現場名: {constructionMatch?.label}
              </p>
              <div className="siteDetail__startAndEnd">
                <div>
                  <label className="label">{LABEL_START_TIME}</label>
                  <Controller
                    name={`list_constructions.${index}.start_at`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      const formatValue = dayjs(value, "HH:mm").toDate();
                      return (
                        <>
                          <AppTimePicker
                            value={formatValue}
                            handleChange={(value) => {
                              const formatValue = formatDate({
                                date: value,
                                format: "HH:mm",
                              });
                              onChange(formatValue);
                            }}
                          />
                          {error && (
                            <p
                              style={{
                                color: "#FF597B",
                                fontSize: "13px",
                                margin: "8px",
                              }}
                            >
                              {error?.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
                <div>
                  <label className="label">{LABEL_STOP_TIME}</label>
                  <Controller
                    name={`list_constructions.${index}.stop_at`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      const formatValue = dayjs(value, "HH:mm").toDate();
                      return (
                        <>
                          <AppTimePicker
                            value={formatValue}
                            handleChange={(value) => {
                              const formatValue = formatDate({
                                date: value,
                                format: "HH:mm",
                              });

                              onChange(formatValue);
                            }}
                          />
                          {error && (
                            <p
                              style={{
                                color: "#FF597B",
                                fontSize: "13px",
                                margin: "8px",
                              }}
                            >
                              {error?.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              {/* <Box sx={{ width: "50%" }}> */}
              <div className="siteDetail__contentWithBorder">
                <div className="siteDetail__contentChangeSize">
                  <label className="label">休憩 (hour)</label>
                  <Controller
                    name={`list_constructions.${index}.break_time`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <AppInput
                          isNumber
                          value={String(value)}
                          handleChange={(value) => {
                            onChange(Number(value));
                          }}
                        />
                        {error && (
                          <p
                            style={{
                              color: "#FF597B",
                              fontSize: "13px",
                              margin: "8px",
                            }}
                          >
                            {error?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
                {/* </Box> */}
              </div>
              <div className="siteDetail__contentWithBorder">
                <div className="siteDetail__contentChangeSize">
                  <label className="label">天気</label>
                  {optionWeather && (
                    <Controller
                      name={`list_constructions.${index}.weather_id`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <>
                          <AppSelect
                            value={value}
                            options={optionWeather}
                            handleChange={(value, action) => {
                              onChange(String(value));
                              if (action === "clear") {
                                onChange("");
                              }
                            }}
                            isClearable
                            placeholder="Select 天気"
                          />
                          {error && (
                            <p
                              style={{
                                color: "#FF597B",
                                fontSize: "13px",
                                margin: "8px",
                              }}
                            >
                              {error?.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="siteDetail__contentWithBorder">
                <div className="siteDetail__contentChangeSize">
                  <label className="label">メモ(報告事項)</label>
                  <Controller
                    name={`list_constructions.${index}.memo`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        <AppTextArea value={value} handleChange={onChange} />
                        {error && (
                          <p
                            style={{
                              color: "#FF597B",
                              fontSize: "13px",
                              margin: "8px",
                            }}
                          >
                            {error?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              {/* minh start code */}
              <div className="siteDetail__contentWithBorder">
                <label className="label">{LABEL_LIVELET_EMPLOYEE}</label>
                <div className="select__contractor">
                  <div className="siteDetail__contentChangeSize">
                    <AppCustomeSelectLiveLetEmployee
                      options={liveletEmployees}
                      placeholder="select LiveLet社員"
                      value={liveletEmployeeValueSelect}
                      onChange={(values) => {
                        clearErrors(
                          `list_constructions.${index}.livelet_employee`
                        );
                        const listContractors = getValues(
                          `list_constructions.${index}.livelet_employee`
                        );
                        if (values.length > listContractors.length) {
                          const checkValue =
                            values[values.length - 1].value;
                          setValue(
                            `list_constructions.${index}.livelet_employee`,
                            [
                              ...listContractors,
                              {
                                employee_id:
                                  typeof checkValue === "string"
                                    ? 0
                                    : checkValue,
                                employee_name:
                                  values[values.length - 1].value,
                                employee_memo: "",
                              },
                            ]
                          );
                          return;
                        }
                        const valueSelect = listContractors.find(
                          ({ employee_id }) =>
                            !values.some(
                              ({ value }) => employee_id === value
                            )
                        );
                        const valueListContractors = listContractors.filter(
                          (employee) =>
                            employee.employee_id !==
                            valueSelect?.employee_id
                        );
                        setValue(
                          `list_constructions.${index}.livelet_employee`,
                          valueListContractors
                        );
                      }}
                      isMulti
                    />
                    {errors.list_constructions &&
                      errors.list_constructions[index]?.livelet_employee && (
                        <p
                          style={{
                            color: "#FF597B",
                            fontSize: "13px",
                            margin: "8px",
                          }}
                        >
                          {
                            errors.list_constructions[index]
                              ?.livelet_employee?.message
                          }
                        </p>
                      )}
                  </div>
                </div>
                {Object.keys(workType).length && (
                  <LiveLetEmployeeItemFormWidget
                    nestIndex={index}
                  // workType={workType}
                  />
                )}
              </div>
              {/* minh end code */}
              <div className="siteDitail__contentWithBorder">
                <label className="label">{LABEL_CONTRACTORS}</label>
                <div className="select__contractor">
                  <div className="siteDetail__contentChangeSize">
                    <AppCustomeSelect
                      options={optionContractors}
                      placeholder="select 請負業者"
                      value={contractorValueSelect}
                      onChange={(value) => {
                        updateContractorHistory(value);
                        // setValueContractors(values);
                        clearErrors(
                          `list_constructions.${index}.contractors`
                        );
                        const listContractors = getValues(
                          `list_constructions.${index}.contractors`
                        );

                        if (value.length > listContractors.length) {
                          setValue(
                            `list_constructions.${index}.contractors`,
                            [
                              ...listContractors,
                              {
                                contractor_id:
                                  value[value.length - 1].value,
                                number_of_people: 1,
                                work_type_1: [],
                                work_type_2: [],
                                work_type_3: [],
                              },
                            ]
                          );
                          return;
                        }

                        const valueSelect = listContractors.find(
                          ({ contractor_id }) =>
                            !value.some(
                              ({ value }) => contractor_id === value
                            )
                        );

                        const valueListContractors = listContractors.filter(
                          (contractor) =>
                            contractor.contractor_id !==
                            valueSelect?.contractor_id
                        );

                        setValue(
                          `list_constructions.${index}.contractors`,
                          valueListContractors
                        );
                      }}
                      isMulti
                    />
                    {errors.list_constructions &&
                      errors.list_constructions[index]?.contractors && (
                        <p
                          style={{
                            color: "#FF597B",
                            fontSize: "13px",
                            margin: "8px",
                          }}
                        >
                          {
                            errors.list_constructions[index]
                              ?.contractors?.message
                          }
                        </p>
                      )}
                  </div>
                  <p className="select__number">
                    <span>{LABEL_CONTRACTOR_AMOUNT}</span>
                    <span>
                      {watchContractors &&
                        watchContractors[index].contractors.reduce(
                          (total, currentValue) => {
                            total += Number(
                              currentValue.number_of_people
                            );
                            return total;
                          },
                          0
                        )}
                    </span>
                  </p>
                </div>
                {Object.keys(workType).length && (
                  <ContractorItemFormWidget
                    nestIndex={index}
                    workType={workType}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </StyledSiteConstruction>
  );
}


export const StyledSiteConstruction = styled.div`
  .siteDetail {

    &__contentWithBorder {
      padding-bottom:1.5rem;
      border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};

    }

    &__contentChangeSize {
      width:70%;

      @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
          width:100%;
        }

    }


    &__startAndEnd {
      display:flex;
      gap:5rem;
      padding-bottom:1.5rem;
      border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};

       @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
          flex-direction:column;
          gap:0;
        }
    }


    &__title {
      font-size:16px;
      font-weight:900;

    }
  }



`;
