export const japaneseHolidays = [
    { date: "2023-01-01", english: "New Year's Day", japanese: "元日" },
    { date: "2023-01-02", english: "New Year's Day (Observed)", japanese: "元日（祝日）" },
    { date: "2023-01-09", english: "Coming of Age Day", japanese: "成人の日" },
    { date: "2023-02-11", english: "National Foundation Day", japanese: "建国記念の日" },
    { date: "2023-02-23", english: "Emperor's Birthday", japanese: "天皇誕生日" },
    { date: "2023-03-21", english: "Vernal Equinox Day", japanese: "春分の日" },
    { date: "2023-04-29", english: "Showa Day", japanese: "昭和の日" },
    { date: "2023-05-03", english: "Constitution Memorial Day", japanese: "憲法記念日" },
    { date: "2023-05-04", english: "Greenery Day", japanese: "みどりの日" },
    { date: "2023-05-05", english: "Children's Day", japanese: "こどもの日" },
    { date: "2023-07-17", english: "Marine Day", japanese: "海の日" },
    { date: "2023-08-11", english: "Mountain Day", japanese: "山の日" },
    { date: "2023-09-18", english: "Respect for the Aged Day", japanese: "敬老の日" },
    { date: "2023-09-23", english: "Autumnal Equinox Day", japanese: "秋分の日" },
    { date: "2023-10-09", english: "Sports Day", japanese: "スポーツの日" },
    { date: "2023-11-03", english: "Culture Day", japanese: "文化の日" },
    { date: "2023-11-23", english: "Labor Thanksgiving Day", japanese: "勤労感謝の日" },
  
    { date: "2024-01-01", english: "New Year's Day", japanese: "元日" },
    { date: "2024-01-08", english: "Coming of Age Day", japanese: "成人の日" },
    { date: "2024-02-11", english: "National Foundation Day", japanese: "建国記念の日" },
    { date: "2024-02-12", english: "National Foundation Day (Observed)", japanese: "建国記念の日（祝日）" },
    { date: "2024-02-23", english: "Emperor's Birthday", japanese: "天皇誕生日" },
    { date: "2024-03-20", english: "Vernal Equinox Day", japanese: "春分の日" },
    { date: "2024-04-29", english: "Showa Day", japanese: "昭和の日" },
    { date: "2024-05-03", english: "Constitution Memorial Day", japanese: "憲法記念日" },
    { date: "2024-05-04", english: "Greenery Day", japanese: "みどりの日" },
    { date: "2024-05-05", english: "Children's Day", japanese: "こどもの日" },
    { date: "2024-05-06", english: "Children's Day (Observed)", japanese: "こどもの日（祝日）" },
    { date: "2024-07-15", english: "Marine Day", japanese: "海の日" },
    { date: "2024-08-11", english: "Mountain Day", japanese: "山の日" },
    { date: "2024-08-12", english: "Mountain Day (Observed)", japanese: "山の日（祝日）" },
    { date: "2024-09-16", english: "Respect for the Aged Day", japanese: "敬老の日" },
    { date: "2024-09-22", english: "Autumnal Equinox Day", japanese: "秋分の日" },
    { date: "2024-10-14", english: "Sports Day", japanese: "スポーツの日" },
    { date: "2024-11-03", english: "Culture Day", japanese: "文化の日" },
    { date: "2024-11-04", english: "Culture Day (Observed)", japanese: "文化の日（祝日）" },
    { date: "2024-11-23", english: "Labor Thanksgiving Day", japanese: "勤労感謝の日" },
  
    { date: "2025-01-01", english: "New Year's Day", japanese: "元日" },
    { date: "2025-01-13", english: "Coming of Age Day", japanese: "成人の日" },
    { date: "2025-02-11", english: "National Foundation Day", japanese: "建国記念の日" },
    { date: "2025-02-23", english: "Emperor's Birthday", japanese: "天皇誕生日" },
    { date: "2025-03-20", english: "Vernal Equinox Day", japanese: "春分の日" },
    { date: "2025-04-29", english: "Showa Day", japanese: "昭和の日" },
    { date: "2025-05-03", english: "Constitution Memorial Day", japanese: "憲法記念日" },
    { date: "2025-05-04", english: "Greenery Day", japanese: "みどりの日" },
    { date: "2025-05-05", english: "Children's Day", japanese: "こどもの日" },
    { date: "2025-07-21", english: "Marine Day", japanese: "海の日" },
    { date: "2025-08-11", english: "Mountain Day", japanese: "山の日" },
    { date: "2025-09-15", english: "Respect for the Aged Day", japanese: "敬老の日" },
    { date: "2025-09-23", english: "Autumnal Equinox Day", japanese: "秋分の日" },
    { date: "2025-10-13", english: "Sports Day", japanese: "スポーツの日" },
    { date: "2025-11-03", english: "Culture Day", japanese: "文化の日" },
    { date: "2025-11-23", english: "Labor Thanksgiving Day", japanese: "勤労感謝の日" },
    { date: "2025-11-24", english: "Labor Thanksgiving Day (Observed)", japanese: "勤労感謝の日（祝日）" },
  
    { date: "2026-01-01", english: "New Year's Day", japanese: "元日" },
    { date: "2026-01-12", english: "Coming of Age Day", japanese: "成人の日" },
    { date: "2026-02-11", english: "National Foundation Day", japanese: "建国記念の日" },
    { date: "2026-02-23", english: "Emperor's Birthday", japanese: "天皇誕生日" },
    { date: "2026-03-20", english: "Vernal Equinox Day", japanese: "春分の日" },
    { date: "2026-04-29", english: "Showa Day", japanese: "昭和の日" },
    { date: "2026-05-03", english: "Constitution Memorial Day", japanese: "憲法記念日" },
    { date: "2026-05-04", english: "Greenery Day", japanese: "みどりの日" },
    { date: "2026-05-05", english: "Children's Day", japanese: "こどもの日" },
    { date: "2026-07-20", english: "Marine Day", japanese: "海の日" },
    { date: "2026-08-11", english: "Mountain Day", japanese: "山の日" },
    { date: "2026-09-21", english: "Respect for the Aged Day", japanese: "敬老の日" },
    { date: "2026-09-23", english: "Autumnal Equinox Day", japanese: "秋分の日" },
    { date: "2026-10-12", english: "Sports Day", japanese: "スポーツの日" },
    { date: "2026-11-03", english: "Culture Day", japanese: "文化の日" },
    { date: "2026-11-23", english: "Labor Thanksgiving Day", japanese: "勤労感謝の日" },
  
    { date: "2027-01-01", english: "New Year's Day", japanese: "元日" },
    { date: "2027-01-11", english: "Coming of Age Day", japanese: "成人の日" },
    { date: "2027-02-11", english: "National Foundation Day", japanese: "建国記念の日" },
    { date: "2027-02-23", english: "Emperor's Birthday", japanese: "天皇誕生日" },
    { date: "2027-03-20", english: "Vernal Equinox Day", japanese: "春分の日" },
    { date: "2027-04-29", english: "Showa Day", japanese: "昭和の日" },
    { date: "2027-05-03", english: "Constitution Memorial Day", japanese: "憲法記念日" },
    { date: "2027-05-04", english: "Greenery Day", japanese: "みどりの日" },
    { date: "2027-05-05", english: "Children's Day", japanese: "こどもの日" },
    { date: "2027-07-19", english: "Marine Day", japanese: "海の日" },
    { date: "2027-08-11", english: "Mountain Day", japanese: "山の日" },
    { date: "2027-09-20", english: "Respect for the Aged Day", japanese: "敬老の日" },
    { date: "2027-09-23", english: "Autumnal Equinox Day", japanese: "秋分の日" },
    { date: "2027-10-11", english: "Sports Day", japanese: "スポーツの日" },
    { date: "2027-11-03", english: "Culture Day", japanese: "文化の日" },
    { date: "2027-11-23", english: "Labor Thanksgiving Day", japanese: "勤労感謝の日" },
  ];
  