import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { Grid } from "@mui/material";

// import { MessageIcon, LockIcon, SettingIcon, CakeIcon, BloodIcon } from "@assets";
import { LogoutButton, PassChangeButton, StyledProfileView } from ".";
import { AppButton, AppModal, StyledPDF, StyledIframe } from "..";
// import arrow_next from "@assets/Icons/arrow_next.svg";
// import logout_icon from "@assets/Icons/logout_icon.svg";
import default_avatar from "@assets/Images/default_avatar.jpg";
import { authRouteEnums } from "@enums";
import {
  DOCUMENT_EXCEL_FAILED_TO_LOAD,
  DOCUMENT_FAILED_TO_LOAD,
  ERROR_SIZE_MORE_THAN_10MB,
  LIMTED_10MB,
  MESSAGE_LOADING_PDF,
} from "@configs";
import {
  logout,
  useAppSelector,
  selectAuth,
  updateAvatar,
  updateSnackBar,
  getInfo,
  getListGlobalDocument,
} from "@redux";
import {
  converNumberToArrayOfInterger,
  formatDateOfBirth,
  isLinkExel,
  linkDocument,
  linkImage,
  theme,
  useWindowDimensions,
} from "@utils";
import { CircularLoader } from "..";
import { notiApi } from "@api";
import { technicalDocumentsApi } from "src/Api/technicalDocuments";
import { saveAs } from "file-saver";
import styled from "styled-components";

import ChangeIcon from "@assets/change-circle.svg";
import EmailIcon from "@assets/emailIcon.png";
import Cake2Icon from "@assets/cakeIcon.png";
import BloodtypeIcon from "@assets/bloodtypeIcon.png";
import PassIcon from "@assets/passIcon.png";
import LogoutIcon from "@assets/logoutIcon.png";


export const ProfileView = () => {
  const auth = useAppSelector(selectAuth);
  const { userInfo, isLoading, data } = auth;
  const notiID = auth?.notiId;
  const [loading, setLoading] = React.useState(false);
  const [openPDF, setOpenPDF] = React.useState<boolean>(false);
  const [linkPdf, setLinkPdf] = React.useState<any>("");
  const [numPages, setNumPages] = React.useState(null);
  const [technicalDocuments, setTechnicalDocuments] = React.useState<any>(undefined);
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const width = useWindowDimensions().width;
  const fetchDeleteNotiId = async () => {
    setLoading(true);
    try {
      const res = await notiApi.deleteTokenDevice({ id: notiID });
      if (res.data.success) {
        dispatch(logout({ dispatch }));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    dispatch(getInfo({ dispatch }));
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getListGlobalDocument({ dispatch }));
  }, [dispatch]);
  // events
  const fetchTechnicalDocuments = async () => {
    try {
      setLoading(true);
      const res = await technicalDocumentsApi.getTechnicalDocumentsProfile();
      setLoading(false);
      setTechnicalDocuments(res.data.data.documents);
    } catch (err) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchTechnicalDocuments();
    return () => {
      //    do something cleanup
    };
  }, [data]);

  const handleOnSelectTechnicalDocuments = async (path: any, title: any) => {
    try {
      if (path.endsWith(".xlsx")) {
        const response = await fetch(path);
        const blob = await response.blob();
        saveAs(blob, `${title}.xlsx`);
      } else {
        handleOpenPDF(path);
      }
    } catch (error) { }
  };
  const onChangeAvatar = (e: any) => {
    if (e.target.files[0].size > LIMTED_10MB) {
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: ERROR_SIZE_MORE_THAN_10MB,
        })
      );
      return false;
    }
    const bodyFormData = new FormData();
    bodyFormData.append("image", e.target.files[0]);
    dispatch(
      updateAvatar({
        dispatch,
        formData: bodyFormData,
      })
    );
  };
  const handleLogout = () => {
    if (!isLoading) {
      if (notiID) {
        fetchDeleteNotiId();
      } else {
        dispatch(logout({ dispatch }));
      }
    }
  };
  const handleOpenPDF = (value: any) => {
    //  window.location.href = '';
    if (width <= 980) {
      window.open(linkDocument(value));
    }
    setLinkPdf(value);
    setOpenPDF(true);
  };
  const handleClosePDF = () => {
    setOpenPDF(false);
    setLinkPdf("");
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <StyledProfileView>
      <CircularLoader loading={loading || isLoading} />
      {width >= 981 && (
        <AppModal open={openPDF} handleClose={handleClosePDF}>
          <StyledPDF>
            {linkPdf ? (
              isLinkExel(linkPdf) ? (
                <p className="failePDF">{DOCUMENT_EXCEL_FAILED_TO_LOAD}</p>
              ) : width >= 991 ? (
                <StyledIframe
                  heightCalculationMethod="lowestElement"
                  src={linkDocument(linkPdf)}
                  log
                  scrolling={true}
                  autoResize={false}
                />
              ) : (
                <Document
                  file={linkDocument(linkPdf)}
                  options={{ workerSrc: "/pdf.worker.js" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error={DOCUMENT_FAILED_TO_LOAD}
                  loading={MESSAGE_LOADING_PDF}
                >
                  {numPages &&
                    converNumberToArrayOfInterger(numPages).map(
                      (page, index) => <Page pageNumber={index} />
                    )}
                </Document>
              )
            ) : (
              <p className="failePDF">{DOCUMENT_FAILED_TO_LOAD}</p>
            )}
          </StyledPDF>
        </AppModal>
      )}

      <div className="profile">

        <div className="profile__card">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={6} >
              <div className="profile__left">

                <div className="profile__left-photo">

                  <div className="profile__photo-container">

                    <img
                      className="profile__avatar"
                      src={
                        userInfo?.avatar?.path
                          ? linkImage(userInfo?.avatar?.path)
                          : default_avatar
                      }

                      alt="avatar"
                      style={{ width: "120px", height: "120px" }}
                    />

                    <label
                      htmlFor="contained-button-file"
                      className="profile__changebutton"
                    >
                      <img src={ChangeIcon} alt="change image" className="downloadIcon" style={{ width: "50px", height: "50px", transform: "translateY(15px)" }} />
                    </label>
                    <input
                      name="images"
                      accept=" image/png, image/jpeg"
                      className="profile__inputUpload"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={onChangeAvatar}
                    />

                  </div>

                  <div className="profile__name-container">

                    <span className="profile__userInfo-name">
                      <h3 className="firstName">{userInfo?.last_name}</h3>
                      <h3 className="lastName">{userInfo?.first_name}</h3>
                    </span>
                    <p className="profile__userInfo-id">従業員ID: {userInfo?.employee_id}</p>

                  </div>

                </div>

                <div className="profile__details">
                  <div className="profile__small-items">
                    <img src={EmailIcon} className="profile__icons" alt="email-icon" />
                    <span className="profile__item-text">メールアドレス</span>
                  </div>
                  <span className="profile__item-text email">
                    <strong>{userInfo?.email}</strong>
                  </span>
                </div>


                <div className="profile__details">
                  <div className="profile__small-items">
                    <img src={Cake2Icon} className="profile__icons" alt="birthday-icon" />
                    <span className="profile__item-text">生年月日</span>

                  </div>
                  <span className="profile__item-text">
                    <strong>{formatDateOfBirth(userInfo?.date_of_birth)}</strong>
                  </span>
                </div>

                <div className="profile__details">
                  <div className="profile__small-items">
                    <img src={BloodtypeIcon} className="profile__icons" alt="bloodtype-icon" />
                    <span className="profile__item-text">血液型</span>
                  </div>
                  <span className="profile__item-text"><strong>{userInfo?.blood}</strong></span>
                </div>

                <div className="profile__details-last">
                  <div className="profile__small-items-last">
                    <img src={PassIcon} className="profile__icons" alt="passkey-icon" />
                    <span className="profile__item-text">パスワード</span>
                  </div>

                  <span
                    onClick={() => history.push(authRouteEnums.PATH_CHANGEPASSORD)}
                  >
                    <PassChangeButton>パスワードを変更する</PassChangeButton>
                  </span>
                </div>

                <div className="profile__logout">
                  <span onClick={handleLogout}>
                    <LogoutButton>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={LogoutIcon} alt="logout" className="logoutIcon" />
                        <span>
                          ログアウト
                        </span>
                      </div>
                    </LogoutButton>

                  </span>

                </div>

              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} >
              <div className="profile__right">
                <StyledContainDocument>
                  {data?.length ? (
                    data.map((file) => (
                      <Grid item xs={12} sx={{ mb: 1 }}>
                        <AppButton
                          text={file.title}
                          onClick={() => handleOpenPDF(file.path)}
                          bgColor={theme.colors.yellow08}
                          txColor={theme.colors.black01}
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                  {technicalDocuments ? (
                    technicalDocuments.map((file, index) => (
                      <Grid
                        item
                        xs={12}
                        sx={{ mb: index === technicalDocuments.length - 1 ? 0 : 1 }}
                      >
                        <AppButton
                          text={file.title}
                          onClick={() =>
                            handleOnSelectTechnicalDocuments(
                              file?.path,
                              file?.title
                            )
                          }
                        />
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </StyledContainDocument>

              </div>
            </Grid>
          </Grid>
        </div>
      </div>





      {/* <div className="profile"> */}
      {/* <div className="profile__topbar"> */}
      {/* <AppTopBar screen={screenNameEnums.SCREEN_PROFILE} /> */}
      {/* </div> */}
      {/* <div className="profile__content">
          <div className="profile__userInfo">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <div className="profile__userInfo-imageWrapper">
                <img
                  className="image"
                  src={
                    userInfo?.avatar?.path
                      ? linkImage(userInfo?.avatar?.path)
                      : default_avatar
                  }
                  alt="avatar"
                />
              </div>
            </Box>
            <Box sx={{ padding: "20px 0" }}>
              <label
                htmlFor="contained-button-file"
                className="profile__userInfo-edit"
              >
                画像を変更する
              </label>
            </Box> */}
      {/* <input
              name="images"
              accept=" image/png, image/jpeg"
              className="profile__inputUpload"
              id="contained-button-file"
              multiple
              type="file"
              onChange={onChangeAvatar}
            />
            <p className="profile__userInfo-name">
              <span className="firstName">{userInfo?.last_name}</span>
              <span className="lastName">{userInfo?.first_name}</span>
            </p>
            <p className="profile__userInfo-id">従業員ID: {userInfo?.employee_id}</p>
          </div>
          <div className="profile__items">
            <Grid container>
              <Grid item xs={12}>
                <div className="profile__item">
                  <IconButton component="span" disableRipple={true}>
                    <MessageIcon />
                  </IconButton>
                  <span className="profile__item-text email">
                    {userInfo?.email}
                  </span>
                </div>
              </Grid> */}
      {/* <Grid item xs={8}>
                <div className="profile__item item__date">
                  <IconButton component="span" disableRipple={true}>
                    <CakeIcon />
                  </IconButton>
                  <span className="profile__item-text">
                    {formatDateOfBirth(userInfo?.date_of_birth)}
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="profile__item">
                  <IconButton component="span" disableRipple={true}>
                    <BloodIcon />
                  </IconButton>
                  <span className="profile__item-text">{userInfo?.blood}</span>
                </div>
              </Grid> */}
      {/* {organiztionChart && (
                                <Grid item xs={12}>
                                    <AppButton
                                        text={VIEW_PDF}
                                        onClick={() => handleOpenPDF(organiztionChart?.file?.path)}
                                    />
                                </Grid>
                            )} */}


      {/* <StyledContainDocument>
                {data?.length ? (
                  data.map((file) => (
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <AppButton
                        text={file.title}
                        onClick={() => handleOpenPDF(file.path)}
                      />
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
                {technicalDocuments ? (
                  technicalDocuments.map((file, index) => (
                    <Grid
                      item
                      xs={12}
                      sx={{ mb: index === technicalDocuments.length - 1 ? 0 : 1 }}
                    >
                      <AppButton
                        text={file.title}
                        onClick={() =>
                          handleOnSelectTechnicalDocuments(
                            file?.path,
                            file?.title
                          )
                        }
                      />
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </StyledContainDocument>
              {!!userInfo?.qualifications?.length && (
                <Grid item xs={12}>
                  <div className="profile__item">
                    <IconButton component="span" disableRipple={true}>
                      <SettingIcon />
                    </IconButton> */}

      {/* <div className="profile__item-listCertis">
                      {userInfo?.qualifications?.map((item) => (
                        <span
                          key={item?.id}
                          className="profile__item-certi"
                        >
                          {item?.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </Grid>
              )} */}

      {/* <Grid item xs={12}>
                <div
                  className="profile__btn"
                  onClick={() => history.push(authRouteEnums.PATH_CHANGEPASSORD)}
                >
                  <IconButton component="span" disableRipple={true}>
                    <LockIcon color="#FF9D43" />
                  </IconButton>
                  <span className="profile__btn-text">パスワードを変更する</span>
                  <div className="profile__btn-next">
                    <img src={arrow_next} alt="->" />
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="profile__btnLogout">
              <span className="btn" onClick={handleLogout}>
                <img src={logout_icon} alt="logout" />
              </span>
              <p className="text" onClick={handleLogout}>
                ログアウト
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </StyledProfileView>
  );
};

export const StyledContainDocument = styled.div`
    width: 100%;
    // max-height: 230px;
    overflow-y: scroll !important;
`;
