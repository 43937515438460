import styled from "styled-components";
// import inventory_bg from "@assets/Background/inventory_bg.png";

export const StyledTechnicalDocumentsComponent = styled.div`
    padding-bottom: 4rem;
    // background-repeat: no-repeat;
    min-height: 100vh;
    border-top: 1px solid #f0f0f0;
    background-color:${(p) => p.theme.colors.white02};
    padding:1rem 1rem;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding:2rem 2rem;
    }
    .top-icon{
        text-align: left;
    }
    .wrapperForm {
        width: 100%;
        // padding: 0rem 1.5rem;
        padding-bottom:2rem;
        border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            border-bottom:none;

        }
            margin-top:7rem;
        // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        //     padding: 0rem 11rem;
        // }
        // &-top{
        //     padding: 1rem 1.5rem;
        // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        //     padding: 1rem 8rem;
        // }
        // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        //     padding: 2rem 11rem 0rem 11rem;
        // }
        }
    }
    .MuiDivider-root {
        display: none;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display: block;
        }
    }

    .form {
        /* display: flex;
        flex-direction: column; */
        padding: 1.6rem 1rem;
        padding-bottom: 0;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            flex-direction: row;
            justify-content: center;
            min-width: 80rem;
            padding-left: 8rem;
            padding-right: 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding-left: 0;
            padding-right: 0;
        }
        &__search {
            padding: 1.6rem 0;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
        }
        &__checkbox {
            padding: 1.6rem 0;
            width: 100%;
            padding-left: 0.5rem;
            border-top: 1px solid #f0f0f0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                /* display: flex;
                justify-content: flex-end; */
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            width: 50%;
            display: flex;
            justify-content: flex-end;
        }
        .MuiFormGroup-root {
            flex-direction: row;
        }
        .MuiTypography-root {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.2rem;
            color: #d5d5d5;
        }
        .MuiSvgIcon-root {
            width: 1.5em;
            height: 1.5em;
        }
        .active {
            .MuiTypography-root {
                color: ${(p) => p.theme.colors.darkBlack} !important;
            }
        }
    }

    .noti {
        display: flex;
        justify-content: center;
        padidng-top: 2rem;
        // padding-left: 1rem;
        // padding-right: 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            // padding-left: 2rem;
            // padding-right: 2rem;
            padding-top: 4rem;
        }
        &__wrapper {
            width: 100%;
            // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            //     width: 100%;
            // }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            //     width: 100%;
            // }
            padding-bottom:12rem;
        }
        &__content {
            max-width: 80rem;
        }
    }
    .wrapperLoading {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
    }
    .viewMore {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 4rem;
        cursor: pointer;
        &__text {
            font-size: 1.6rem;
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => p.theme.colors.secondary};
            text-decoration: underline;
        }
        &__icon {
            margin-left: 0.5rem;
            padding-top: 0.5rem;
        }
    }
`;
export const StyledTechnicalDocumentsItem = styled.div`
    .noti__item {
        min-height:10rem;
        margin-bottom:1rem;
        // text-align: left;
        padding: 1rem 1rem;
        border-radius: 6px;
        background-color:${(p) => p.theme.colors.white00};
        // border-top: 1px solid #f0f0f0;
        // border-bottom: 1px solid #f0f0f0;
        transition: 250ms;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
         &:hover {
        background-color: ${(p) => p.theme.colors.blue06};
        }

        // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        //     justify-content: space-between;
        // }
        &-deleteIcon{
            width: 2rem;
            height: 2rem;
            &hover{
                cursor: pointer;
            }
        }
        &-icon {
            display: flex;
            justify-content: center;
        }
        &-content {
            // padding:0.5rem;
            // display: flex;
            // width: 100%;
            /* flex-direction: column; */
            /* justify-content: space-around; */
            /* align-items: stretch; */
            // padding-left: 1rem;
            // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            //     width: 90%;
            // }
        }
        &__items{
        display:flex;
        justify-content:space-between;


        }
        &-title {
            margin-left: 3rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            display: flex !important;
            // align-items: center;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin-left: 1rem;
        }
        }

        &-description {
            padding-top:1.5rem;
            font-size: 1.2rem;
            text-align:end;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-status {
            display: flex;
            align-items: center;
            padding:0 0.5rem;
        }
    }
    .icon {
        &__vehicle {
            background-color: #ffeedf !important;
        }
        &__instrument {
            background-color: #ffdff8 !important;
        }
        &__overtime {
            background-color: #d1e9ff !important;
        }
    }
`;
