import clsx from "clsx";
import { getDaysWeeksArrayMoment } from "@utils";
import { useHistory } from "react-router-dom";
import { appRouteEnums } from "@enums";
import { StyledTableWrapView } from "./styles";
import { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";

interface IProps {
data?: any;
date?: any;
}

const StickyHeaderContainer = styled.div<{ left: number; width: number }>`
  position: fixed;
  top: 78px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  z-index: 10;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
  @media (max-width: 768px) {
    top: 68px;
  }
`;


export const TableWrapView = (props: IProps) => {
const { data, date } = props;
const history = useHistory();


const headerRef = useRef<HTMLTableRowElement>(null);
const stickyHeaderContainerRef = useRef<HTMLDivElement>(null);
const tableContainerRef = useRef<HTMLDivElement>(null);
const [containerRect, setContainerRect] = useState({ left: 0, width: 0 });
const tableCloneRef = useRef<HTMLTableElement>(null);

const [showStickyHeader, setShowStickyHeader] = useState(false);


// sync horizontal scroll position
const syncHorizontalScroll = useCallback(() => {
  if (!tableContainerRef.current || !tableCloneRef.current) return;
  
  const scrollLeft = tableContainerRef.current.scrollLeft;
  
  // move the table inside the fixed container to sync horizontal scroll
  tableCloneRef.current.style.transform = `translateX(-${scrollLeft}px)`;
  
  // for each leftSticky cell cancel out the parent's translation
  const leftStickyCells = tableCloneRef.current.querySelectorAll('.leftSticky');
  leftStickyCells.forEach(cell => {
    (cell as HTMLElement).style.transform = `translateX(${scrollLeft}px)`;
  });
}, []);

useEffect(() => {
  if (!tableContainerRef.current) return;
  
  const updateRect = () => {
    const rect = tableContainerRef.current!.getBoundingClientRect();
    setContainerRect({ left: rect.left, width: rect.width });
  };

  updateRect();
  window.addEventListener('resize', updateRect);
  return () => window.removeEventListener('resize', updateRect);
}, []);

useEffect(() => {
  // when sticky header becomes visible, sync its horizontal scroll pos
  if (showStickyHeader && tableContainerRef.current && stickyHeaderContainerRef.current) {
    const stickyTable = stickyHeaderContainerRef.current.querySelector('table');
    if (stickyTable) {
      stickyTable.style.width = `${tableContainerRef.current.scrollWidth}px`;
    }
    
    syncHorizontalScroll();
  }
}, [showStickyHeader, syncHorizontalScroll]);

// detect when the header is out of view
useEffect(() => {
  if (!headerRef.current) return;
  
  const options = {
    rootMargin: "-110px 0px 0px 0px",
    threshold: 0
  };
  
  const observer = new IntersectionObserver((entries) => {
    const [entry] = entries;
    const isOutOfView = !entry.isIntersecting;
    
    if (isOutOfView !== showStickyHeader) {
      setShowStickyHeader(isOutOfView);
      
      if (isOutOfView) {
        setTimeout(() => {
          syncHorizontalScroll();
        }, 0);
      }
    }
  }, options);
  
  observer.observe(headerRef.current);
  
  return () => {
    observer.disconnect();
  };
}, [syncHorizontalScroll, showStickyHeader]);

useEffect(() => {
  const tableContainer = tableContainerRef.current;
  if (!tableContainer || !showStickyHeader) return;
  
  tableContainer.addEventListener('scroll', syncHorizontalScroll);
  
  return () => {
    tableContainer.removeEventListener('scroll', syncHorizontalScroll);
  };
}, [syncHorizontalScroll, showStickyHeader]);

useEffect(() => {
  if (!showStickyHeader || !tableContainerRef.current || !stickyHeaderContainerRef.current) return;

  const stickyTable = stickyHeaderContainerRef.current.querySelector('table');
  if (stickyTable) {
    stickyTable.style.width = `${tableContainerRef.current.scrollWidth}px`;
  }
  
  const handleResize = () => {
    if (stickyTable && tableContainerRef.current) {
      stickyTable.style.width = `${tableContainerRef.current.scrollWidth}px`;
      syncHorizontalScroll();
    }
  };
  
  window.addEventListener('resize', handleResize);
  
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [showStickyHeader, syncHorizontalScroll]);

const daysWeeksArray = getDaysWeeksArrayMoment(date);

return (
  <StyledTableWrapView ref={tableContainerRef}>
    {/* header clone */}
    {showStickyHeader && (
      <StickyHeaderContainer
      left={containerRect.left}
      width={containerRect.width}
      ref={stickyHeaderContainerRef}
    >
      <table
        className="table"
        ref={tableCloneRef}
        style={{ width: tableContainerRef.current?.scrollWidth || '100%' }}
      >
        <thead>
          <tr className="table__header" style={{ height: "35px" }}>
            <th className="leftSticky title">担当</th>
            <th className="leftSticky title">工事名（略名）</th>
            <th className="leftSticky title">工期</th>
            {daysWeeksArray.map((day, index) => (
              <th
                key={`sticky-${index}`}
                className={clsx(day.dayInWeek === "sun" || day.dayInWeek === "sat" ? "dayOff" : "day")}
              >
                {day.dayNumber}
              </th>
            ))}
          </tr>
        </thead>
      </table>
    </StickyHeaderContainer>
)}

    <table className="table">
      <thead>
        <tr className="table__header" ref={headerRef}>
          <th className="leftSticky title">担当</th>
          <th className="leftSticky title">工事名（略名）</th>
          <th className="leftSticky title">工期</th>
          {daysWeeksArray.map((day, index) => (
            <th 
              key={index} 
              className={clsx(day.dayInWeek === "sun" || day.dayInWeek === "sat" ? "dayOff" : "day")}
            >
              {day.dayNumber}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item: any) => (
          <tr key={item.id} className="">
            <td className="leftSticky">{item?.employee?.last_name} {item?.employee?.first_name}</td>
            <td className="leftSticky" style={{ cursor: "pointer" }}>
              <div onClick={() => history.push(`${appRouteEnums.PATH_WORK_SCHEDULE}/${item?.id}`, { itemDetail: item })}>
                {item?.name}
              </div>
            </td>
            <td className="leftSticky">
              {item?.start_date} <br /> {item?.end_date}
            </td>

            {daysWeeksArray.map((day, i) => {
              const currentDate = i < 10 ? "0" + i : "" + i;

              const totalNumberOfPeople =
                item?.work_schedules
                  ?.map((schedule) => {
                    const filteredWorkTypes = schedule?.work_schedule_work_type.filter((workType) => {
                      return workType?.date?.split("-")[2] === currentDate;
                    });

                    const numberOfPeople = filteredWorkTypes?.map((workType) => workType?.number_of_people);

                    return numberOfPeople?.reduce((sum, num) => sum + num, 0);
                  })
                  ?.reduce((total, dailyTotal) => total + dailyTotal, 0) ?? 0;

              return (
                <td key={i} className="table__event">
                  <div className="eventContainer">
                    {totalNumberOfPeople > 0 ? (
                      <div className="event">
                        <p className="event__name">{totalNumberOfPeople}</p>
                      </div>
                    ) : (
                      <div className="empty"></div>
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </StyledTableWrapView>
);
};