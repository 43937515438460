import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { StyledHomeItem, Tag } from "./styles";
import { theme } from "@utils";

const ListComponent = ({ logItems }) => {
  if (!logItems) {
    return (<span>Loading</span>);
  };

  return (
    <StyledHomeItem>
      <div className="home-table">

        <Grid container >
          <Grid item xs={12}>
            <div className="subTitle">
              <Grid container >
                <Grid item xs={6} sm={6} md={6} lg={1} xl={1}
                  sx={{ order: { xs: 2, sm: 2, md: 2, lg: 1 } }}
                >
                  <Typography align="center">操作名</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={3} xl={3}
                  sx={{ order: { xs: 1, sm: 1, md: 1, lg: 2 } }}
                >
                  <Typography align="center">アイテム名</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}
                  sx={{ order: { xs: 3, sm: 3, md: 3, lg: 3 } }}
                >
                  <Typography align="left">数量</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}
                  sx={{ order: { xs: 4, sm: 4, md: 4, lg: 4 } }}
                >
                  <Typography align="left">在庫数</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}
                  sx={{ order: { xs: 5, sm: 5, md: 5, lg: 5 } }}
                >
                  <Typography align="left">使用期間</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}
                  sx={{ order: { xs: 6, sm: 6, md: 6, lg: 6 } }}
                >
                  <Typography align="left">担当・現場名</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}
                  sx={{ order: { xs: 7, sm: 7, md: 7, lg: 7 } }}
                >
                  <Typography align="left">操作日付</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} >
            <div className="home-content">
              {logItems.map((logItem, index) => {
                const startDate =
                  logItem.start_reservation && moment(logItem.start_reservation).format("YYYY-MM-DD");
                const endDate = logItem.end_reservation && moment(logItem.end_reservation).format("YYYY-MM-DD");
                const oldStartDate =
                  logItem.pre_start_reservation && moment(logItem.pre_start_reservation).format("YYYY-MM-DD");
                const oldEndDate =
                  logItem.pre_end_reservation && moment(logItem.pre_end_reservation).format("YYYY-MM-DD");
                const date = logItem.date && moment(logItem.date).format("YYYY-MM-DD");
                // const displayDate = logItem.date && moment(logItem.date).format("YYYY/MM/DD HH:mm");
                const noteColors: Record<string, string> = {
                  "入庫": "#673AB7",
                  "出庫": theme.colors.orange,
                  "予約": theme.colors.blue06,
                  "返却": theme.colors.green07,
                  "変更": theme.colors.yellow08,
                  "取消": theme.colors.crimson,
                };


                return (

                  <Grid container key={index}
                    sx={{
                      borderBottom: index === logItems.length - 1 ? "none" : `1px solid ${theme.colors.lightgray09}`,
                      padding: 1,
                      alignItems: "center"
                    }}>
                    <Grid item xs={6} sm={6} md={6} lg={1} xl={1}
                      sx={{ order: { xs: 2, sm: 2, md: 2, lg: 1 } }}>
                      <Typography>
                        <div className="label">
                          <Tag bgColor={noteColors[logItem.note] || theme.colors.gray}>
                            {logItem.note}
                          </Tag>
                        </div>
                        <div className="label-arrow">
                          <CustomSVG
                            text={logItem.note}
                            bgColor={noteColors[logItem.note] || theme.colors.gray}
                            iconColor={noteColors[logItem.note] || theme.colors.gray}
                          />
                        </div>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}
                      sx={{ order: { xs: 1, sm: 1, md: 1, lg: 2 } }}>
                      <Typography align="left">
                        {logItem.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}
                      sx={{ order: { xs: 3, sm: 3, md: 3, lg: 3 } }}>
                      <Typography align="left">
                        <div className="recordName">
                          <p className="recordName__text">数量</p>
                        </div>
                        <p>
                          {logItem.note !== "取消" && logItem.returning_inventory !== null ? `+ ${logItem.returning_inventory}`
                            : logItem.note !== "取消" && logItem.taking_inventory !== null ? `- ${logItem.taking_inventory}` : ""}
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={1} xl={1}
                      sx={{ order: { xs: 4, sm: 4, md: 4, lg: 4 } }}>
                      <Typography align="left">
                        <div className="recordName">
                          <p className="recordName__text">在庫数</p>
                        </div>
                        <p>
                          {(logItem.note !== "取消" && logItem.returning_inventory !== null || logItem.note !== "取消" && logItem.taking_inventory !== null) ? logItem.quantity : ""}
                        </p>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={2} xl={2}
                      sx={{ order: { xs: 5, sm: 5, md: 5, lg: 5 } }}>
                      <Typography align="left">
                        <div className="recordName">
                          <p className="recordName__text">使用期間</p>
                        </div>
                        {(oldStartDate && oldStartDate !== startDate && logItem.note !== "取消") && (
                          <div style={{ textDecoration: "line-through" }}>{oldStartDate}</div>
                        )}
                        {startDate || date}
                        {endDate && " ~ "}<br />
                        {(oldEndDate && oldEndDate !== endDate && logItem.note !== "取消") && (
                          <div style={{ textDecoration: "line-through" }}>{oldEndDate}</div>
                        )}
                        {endDate}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}
                      sx={{ order: { xs: 6, sm: 6, md: 6, lg: 6 } }}>
                      <Typography align="left">
                        <div className="recordName">
                          <p className="recordName__text">担当・現場名</p>
                        </div>
                        {logItem.last_name} {logItem.first_name}<br />
                        現場名：{logItem.construction_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}
                      sx={{ order: { xs: 7, sm: 7, md: 7, lg: 7 } }}>
                      <Typography align="left">
                        <div className="recordName">
                          <p className="recordName__text">操作日付</p>
                        </div>
                        {logItem.date}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>

        </Grid>
      </div>
    </StyledHomeItem>
  );
};

export default ListComponent;

const CustomSVG = ({ bgColor = theme.colors.blue06, iconColor = theme.colors.blue06, text = "予約", textColor = theme.colors.white00 }) => {
  return (
    <svg width="90" height="33" viewBox="0 0 124 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="6.25" width="110" height="35" rx="17.5" fill={bgColor} />

      <path d="M100.21 10.5292C101.916 9.65736 103.971 9.83773 105.5 10.9936L117.762 20.2651C120.542 22.367 120.373 26.5968 117.434 28.4699L103.473 37.3675C101.815 38.4242 99.6912 38.4111 98.0463 37.3339L83.2044 27.6144C79.9934 25.5117 80.251 20.7249 83.669 18.9788L100.21 10.5292Z" fill={iconColor} />

      <text
        fill={textColor}
        fontFamily={theme.fontFamily.NotoSansJP_500}
        fontSize="20"
        fontWeight="500"
        letterSpacing="0em"
        x="50"
        y="30"
        textAnchor="middle"
      >
        {text}
      </text>
    </svg>
  );
};
