import React from "react";
import { StyledVehicleView, VehiclesView } from "src/Components/Vehicles";

export const Vehicles = () => {
    return (
        <div>
            <StyledVehicleView>
                <VehiclesView />
            </StyledVehicleView>
        </div>
    );
};
