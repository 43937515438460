import { Grid } from "@mui/material";
import { formatDate } from "@utils";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import { AppCustomeSelect, AppInput, AppSelect, AppTextArea, AppTimePicker } from "../Common";
import useContractors from "src/Utils/Hooks/useContractors";

interface IProps {
  values: any;
  handleChange: any;
  touched: any;
  errors: any;
  handleBlur: any;
  optionSiteConstructions: any;
  optionConstractors: any;
  setFieldValue: any;
  onChangeConstractors: any;
  listReasons: any;
  onChangeConstruction: any;
}

const OTNewHalfDay = (props: IProps) => {
  const {
    values,
    handleChange,
    touched,
    errors,
    optionSiteConstructions,
    optionConstractors,
    setFieldValue,
    onChangeConstractors,
    handleBlur,
    listReasons,
    onChangeConstruction,
  } = props;
  const [valueConstruction, setValueConstruction] = React.useState<any>(null);

  const listConstructionOption =
    optionSiteConstructions?.constructions?.length &&
    optionSiteConstructions?.constructions?.map((item) => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  const constructionSelected = listConstructionOption?.find(
    (item) => item.value === values?.construction.toString()
  );
  const { updateContractorHistory } = useContractors();
  return (
    <div className="newOT__time">
      <Grid container spacing={4}>
        <Grid item xs={12} md={7} container>
          <WrapTime>
            <div className="start-time">
              <label className="label">開始時間</label>
              <AppTimePicker
                value={dayjs(values.start_time, "HH:mm:ss").toDate()}
                handleChange={(newValue) => {
                  const formatValue = formatDate({
                    date: newValue,
                    format: "HH:mm:ss",
                  });
                  setFieldValue("start_time", formatValue);
                }}
                errorMessage={
                  touched.start_time && errors.start_time
                    ? errors.start_time
                    : undefined
                }
              />
            </div>
            <div className="end-time">
              <label className="label">終了時間</label>
              <AppTimePicker
                value={dayjs(values.stop_time, "HH:mm:ss").toDate()}
                handleChange={(newValue) => {
                  const formatValue = formatDate({
                    date: newValue,
                    format: "HH:mm:ss",
                  });
                  setFieldValue("stop_time", formatValue);
                }}
                errorMessage={
                  touched.stop_time && errors.stop_time
                    ? errors.stop_time
                    : undefined
                }
              />
            </div>
          </WrapTime>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="user__department">
            <p className="label">工事名</p>
            <AppCustomeSelect
              options={listConstructionOption}
              placeholder="select 請負業者"
              name="construction"
              value={valueConstruction || constructionSelected}
              // noError={errors.constructors}
              onChange={(value) => {
                updateContractorHistory(value);
                onChangeConstruction(value, setFieldValue);
                setValueConstruction(value);
              }}
              error={errors.construction}
              touched={touched.construction}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="input">
            <AppInput
              isNumber
              value={String(values.work_time)}
              handleChange={handleChange("work_time")}
              label="稼働時間 (hour)"
              error={errors.work_time}
              touched={touched.work_time}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="input">
            <AppInput
              isNumber
              value={String(values.break_time)}
              handleChange={handleChange("break_time")}
              label="休憩 (hour)"
              error={errors.break_time}
              touched={touched.break_time}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <AppInput
              label="業務内容"
              value={values.work_detail}
              name="work_detail"
              error={errors.work_detail}
              touched={touched.work_detail}
              handleChange={handleChange("work_detail")}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <label className="label">業者名</label>

            <div className="select__contractor">
              <div style={{ width: "70%" }}>
                <AppCustomeSelect
                  groupOptions={optionConstractors}
                  options={
                    optionConstractors?.contractors?.length &&
                    optionConstractors?.contractors.map((item) => {
                      return {
                        label: item.name,
                        value: item.id.toString(),
                      };
                    })
                  }
                  placeholder="select 請負業者"
                  name="subContractor"
                  value={values.subContractor}
                  // noError={errors.constructors}
                  onChange={(value) => {
                    updateContractorHistory(value);
                    onChangeConstractors(value, values, setFieldValue);
                    setFieldValue("subContractor", value);
                  }}
                  isMulti
                />
              </div>
            </div>
            {errors.subContractor && touched.subContractor && (
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  marginTop: "5px",
                }}
              >
                {errors?.subContractor}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="des">
            <label className="label">理由</label>
            <AppSelect
              options={
                listReasons?.length &&
                listReasons?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id?.toString(),
                  };
                })
              }
              name="reasonType"
              value={values.reasonType}
              handleChange={handleChange("reasonType")}
              placeholder="理由を選択してください"
              error={touched.reasonType ? errors.reasonType : undefined}
            />
          </div>
          {values.reasonType === "0" && (
            <div className="input">
              <AppInput
                value={values.reasonContent}
                name="reasonContent"
                error={errors.reasonContent}
                touched={touched.reasonContent}
                handleChange={handleChange("reasonContent")}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <label className="label">連絡先</label>
            <AppTextArea
              name="phoneNumber"
              handleChange={handleChange("phoneNumber")}
              value={values.phoneNumber}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              handleBlur={handleBlur("phoneNumber")}
            // isNumber
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <AppInput
              label="備考"
              value={values.memo}
              name="memo"
              // error={errors.memo}
              error={touched.memo ? errors.memo : undefined}
              touched={touched.memo}
              handleChange={handleChange("memo")}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OTNewHalfDay;

const WrapTime = styled.div`
    display: flex;
    .end-time {
        margin-left: 20px;
    }
`;
