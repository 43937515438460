export const overtimeNewTitle = [
    {
        label: "有給休暇",
        value: "有給休暇",
    },
    {
        label: "休日出勤",
        value: "休日出勤",
    },
    {
        label: "半日有給",
        value: "半日有給",
    },
    {
        label: "夜勤",
        value: "夜勤",
    },
    {
        label: "残業",
        value: "残業",
    },
    {
        label: "日勤からの夜勤",
        value: "日勤からの夜勤",
    },
    {
        label: "夜勤からの日勤",
        value: "夜勤からの日勤",
    },
    {
        label: "出張届",
        value: "出張届",
    },
    {
        label: "特別休暇",
        value: "特別休暇",
    },
    {
        label: "欠勤",
        value: "欠勤",
    },
    {
        label: "遅刻",
        value: "遅刻",
    },
    {
        label: "早退",
        value: "早退",
    },
];

// export const filterOverTimeTitle = [
//     {
//         // Holiday Work
//         id: 1,
//         name: "休日出勤",
//         value: "休日出勤",
//     },
//     {
//         // Over Time
//         id: 2,
//         name: "残業",
//         value: "残業",
//     },
//     {
//         // night shift
//         id: 3,
//         name: "夜勤",
//         value: "夜勤",
//     },
//     {
//         // Day shift and ngiht shift
//         id: 4,
//         name: "日勤からの夜勤",
//         value: "日勤からの夜勤",
//     },
//     {
//         // Night shift and day shift
//         id: 5,
//         name: "夜勤からの日勤",
//         value: "夜勤からの日勤",
//     },
//     {
//         // Half-day work
//         id: 6,
//         name: "半日出勤",
//         value: "半日出勤",
//     },
//     {
//         // Paid Time Off
//         id: 7,
//         name: "特別休暇",
//         value: "特別休暇",
//     },
//     {
//         // Absence
//         id: 8,
//         name: "忌引き休暇",
//         value: "忌引き休暇",
//     },
//     {
//         // Legal Holiday
//         id: 9,
//         name: "欠勤",
//         value: "欠勤",
//     },
//     {
//         // Legal Holiday
//         id: 10,
//         name: "早退",
//         value: "早退",
//     },
//     {
//         // Legal Holiday
//         id: 11,
//         name: "遅刻",
//         value: "遅刻",
//     },
//     {
//         // Paid vacation
//         id: 12,
//         name: "有給休暇",
//         value: "有給休暇",
//     },
// ];

export const filterOverTimeTitle = [
    {
        id: 1,
        name: "有給休暇",
        value: "有給休暇",
    },
    {
        id: 2,
        name: "休日出勤",
        value: "休日出勤",
    },
    {
        id: 3,
        name: "半日有給",
        value: "半日有給",
    },
    {
        id: 4,
        name: "夜勤",
        value: "夜勤",
    },
    {
        id: 5,
        name: "残業",
        value: "残業",
    },
    {
        id: 6,
        name: "日勤からの夜勤",
        value: "日勤からの夜勤",
    },
    {
        id: 7,
        name: "夜勤からの日勤",
        value: "夜勤からの日勤",
    },
    {
        id: 8,
        name: "出張届",
        value: "出張届",
    },
    {
        id: 9,
        name: "特別休暇",
        value: "特別休暇",
    },
    {
        id: 10,
        name: "欠勤",
        value: "欠勤",
    },
    {
        id: 11,
        name: "遅刻",
        value: "遅刻",
    },
    {
        id: 12,
        name: "早退",
        value: "早退",
    },
];

export const initOTVacation = {
    title: "",
    reasonType: "",
    phoneNumber: "",
    memo: "",
    start_time: '',
    stop_time: '',
    type_of_overtime: "0",
};

export const initOTOvertime = {
    title: "",
    memo: "",
    start_time: '',
    stop_time: '',
    type_of_overtime: "0",
    break_time: '',
    work_time: '',
    construction: '',
    work_detail: '',
    subContractor: ''
};
export const initOTAllType = {
    title: "",
    memo: "",
    reasonType: "",
    reasonContent: "",
    phoneNumber: "",
    start_time: '',
    stop_time: '',
    type_of_overtime: "0",
    break_time: '',
    work_time: '',
    construction: '',
    work_detail: '',
    subContractor: [],
    compensatory_leave: "0",
    detail: '',
    start_at_extra: '',
    stop_at_extra: '',
    break_time_extra: '',
    work_time_extra: '',
    construction_id_extra: '',
    work_detail_extra: '',
    contractor_id_extra: [],
    memo_extra: '',
    family_relationship: '',
    family_living: ''
};