import { ILayout } from "@interfaces";

import { StyledAppLayout, MainContent, Sidebar, MenuToggleButton } from ".";
import { AppTopBar, MenuComponent } from "@components";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export const AppLayout = (props: ILayout) => {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const hiddenPaths = ["/login", "/fogot-password"];

  return (
    <StyledAppLayout>
      {!hiddenPaths.includes(location.pathname) &&
        <Sidebar isOpen={isSidebarOpen}>
          <MenuComponent />
        </Sidebar>
      }

      {!hiddenPaths.includes(location.pathname) ? (
        <MainContent>
          <MenuToggleButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>☰</MenuToggleButton>
          <AppTopBar />
          {children}
        </MainContent>
      ) : (
        <>
          {children}
        </>
      )}
    </StyledAppLayout>
  );
};
