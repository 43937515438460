import axiosClient from "./axiosClient";

interface IStoreTokenDeviceParams {
    token: string;
}
interface IDeleteTokenDeviceParams {
    id: string;
}
export const notiApi = {
    storeTokenDevice: (params: IStoreTokenDeviceParams) => {
        const url = "/api/store-device-token";
        return axiosClient.post(url, params);
    },
    deleteTokenDevice: (params: IDeleteTokenDeviceParams) => {
        const url = `/api/remove-device-token?id=${params.id}`;
        return axiosClient.delete(url);
    },
    viewListNotification: (params: any) => {
        const url = `/api/list-notification?page=${params.page}&status=${params.status}&type_noti=${params.type_noti}`;
        return axiosClient.get(url);
    },
    updateStatusNotification: (id: any) => {
        const url = `/api/update-notification/${id}`;
        return axiosClient.put(url);
    },
    // updateStatusNotification: (params: any) => {
    //     const url = `/api/update-notification/${params.id}`;
    //     return axiosClient.put(url);
    // },
    deleteNotification: (params: any) => {
        let url = `/api/notifications`;
        if (params.id) {
            url += `/${params.id}`;
        }
        return axiosClient.delete(url);
    },
    deleteMultipleNotification: (body: any) => {
        const url = `/api/notifications/mutiple`;
        // if (params.id) {
        //     url += `/${params.id}`;
        // }
        return axiosClient.post(url, body);
    }
};