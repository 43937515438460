import {
    ILoginForm,
    IForgotPasswordForm,
    IResetPasswordForm,
    IChangePasswordForm,
    ITokenExpired,
} from "src/Interfaces/auth";
import axiosClient from "./axiosClient";

export const authApi = {
    login: (params: ILoginForm) => {
        const url = "/api/login";
        return axiosClient.post(url, params);
    },
    logout: () => {
        const url = "/api/logout";
        return axiosClient.post(url);
    },
    fogotPassword: (params: IForgotPasswordForm) => {
        const url = "/api/forgot-password";
        return axiosClient.post(url, params);
    },
    resetPassword: (params: IResetPasswordForm) => {
        const url = "/api/reset-password";
        return axiosClient.post(url, params);
    },
    changePassword: (params: IChangePasswordForm) => {
        const url = "/api/change-password";
        return axiosClient.post(url, params);
    },
    updateAvatar: (params: any) => {
        const url = "/api/change-avatar";
        return axiosClient.post(url, params);
    },
    tokenExpired: (params: ITokenExpired) => {
        const url = "/api/token-expired";
        return axiosClient.post(url, params);
    },
    getInfo: () => {
        const url = "/api/profile";
        return axiosClient.get(url);
    },
    getListGlobalDocument: () => {
        const url = "api/get-list-global-document";
        return axiosClient.get(url);
    }
};
