import { selectAuth, useAppSelector } from "@redux";
import { CircularLoader } from "../Common";
import { useState } from "react";

export const BusinessCardsView = () => {
  const user = useAppSelector(selectAuth);
  const userId = user.userInfo?.id;
  const [loading, setLoading] = useState(true);
  
  return (
    <div style={{ position: "absolute", left: 0, right: 0, top: "6.5rem", bottom: 0, backgroundColor: "#fff" }}>
      <CircularLoader loading={loading} />
      <iframe
        src={`https://cards.livelet.dev?userKey=${userId}`}
        title="Business Cards"
        style={{ width: '100%', height: '100%', border: 'none' }}
        allow="camera"
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
      />
    </div>
  );
};
