import styled from "styled-components";

export const StyledAppSelect = styled.div`
    /* display: flex;
    align-items:center; */
    width: 100%;
    .MuiOutlinedInput-root {
        background-color:${(p) => p.theme.colors.white02};
        font-size: 1.4rem;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        border-radius: 24px;;
        height:40px;
    }
    .MuiOutlinedInput-input {
        padding: 0.8rem;
    }
    .MuiMenuItem-root {
        justify-content: flex-start !important;
        text-align: left;
        display: none;
    }
    svg {
        margin-right: 2rem;
        cursor: pointer;
    }
    .MuiAvatar-root {
        width: 3rem;
        height: 3rem;
    }
    .colorIcon {
        height: 2rem;
        width: 2rem;
        border-radius: 100%;
        margin-right: 1rem;
    }
    .MuiMenuItem-root {
        padding: 1rem 0;
    }
`;
export const Placeholder = styled.div`
    color: #c6c6c7;
`;
export const StyledAppMultipleSelect = styled.div`
    width: 100%;
    max-width: 40rem;
    .MuiOutlinedInput-root {
        font-size: 1.4rem;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        border-radius: 1.6rem;
        width: 100%;
    }
    .MuiOutlinedInput-input {
        padding: 1.2rem;
    }
    svg {
        margin-right: 2rem;
        cursor: pointer;
    }
`;
export const StyledSelectInputChipBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    .chip {
        &__select {
            margin-left: 0.5rem;
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
        }
        .MuiChip-label {
        }
    }
`;
export const StyledAppCustomSelect = styled.div`
    // z-index: 1;
    .filter {
      &__single-value{
        .MuiAvatar-root{
          display: none;
          }
          }
          &__menu {
            margin: 0.125rem auto;
            font-size: 1.2rem;
            z-index: 100;
            }
            &__control{
            background-color:${(p) => p.theme.colors.white02};
            border-radius: 24px;
        }
        &__option {
            min-height: 6rem;
            &--is-focused {
                background-color: lightblue;
            }

        }

        &__group {
            padding: 0;
        }

        &__menu-portal {
            border: 1px solid white;
        }
        &__multi-value__label{
            font-size: 1.4rem;
        }
        &__placeholder,&__input{
            font-size: 1.4rem !important;
        }
    }
`;
export const StyledAppCustomeOption = styled.div`
    display: flex;
    .optionName{
        padding-left: 1rem;
        display: flex;
        align-items: center;
    }
    .default{
        background-color: gray;
        color: black;
    }
`;
