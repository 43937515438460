/* eslint-disable no-console */
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Box } from "@mui/system";

import { LABEL_DEVICE_CHECK, LABEL_INSPECTOR, PLACEHOLDER_CONSTRUCTIONSITE, PLACEHOLDER_INSPECTOR } from "@configs";
import { formatDate, useConstructionSites } from "@utils";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { AppCustomeSelect, AppInput, AppSelect, AppTextAreaNumber, AppTimePicker } from "src/Components/Common";
import { useTimeSheetContext } from "../../context";
import { TimeSheetNightShiftFormSchemaType } from "../../validation";
import { SiteConstructionsFormWidget } from "./site-constructions-form.widget";
import { timesheetApi } from "@api";
import { updateSnackBar } from "@redux";
import { useDispatch } from "react-redux";
import { appRouteEnums } from "@enums";
import { useState } from "react";
import queryString from "query-string";
import styled from "styled-components";
import dayjs from "dayjs";
import { CheckDefaultValue, StyledAlcolCheck, StyledBorder, StyledButtonContainer } from "../form-sheet-night-shift";
import { CustomBlueCheckbox } from "../../styles";
import { GrayButton, PassChangeButton } from "src/Components/Profile";
import "../timesheetWidget.css";

interface FormSheetNightAndDayProps {
  triggerOtherForm: any;
}

export function FormSheetNightAndDay({ triggerOtherForm }: FormSheetNightAndDayProps) {
  const form = useFormContext<TimeSheetNightShiftFormSchemaType>();
  const { updateConstructionHistory, sortConstructionSites } = useConstructionSites();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const parsed = queryString.parse(location.search);

  const { employees, optionVehicles, optionSiteConstructions, alcoholChecker } = useTimeSheetContext();
  const optionSiteConstructionsSorted = sortConstructionSites(optionSiteConstructions);
  let defaultConstructionValues;
  let newOptionConstruction;

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = form;

  const params: any = useParams();

  const watchConstructions = useWatch({ name: `list_constructions` });
  if (watchConstructions && optionSiteConstructionsSorted.length > 0 && (params?.id || parsed?.copy)) {
    const constructionsListValue = watchConstructions.map((construction) => {
      return construction.construction_id;
    });
    const inactiveOptionConstruction = watchConstructions?.map((construction) => {
      if (construction?.listConstruction?.name && construction?.listConstruction?.id) {
        return {
          label: construction?.listConstruction?.name,
          value: construction?.listConstruction?.id,
        };
      }
      return null;
    });

    const filteredInactiveOptionConstruction = inactiveOptionConstruction.filter((item) => item !== null);

    // Kiểm tra xem giá trị của phần tử có trùng với giá trị của optionSiteConstructionsSorted hay không
    const isDuplicate = (item) => optionSiteConstructionsSorted?.some((option) => option.value === item.value);

    // thêm construction trả về từ timesheet vào option list các construction thành 1 mảng option mới
    newOptionConstruction = [...filteredInactiveOptionConstruction.filter((item) => !isDuplicate(item)), ...optionSiteConstructionsSorted];

    defaultConstructionValues = constructionsListValue;

    defaultConstructionValues = newOptionConstruction?.filter((construction) => constructionsListValue.some((constructionChecked) => constructionChecked === construction.value));
  }

  const handleDeleteTimeSheet = async () => {
    setDisableSubmit(true);
    try {
      const result = await timesheetApi.deleteTimesheet(params?.id);

      dispatch(
        updateSnackBar({
          messageType: "success",
          message: result.data.message,
        })
      );
      history.push(appRouteEnums.PATH_TIMESHEET);
    } catch (error: any) {
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response.data.message,
        })
      );
    } finally {
      setDisableSubmit(false);
    }
  };

  return (
    <>
      <div className="boxWrapper">
        <h3>日勤</h3>
        <div className="radio">
          <StyledBorder>
            <label className="label">アルコールチェック</label>
            <div>
              <FormGroup>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name="alcohol_check"
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value, ref } }) => (
                      <>
                        <Checkbox inputRef={ref} checked={!!value} onChange={(_, checked) => onChange(checked)} />
                        <FormControlLabel control={<></>} label="チェック済み" style={{ marginLeft: "5px" }} />
                      </>
                    )}
                  />
                </div>
              </FormGroup>
              {errors?.alcohol_check && (
                <p
                  style={{
                    color: "#FF597B",
                    fontSize: "13px",
                  }}
                >
                  {errors?.alcohol_check?.message === "Required" ? "この項目は必須です" : errors?.alcohol_check?.message}
                </p>
              )}
            </div>
          </StyledBorder>
          <StyledBorder>
            <div className="label">日付</div>
            <div className="input select time">
            <Controller
              name="check_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <input
                  type="date"
                  value={value ? dayjs(value).format("YYYY-MM-DD") : ""}
                  onChange={(event) => {
                    const formattedValue = dayjs(event.target.value).format("YYYY-MM-DD");
                    onChange(formattedValue);
                  }}
                  disabled
                  className="timesheetDate"
                />
              )}
            />
            </div>
          </StyledBorder>
          <StyledAlcolCheck>
            <div>
              <label className="label">出勤時 </label>
              <div className="wrap__alcohol-start">
                <Controller
                  name={`start_time_check_alcohol`}
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const formatValue = dayjs(value, "HH:mm").toDate();
                    return (
                      <div>
                        <AppTimePicker
                          value={formatValue}
                          handleChange={(value) => {
                            const formatValue = formatDate({
                              date: value,
                              format: "HH:mm",
                            });

                            onChange(formatValue);
                          }}
                        />
                        {error && (
                          <p
                            style={{
                              color: "#FF597B",
                              fontSize: "13px",
                              margin: "8px",
                            }}
                          >
                            {error?.message === "Required" ? "この項目は必須です" : error?.message}{" "}
                          </p>
                        )}
                      </div>
                    );
                  }}
                />
                <div
                // className="input"
                // style={{
                //   marginTop: "10px",
                //   display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // }}
                >
                  <Controller
                    name={`start_capacity_alcohol`}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <div
                        className="wrap__alcohol-start"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "-20px",
                          marginLeft: "10px",
                        }}
                      >
                        <AppTextAreaNumber value={value} handleChange={onChange} />
                        {error && (
                          <p
                            style={{
                              color: "#FF597B",
                              fontSize: "13px",
                              margin: "8px",
                            }}
                          >
                            {error?.message === "Required" ? "この項目は必須です" : error?.message}{" "}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <label className="label">退勤時</label>
              <div className="wrap__alcohol-end">
                <Controller
                  name={`end_time_check_alcohol`}
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const formatValue = dayjs(value, "HH:mm").toDate();
                    return (
                      <div>
                        <AppTimePicker
                          value={formatValue}
                          handleChange={(value) => {
                            const formatValue = formatDate({
                              date: value,
                              format: "HH:mm",
                            });

                            onChange(formatValue);
                          }}
                        />
                        {error && (
                          <p
                            style={{
                              color: "#FF597B",
                              fontSize: "13px",
                              margin: "8px",
                            }}
                          >
                            {error?.message === "Required" ? "この項目は必須です" : error?.message}{" "}
                          </p>
                        )}
                      </div>
                    );
                  }}
                />
                <div
                // className="input"
                // style={{
                //   marginTop: "10px",
                //   display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // }}
                >
                  <Controller
                    name={`end_capacity_alcohol`}
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <div
                      // className="wrap__alcohol-end"
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "column",
                      //   marginRight: "-20px",
                      //   marginLeft: "10px",
                      // }}
                      >
                        <AppTextAreaNumber value={value} handleChange={onChange} />
                        {error && (
                          <p
                            style={{
                              color: "#FF597B",
                              fontSize: "13px",
                              margin: "8px",
                            }}
                          >
                            {error?.message === "Required" ? "この項目は必須です" : error?.message}{" "}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="check">
              <Controller
                name="alcohol_default_check"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckDefaultValue>
                    <CustomBlueCheckbox checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)} />
                    <p>固定</p>
                    {/* <input type="checkbox" {...field} checked={field.value} value={field.value ? "true" : "false"} /> */}
                  </CheckDefaultValue>
                )}
              />
            </div>
          </StyledAlcolCheck>
          {/* </Box> */}
          <StyledBorder>
            {/* <Box> */}
            <label className="label">{LABEL_INSPECTOR}</label>
            <div className="input select wrap-checkbox">
              {alcoholChecker?.length && (
                <Controller
                  name="inspector_id"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <AppSelect
                        error={error?.message}
                        options={alcoholChecker}
                        value={String(value)}
                        handleChange={(value, action) => {
                          onChange(String(value));
                          if (action === "clear") {
                            onChange("");
                          }
                        }}
                        isClearable
                        placeholder={PLACEHOLDER_INSPECTOR}
                      />
                    );
                  }}
                />
              )}
              <Controller
                name="inspector_default_check"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckDefaultValue>
                    <CustomBlueCheckbox checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)} />
                    <p>固定</p>
                    {/* <input type="checkbox" {...field} checked={field.value} value={field.value ? "true" : "false"} /> */}
                  </CheckDefaultValue>
                )}
              />
            </div>
            {/* </Box> */}
          </StyledBorder>
          <StyledBorder>
            {/* <Box> */}
            <label className="label">運転者</label>
            <div className="input select wrap-checkbox">
              {employees?.length && (
                <Controller
                  name="driver_id"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <AppSelect
                      error={error?.message}
                      options={employees}
                      value={String(value)}
                      handleChange={(value, action) => {
                        onChange(String(value));
                        if (action === "clear") {
                          onChange("");
                        }
                      }}
                      isClearable
                      placeholder="ドライバーを選択"
                    />
                  )}
                />
              )}
              <Controller
                name="driver_default_check"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckDefaultValue>
                    <CustomBlueCheckbox checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)} />
                    <p>固定</p>
                    {/* <input type="checkbox" {...field} checked={field.value} value={field.value ? "true" : "false"} /> */}
                  </CheckDefaultValue>
                )}
              />
            </div>
            {/* </Box> */}
          </StyledBorder>
          <StyledBorder>
            {/* <Box> */}
            <label className="label">車両名</label>
            <div className="input select wrap-checkbox">
              {optionVehicles?.length && (
                <Controller
                  name="vehicle_id"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <AppSelect
                      error={error?.message}
                      options={optionVehicles}
                      value={String(value)}
                      handleChange={(value, action) => {
                        onChange(String(value));
                        if (action === "clear") {
                          onChange("");
                        }
                      }}
                      isClearable
                      placeholder="車両を選択"
                    />
                  )}
                />
              )}
              <Controller
                name="vehicle_default_check"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckDefaultValue>
                    <CustomBlueCheckbox checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)} />
                    <p>固定</p>
                    {/* <input type="checkbox" {...field} checked={field.value} value={field.value ? "true" : "false"} /> */}
                  </CheckDefaultValue>
                )}
              />
            </div>
            {/* </Box> */}
          </StyledBorder>
          <StyledBorder>
            {/* <Box> */}
            <label className="label">{LABEL_DEVICE_CHECK}</label>
            <div className="wrap-checkbox">
              <Controller
                name="device_check"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <AppInput name="deviceCheck" handleChange={onChange} value={value ? String(value) : ""} />
                    {error && (
                      <p
                        style={{
                          color: "#FF597B",
                          fontSize: "13px",
                          margin: "8px",
                        }}
                      >
                        {error?.message}
                      </p>
                    )}
                  </div>
                )}
              />
              <Controller
                name="device_default_check"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckDefaultValue>
                    <CustomBlueCheckbox checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)} />
                    <p>固定</p>
                    {/* <input type="checkbox" {...field} checked={field.value} value={field.value ? "true" : "false"} /> */}
                  </CheckDefaultValue>
                )}
              />
            </div>
            {/* </Box> */}
          </StyledBorder>
          {/* <Divider sx={{ mx: "20px" }} /> */}
          <div className="input select">
            <label className="label">現場名</label>

            <AppCustomeSelect
              placeholder={PLACEHOLDER_CONSTRUCTIONSITE}
              onChange={(values) => {
                if (values.length === 0) {
                  setValue("list_constructions", []);
                  updateConstructionHistory(null);
                  return;
                }

                const lastValue = values[values.length - 1]?.value;
                if (!lastValue) {
                  return;
                }

                updateConstructionHistory(lastValue);

                const listConstructions = getValues("list_constructions");
                clearErrors("list_constructions");
                if (values.length > listConstructions.length) {
                  setValue("list_constructions", [
                    ...listConstructions,
                    {
                      construction_id: lastValue,
                      start_at: "",
                      stop_at: "",
                      break_time: 0,
                      weather_id: "",
                      memo: "",
                      livelet_employee: [],
                      contractors: [],
                    },
                  ]);
                  return;
                }

                const valueSelect = listConstructions.find(({ construction_id }) => !values.some(({ value }) => construction_id === value));

                const valueListConstructions = listConstructions.filter((construction) => construction.construction_id !== valueSelect?.construction_id);

                setValue("list_constructions", valueListConstructions);
              }}
              value={defaultConstructionValues}
              options={optionSiteConstructionsSorted}
              isMulti
            />
            {errors.list_constructions && (
              <p
                style={{
                  color: "#FF597B",
                  fontSize: "13px",
                  margin: "8px",
                }}
              >
                {errors?.list_constructions.message}
              </p>
            )}
          </div>
          {optionSiteConstructionsSorted && <SiteConstructionsFormWidget optionSiteConstructions={newOptionConstruction || optionSiteConstructionsSorted} />}
        </div>
      </div>

      <StyledButtonContainer>
        <PassChangeButton onClick={() => triggerOtherForm()} type="submit" disabled={disableSubmit}>更新</PassChangeButton>
      </StyledButtonContainer>

      {/* <div className="btn">
        <AppButton disabled={disableSubmit} onClick={() => triggerOtherForm()} type="submit" text="更新" />
      </div> */}

      {params?.id && (
        <StyledButtonContainer>
          <GrayButton disabled={disableSubmit} onClick={handleDeleteTimeSheet} >削除</GrayButton>
        </StyledButtonContainer>
        // <div className="delete">
        //   <AppButton text="削除する" disabled={disableSubmit} onClick={handleDeleteTimeSheet} />
        // </div>
      )}
    </>
  );
}
// export const StyledAlcolCheck = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     /* align-items: center; */
//     /* @media (min-width: 620px) {
//         margin-left: -10px !important;
//     } */
//     .wrap__alcohol {
//         &-start {
//             margin-right: 10px;
//             @media (max-width: 620px) {
//                 max-width: 250px !important;
//             }
//         }
//         &-end {
//             margin-right: 10px;
//             @media (max-width: 620px) {
//                 max-width: 250px !important;
//             }
//         }
//     }

//     @media (max-width: 620px) {
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-start !important;
//     }
// `;

// export const CheckDefaultValue = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     margin-left: 10px;
//     width: 35px;
//     p {
//         line-height: 1.4;
//     }
//     input {
//         width: 30px;
//         height: 20px;
//     }
// `;

// export const CheckDefaulAlcoltValue = styled.div`
//     display: flex;
//     flex-direction: column;
//     width: 35px;
//     input {
//         width: 20px;
//         height: 20px;
//     }
// `;
