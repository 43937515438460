import styled from "styled-components";
interface IStyledScheduleScreen {
    isCollaspe?: boolean;
}
export const StyledProcessSheetWorkSchedule = styled.div`
    .wrapper {
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        
    }
    .graphView {
        width: 100%;
        height: 50vh;
        &__wrapper {
            height: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 100%;
            }
        }
        .date {
            width: 8rem !important;
        }
    }
`;
export const StyledTableWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow-x: auto;

    .body-container {
        width: 100vw;
        
    }

    .table {
        table-layout: fixed;
        width: 1300px;
        border-spacing: 0;
        overflow: hidden;
        .dayOff {
            background-color: #ffffff;
        }
        &__header {
            border: 1px solid #d4d4d4;
            td {
                text-align: center;
                background-color: #ebe6e6;
            }
        }
        &__body {
            td {
                min-width: 5rem;
                border: 1px solid #d4d4d4 !important;
                width: 5rem !important;
                overflow: hidden;
                /* padding: 1rem; */
                /* text-align: left; */
            }
        }
        &__event {
            .event_pointer {
                cursor: pointer;
            }
            .event {
                width: 100%;
                height: 100%;
                /* border-radius: 1.6rem; */
                padding: 0.5rem;
                max-height: 7rem;
                justify-content: center;

                &__name {
                    display: block;
                    display: -webkit-box;
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    max-width: 100%;
                    // min-height: 1.8rem;
                }
                &__wrapper {
                    display: flex;
                }
                &__content {
                    padding-left: 1rem;
                }
                .MuiAvatar-root {
                    width: 3rem;
                    height: 3rem;
                }
                .workContent {
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .cursor-default {
                cursor: default !important;
            }
        }
    }
    td {
        width: 5rem !important;
        overflow: hidden;
    }
    .text {
        &__construction {
            display: block;
            display: -webkit-box;
            line-height: 1.2;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            max-width: 100%;
        }
    }
`;
export const StyledWorkScheduleScreen = styled.div<IStyledScheduleScreen>`
    padding: 1rem 1rem;
    background-color:${(p) => p.theme.colors.white02};
    min-height:100vh;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding: 2rem 2rem;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding: 2rem 2rem;
    }
        margin-top:7rem;
        padding-bottom:12rem;
    .topContents {
      display:flex;
      align-items:center;
      justify-content:flex-start;
      gap:2rem;
      padding-bottom:2rem;
      border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};

      @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        text-align:left;
        display:block;
    }}

    .buttons {
      display:flex;
      align-items:center;
      gap:1rem;

      @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        margin-top:2rem;
        justify-content:space-around;
    }
    }

    .mb-5 {
        margin-bottom: 2rem;
    }
    .workSchedule {
        text-align: left;

        .container {
          margin:2rem 0;
          display:flex;
          align-items:center;
          justify-content:space-between;
          @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display:block;
            }
        }
        .subContainer {
          display:flex;
          align-items:center;
          gap:2rem;
          @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                  display:block;
              }

        }

        .visible {
            &__mobile {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    display: none;
                }
            }
            &__desktop {
                display: block;
                // display: none;
                // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                //     display: block;
                // }
            }
        }
        &__button {
            width: 15rem;
        }
        &__emp {
          display:flex;
          gap:0.5rem;
          align-items:center;
          @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display:block;
            }
        }
        &__empSelect {
          width:25rem;
          @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width:100%;
            }
        }
        &__form {
            margin-top: 2rem;
        }
        &__select {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 30rem !important;
            }
        }
        &__filter {
            padding: 1rem 0;
            display: flex;
            overflow-y: auto;
            column-gap: 0.5rem;
            // margin-bottom: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                // width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                grid-template-columns: auto auto auto auto auto;
            }

            .active {
                border: 1px solid ${(p) => p.theme.colors.secondary} !important;
                .title {
                    color: ${(p) => p.theme.colors.secondary}!important;
                }
            }
            &-item {
                display: flex;
                padding: 0.5rem 1rem;
                border-radius: 1.6rem;
                border: 1px solid ${(p) => p.theme.colors.darkBlack};
                justify-content: center;
                cursor: pointer;
                min-width: 12rem;
                .title {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    padding-right: 1rem;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    height: 20px;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &__datePicker {
            width: 100%;
            margin-bottom:1rem;

            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 20rem;
                margin-bottom:0;
            }
            .label {
                width: 5rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                }
            }
            .wrapperDatePicker {
                // padding-left: 1rem;
            }
        }
        &__toggle {
            margin: 2rem 0;
            width: 100%;
            border-radius: 1.6rem;
            display: flex;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
            .left,
            .right {
                text-align: center;
                padding: 1rem 0;
                width: 50%;
                border: 1px solid #f4f4f4;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                transition: 250ms;
            }
            .left {
                border-top-left-radius: 1.6rem;
                border-bottom-left-radius: 1.6rem;
            }
            .right {
                border-top-right-radius: 1.6rem;
                border-bottom-right-radius: 1.6rem;
            }
            .activeToggle {
                background-color: #3ab4f2 !important;
                color: #ffffff;
            }
        }
        .wrapperDesktopContent {
            display: flex;
            &__graphView {
                width: 100%;
                overflow-x: auto;
            }
            .table {
                &__header {
                    td {
                        padding: 2rem 0;
                        height: 8rem;
                    }
                }
                &__body {
                    td {
                        /* height: 8rem; */
                    }
                }
            }
        }
        .newOT {
            display: flex;
            align-item: center;

            &__btn-1 {
                margin-right: 1.5rem;
                margin-left: 1.5rem;
                border-radius: 1.6rem;
                border: 2px solid #d1d1d1;
                padding-right: 1rem;
                padding-left: 1rem;
                display: flex;
                align-item: center;
                justify-content: center;
                font-weight: 700;
                color: #d1d1d1;
                min-width: 100px;
                cursor: pointer;
            }
            .active {
                border: 2px solid orange !important;
                color: orange !important;
            }

            &__btn-2 {
                border-radius: 1.6rem;
                border: 2px solid #d1d1d1;
                padding-right: 1rem;
                padding-left: 1rem;
                display: flex;
                align-item: center;
                justify-content: center;
                color: #d1d1d1;
                min-width: 100px;
                cursor: pointer;
            }
        }
    }
    .input_search {
        margin: 1.6rem 0;

        .search-list {
            z-index: 10;
        }
    }

    .collapse {
    }
    .btn {
        &__add {
            margin-top: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                justify-content: center;
            }
        }
        &__wrapper {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 40rem;
            }
        }
    }

    .ws-top {
        display:flex;
        justify-content:space-between;
        align-items:center;
        margin-bottom:2rem;

       @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display:block;
            }

      }

    .ws-middle {
        border-top:1px solid ${(p) => p.theme.colors.lightgray09};
        padding-top:1rem;
        display:grid;
        grid-template-columns: 15rem 30rem 20rem;
        gap:1rem;
        color:${(p) => p.theme.colors.black01};

        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                grid-template-columns: 1fr 1fr;
            }
    }

    .table_component {
        padding-top:1rem;
        border-top:1px solid ${(p) => p.theme.colors.lightgray09};
        overflow: auto;
        width: 100%;
    }

    .table_component table {
        border: 1px solid #dededf;
        height: 100%;
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        border-spacing: 1px;
        text-align: center;
    }

    .table_component caption {
        caption-side: top;
        text-align: left;
    }

    .table_component th {
        border: 1px solid #dededf;
        background-color: #eceff1;
        color: #000000;
        padding: 5px;
        font-weight: 200;
    }

    .table_component td {
        border: 1px solid #dededf;
        background-color: #ffffff;
        color: #000000;
        padding: 5px;
    }

    .truncate {
        display: -webkit-box;
        max-width: 140px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;
export const StyledTableViewWorkSchedule = styled.div`
    .table {
        /* min-height: 40vh; */
        td {
            vertical-align: top;

            .text-center {
                display: flex;
                justify-content: center;
            }
        }
        width: 100%;
        &__colName {
            vertical-align: top;
        }
        .date {
            width: 8rem;
        }
        &__header {
            color: #8e8e8e;
            background-color: #f4f4f4;
            td {
                font-size: 1.2rem;
                padding: 1rem;
            }
        }
        &__body {
            border-bottom: 1px solid #dedede !important;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                height: 15rem;
            }
            td {
                padding: 0;
                border-right: 1px solid #d4d4d4;
                border-left: 1px solid #d4d4d4;
            }
        }

        .flex-start {
            display: flex;
            justify-content: start;
            align-items: center;
        }

        .type-container {
            height: 32px;
            margin: 1.5px 0;
            /* border-bottom: 1px solid #d4d4d4; */
        }

        .truncate {
            display: -webkit-box;
            max-width: 100px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .opacity-80 {
            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
`;
export const StyledToogleWorkScheduleContent = styled.div`
    padding: 2.5rem 2rem;
    .toggle {
        &__top-collapse {
            padding-bottom: 1rem;
        }
        &__info {
            padding: 2rem 0;
            display: flex;
            justify-content: space-between;
            .subText {
                font-size: 1.2rem;
                color: #8e8e8e;
                padding-bottom: 1rem;
            }
            .mainText {
                font-size: 1.4rem;
            }
        }
        &__content {
            .file {
                display: flex;
                justify-content: space-around;
                padding: 1.2rem;
                margin-top: 2.4rem;
                background-color: #f5f5f5;
                border-radius: 1rem;
                &__icon,
                &__name {
                    display: flex;
                    /* align-items: center; */
                }
                &__name {
                    width: 50%;
                    padding-left: 0.5rem;
                    font-size: 1.4rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                }
                .MuiIconButton-root {
                    background-color: #ffffff;
                    svg {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
            }
        }
    }
`;
export const StyledNewFormWorkSchedule = styled.div`
    max-height: 90vh;
    min-width: 31vw;
    overflow-x: auto;
    .btn-addDate__container {
        margin-top: 16px;
        width: 100%;
        display: flex;
        justify-content: center;

        .btn-addDate {
            margin-left: auto;
            padding: 1rem;
            border-radius: 8px;
            background-color: #dedede;
            border: none;
            border-radius: 8px;
            font-size: 1.2rem;

            &:hover {
                cursor: pointer;
            }
        }

        .btn-addClass {
            padding: 1rem;
            border-radius: 8px;
            background-color: #292d32;
            border: none;
            border-radius: 8px;
            font-size: 1.5rem;
            color: #fff;

            &:hover {
                cursor: pointer;
            }
        }
    }
    .select {
        &__contractor {
            display: flex;
            column-gap: 2rem;
        }
        &__number {
            display: flex;
            flex-direction: column;
            font-size: 1.4rem;
        }
    }
    .label {
        font-size: 1.8rem;
    }
    .workSchedule {
        &__newForm {
            background-color: #ffffff;
            padding: 4rem 2rem;
            border-radius: 1.6rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 4rem;
            }
        }
    }
    .workScheduleNewForm {
        &__title {
            display: flex;
            justify-content: space-between;
            padding-bottom: 2rem;
            border-bottom: 1px solid #f0f0f0;
            .mainText {
                font-size: 1.4rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            }
            .subText {
                font-size: 1.4rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
                display: block;
                display: -webkit-box;
                width: 10rem;
                max-width: 100%;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &__datePicker {
            /* padding: 2rem 0; */
            .wrapperDateRange {
                display: flex;

                @media (max-width: 680px) {
                    flex-direction: column;
                }
                .MuiBox-root {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 2rem;
                    /* padding-top: 1rem; */
                    border-bottom: 1px solid #f0f0f0;
                }
                .label {
                    font-size: 1.4rem;
                }
            }
        }
        &__select {
            padding-bottom: 2rem;
            border-bottom: 1px solid #f0f0f0;
            font-size: 1.4rem;
            font-weight: 500;
            .label {
                display: block;
                font-size: 1.4rem;
                margin-bottom: 0.4rem;
                margin-top: 0.4rem;
            }
        }
        &__btn {
            padding: 1rem 0;
        }
    }
    .cancel {
        .MuiButtonBase-root {
            width: 100%;
            padding: 10px;
            background-color: #dedede;
            border-radius: 8px;
            font-size: 1.4rem;
            color: #656565;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        }
    }
    .delete {
        .MuiButtonBase-root {
            width: 100%;
            padding: 10px;
            background-color: #ff9d43;
            border-radius: 8px;
            font-size: 1.4rem;
            color: #ffffff;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        }
    }
    .update {
        .MuiButtonBase-root {
            background-color: ${(p) => p.theme.colors.primary};
        }
    }
    .delete {
        .MuiButtonBase-root {
            width: 100%;
            padding: 10px;
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #fa5c5c;
            font-size: 1.4rem;
            color: #fa5c5c;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        }
    }
    .error {
        color: red;
    }
`;
export const StyledWorkScheduleDetailViewOnly = styled.div`
    background-color: #f6f6f6;
    min-height: 100vh;
    .detail {
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        &__top {
            text-align: left;
        }
        &__info {
            margin-top: 2rem;
            padding: 2.5rem 1.5rem;
            border-radius: 1.6rem;
            background-color: white;
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 5rem;
                width: 50rem;
            }
            &-row {
                display: flex;
                justify-content: space-between;
                padding: 1rem 0;
            }
            .subText {
                color: #656565;
            }
            .mainText {
                color: #000000;
            }
        }
        &__graphView {
            margin-top: 2rem;
            padding: 2.5rem 1.5rem;
            border-radius: 1.6rem;
            background-color: white;
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 5rem 8rem;
            }
            .datePicker {
                width: 20rem;
                display: flex;
                justify-content: space-between;
                .label {
                    width: 8rem;
                }
            }
        }
        .btn {
            &__add {
                margin-top: 2rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    display: flex;
                    justify-content: center;
                }
            }
            &__wrapper {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    width: 40rem;
                }
            }
        }
    }
`;
export const StyledWorkScheduleDetailItem = styled.div`
    .detailItem {
        border-radius: 1.6rem;
        background-color: #ffffff;
        width: 35rem;
        .circle-around {
            width: 2rem;
            height: 2rem;
            margin-left: 1rem;
            border-radius: 100%;
        }
        .colorSpace {
            height: 2rem;
            width: 100%;
            border-top-left-radius: 1.6rem;
            border-top-right-radius: 1.6rem;
        }
        &__row {
            padding: 2rem;
            display: flex;
            justify-content: space-between;
            .mainText {
                font-size: 1.4rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
            }
            .subText {
                font-size: 1.4rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
                color: #656565;
            }
        }
    }
`;

export const StyledWorkScheduleListConstructionSite = styled.div`

  .paperStyle {
    background-color:${(p) => p.theme.colors.white00};
    border-radius:6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  }
  .header {
    background-color:${(p) => p.theme.colors.lightgray09};
    color:${(p) => p.theme.colors.black01};

  }
  .input {
    margin:1.5rem 0;

  }

  .rowsPerPage {
    display:flex;
    justify-content:flex-start;

  }

  & .MuiSelect-root,
  & .MuiOutlinedInput-root {
    background-color:${(p) => p.theme.colors.white02} !important;
    border-radius:24px !important;
    color: ${(p) => p.theme.colors.black01};
    font-size: 14px;
    padding:2px;
    border:1px solid lightgray;
  }

  & .MuiTablePagination-select:hover {
    background-color: ${(p) => p.theme.colors.white02};
    border-radius:24px;
  }

  & .MuiTablePagination-select.Mui-focused {
    background-color: ${(p) => p.theme.colors.white02};
    border-radius:24px;
  }


`;


export const StyledTableWrapView = styled.div`
    width: 100%;
    overflow-x: auto;
    border-radius:6px;
    background-color:${(p) => p.theme.colors.white00};

    table {
        width: 100%;
        border-collapse: collapse;

    }

    th, td {
        padding: 8px;
        border-bottom: 1px solid ${(p) => p.theme.colors.lightgray09};
        text-align: center;
        min-width: 50px;
        font-weight:normal;
        color:${(p) => p.theme.colors.black01};

        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 4px;
        }
    }

    th, td::first {
        border-bottom: none;
    }

    .leftSticky {
        position: sticky;
        left: 0;
        background: ${(p) => p.theme.colors.white00};
        z-index: 2;

        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            font-size: 12px;
      }
    }

    .title {

    }

    .leftSticky:nth-child(1) {
      left: 0;
      min-width: 80px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                min-width:40px;
            }
      
      }

    .leftSticky:nth-child(2) {
      left: 80px;
      min-width: 150px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                left:40px;
                min-width:70px;
            }
      
      }

    .leftSticky:nth-child(3) {
      left: 230px;
      min-width: 100px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                left:110px;
                min-width:80px;
            }
      
      }

    .table__event {
      border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
      padding:0;
      margin:0;
      position:relative;
    }

    .eventContainer {
      background-color: ${(p) => p.theme.colors.lightgray09};
      border:3px solid ${(p) => p.theme.colors.white00};
      position:absolute;
      top:0;
      left:0;
      height: 100%;
      width: 100%;
      display:flex;
      align-items:center;
      justify-content:center;
    }

    .eventContainer .event {
        background-color: ${(p) => p.theme.colors.blue06};
        color: #fff;
        text-align: center;
        position:absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .table__header .leftSticky:nth-child(1) {
        border: none;
        background-color: ${(p) => p.theme.colors.lightgray09};
    }

    .table__header .leftSticky:nth-child(2) {
        border: none;
        background-color: ${(p) => p.theme.colors.lightgray09};
    }

    .table__header .leftSticky:nth-child(3) {
        border: none;
        background-color: ${(p) => p.theme.colors.lightgray09};
    }

    .day {
      background-color: ${(p) => p.theme.colors.lightgray09};
      border-left:6px solid ${(p) => p.theme.colors.white00};
    }

    .table__header th.dayOff,
    .table__header td.dayOff {
        border: none;
    }

    .empty {
        height: 42px;
    }
`;
