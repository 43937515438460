import styled from "styled-components";

import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledReturnScheduleView = styled.div`
    // background-image: url(${small_bg_dot});
    background-color: ${(p) => p.theme.colors.white02};
    min-height: 100vh;
    padding: 1rem 1rem;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding: 2rem 2rem;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding: 2rem 2rem;
    }
    .returnSchedule {
        padding: 1.6rem;
        text-align: left;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 0;
        }
            margin-top:7rem;
            padding-bottom:12rem;
        &__content {
            padding-top: 2.5rem;
        }
        &__title {
            padding-bottom:2rem;
            border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};

            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-bottom:none;
            }
        }
        &__icon {
            width: 5rem;
            div {
                width: 100%;
                button {
                    width: 100%;
                    display: flex;
                    justify-content: end;
                }
            }
            .MuiIconButton-root {
                padding-left: 0;
            }
        }
    }
`;
export const StyledReturnScheduleItem = styled.div`
    .cardContainer {
      display: flex;
      justify-content: start;
      gap: 1rem;
    }

    .cardRight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .cardRightItem {
      display: flex;
      justify-content: end;

    }



    .wrapperItem {
        display:flex;
        justify-content:space-between;
        padding: 1rem;
        background: ${(p) => p.theme.colors.white00};
        border-radius: 6px;
        transition: 250ms;
        cursor: pointer;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        &:hover {
            background-color: ${(p) => p.theme.colors.blue06};
            .item__content {
                .text {
                    color: #f4f4f4;
                }
                .amount {
                    border-color: ${(p) => p.theme.colors.white};
                }
                .subText {
                    color: ${(p) => p.theme.colors.white00};
                }
            }
            .item__btn {
                .MuiIconButton-root {
                    background-color: ${(p) => p.theme.colors.secondary} !important;
                    border-color: ${(p) => p.theme.colors.darkBlack};
                }
            }
        }
    }
    .active {
        background-color: #343942;
        .item__content {
            .text {
                color: #f4f4f4;
            }
            .amount {
                border-color: ${(p) => p.theme.colors.white};
            }
            .code2 {
                color: ${(p) => p.theme.colors.secondary};
            }
        }
        .item__btn {
            .MuiIconButton-root {
                background-color: ${(p) => p.theme.colors.secondary} !important;
                border-color: ${(p) => p.theme.colors.darkBlack};
            }
        }
    }
    .item {
        &__icon {
            width: 9rem;
            height: 9rem;
            max-height: 9rem;
            border-radius: 6px;
            overflow: hidden;
            // border: 1px solid black;
            .image {
                object-fit:cover;
                width: 100%;
                height: 100%;
            }
        }
        &__content {
            // padding-left: 1rem;
            .text {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                font-size: 1.6rem;
                display: block;
                // display: -webkit-box;
                // max-width: 80%;
                height: 22px;
                line-height: 1.2;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // word-break: break-all;
            }
            .subText {
                padding-top: 0.5rem;
                color: ${(p) => p.theme.colors.gray2};
                font-size: 0.5rem;
                display: block;
                // display: -webkit-box;
                // max-width: 80%;
                line-height: 1.2;
                // -webkit-line-clamp: 1;
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // word-break: break-all;
                span {
                font-size: 0.5rem;
                }
            }
            .amount {
                padding: 0.1rem 0.5rem;
                font-size: 1rem;
                border-radius: 100%;
                border: 1px solid black;
            }
        }
    }
`;
