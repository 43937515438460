import axiosClient from "./axiosClient";

export const technicalDocumentsApi = {
    getTechnicalDocuments: ({ category, name }: { category?: any; name?: string } = {}) => {
        const url = `api/search-document?category=${category || ""}&name=${name || ""}`;
        return axiosClient.get(url);
    },
    getTechnicalDocumentsPage: (page: any) => {
        const url = `/api/get-list-box-document?page=${page}&limit=1000`;
        return axiosClient.get(url);
    },
    getTechnicalDocumentsProfile: () => {
        const url = `/api/get-list-box-document`;
        return axiosClient.get(url);
    },
    deleteTechnicalDocuments: (body: any) => {
        const url = `/api/delete-box-document`;
        return axiosClient.post(url, body);
    },
    readTechnicalDocuments: (body: any) => {
        const url = `/api/read-box-document`;
        return axiosClient.post(url, body);
    },
};