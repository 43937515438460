import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { weeklyScheduleApi } from "src/Api/weekly-schedule";
import { RootState } from ".";

interface IState {
    loadingWeeklySchedule: boolean;
    weeklySchedule: {users: any, total: number, date_holiday: any[]};
}

const initialState: IState = {
    loadingWeeklySchedule: false,
    weeklySchedule: {users: [], total: 0, date_holiday: []},
};

interface UpdateSchedulePayload {
    userId: number;
    date: string;
    contentOne: string;
    contentTwo: string;
}

// Async Thunks for fetching data
export const fetchWeeklySchedule = createAsyncThunk(
    "weeklySchedule/fetchWeeklySchedule",
    async (arg: { year?: number; month?: number } = {}, { rejectWithValue }) => {
        if(!arg.year) { arg.year = new Date().getFullYear(); }
        if(!arg.month) { arg.month = new Date().getMonth() + 1; }
        try {
            const response = await weeklyScheduleApi.getListWorksInConstruction(arg, [], undefined, undefined);
            return response.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

const weeklyScheduleSlice = createSlice({
    name: "weeklySchedule",
    initialState,
    reducers: {
        updateSchedule(state, action: PayloadAction<UpdateSchedulePayload>) {
            const { userId, date, contentOne, contentTwo } = action.payload;
            const user = state.weeklySchedule.users.find((u: any) => u.id === userId);
            if(user) {
                const schedule = user.schedule.find((s: any) => s.date === date);
                if(schedule) {
                    schedule.content_one = contentOne;
                    schedule.content_two = contentTwo;
                } else {
                    user.schedule.push({
                        date: date,
                        content_one: contentOne,
                        content_two: contentTwo
                    });
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWeeklySchedule.pending, (state) => {
                state.loadingWeeklySchedule = true;
            })
            .addCase(fetchWeeklySchedule.fulfilled, (state, action) => {
                state.loadingWeeklySchedule = false;
                state.weeklySchedule = action.payload;
            })
            .addCase(fetchWeeklySchedule.rejected, (state) => {
                state.loadingWeeklySchedule = false;
            });
    },
});

export default weeklyScheduleSlice.reducer;

export const { updateSchedule } = weeklyScheduleSlice.actions;

export const selectWeeklyScheduleLoading = (state: RootState) => state.weeklySchedule.loadingWeeklySchedule;
export const selectWeeklySchedule = (state: RootState) => state.weeklySchedule.weeklySchedule;