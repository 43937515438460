import axiosClient from "./axiosClient";

export interface IFilterDailyLogParams {
    from?: string;
    to?: string;
    constructionId?: string;
    contractorId?: string;
    workType1?: string;
    workType2?: string;
    workType3?: string;
    minEmployee?: string;
    maxEmployee?: string;
    userId?: string;
}

export const dailyLogApi = {
    getDailyLog: (params: any) => {
        const url = `/api/daily-log?date=${params.date}`;
        return axiosClient.get(url);
    },
    filterDailyLog: (params: IFilterDailyLogParams) => {
        const url = `/api/daily/timesheets`;
        return axiosClient.get(url, { params });
    },
};
