import React from "react";
import { Avatar } from "@mui/material";
import styled from "styled-components";
import { AppButton, AppDateRangePicker } from "../Common";
import { selectAuth, useAppSelector } from "@redux";
import { linkImage } from "@utils";
import moment from "moment";

interface IProps {
    data: any;
    handleEditSubmit: (values: any) => void;
    slots?: any;
}
export const InstrumentEditTime = (props: IProps) => {
    const { handleEditSubmit, slots } = props;
    // redux store
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    //component states
    const [dateRate, setDateRate] = React.useState<any>({
        startDate: (slots?.length && slots[0]) || new Date(),
        endDate: (slots?.length && slots[slots.length - 1]) || new Date(),
    });
    React.useEffect(() => {
        setDateRate({
            startDate: slots?.length && slots[0],
            // endDate: moment(slots?.length && slots[slots.length - 1]).subtract(1, "days"),
            endDate: moment(slots?.length && slots[slots.length - 1]),
        });
    }, [slots]);
    // events
    const handleChangeDateRange = (e) => {
        setDateRate({ startDate: e.startDate, endDate: e.endDate });
    };
    return (
        <StyledInstrumentEditTime>
            <div className="wrapper">
                <div className="rowContent">
                    <div className="userInfo">
                        <Avatar src={linkImage(userInfo?.avatar?.path)} />
                        <span className="userInfo__name">
                            {userInfo?.last_name}
                            {userInfo?.first_name}
                        </span>
                    </div>
                </div>
                <div className="rowContent">
                    <AppDateRangePicker
                        value={{
                            startDate: dateRate?.startDate,
                            endDate: dateRate?.endDate,
                        }}
                        onChange={(e) => handleChangeDateRange(e)}
                        startText="持出"
                        endText="返却"
                    />
                </div>
                <div className="rowContent">
                    <AppButton
                        onClick={() =>
                            handleEditSubmit({
                                dateRate,
                            })
                        }
                        text="決定"
                    />
                </div>
            </div>
        </StyledInstrumentEditTime>
    );
};

const StyledInstrumentEditTime = styled.div`
    background-color: #ffffff;
    padding: 2rem 4rem;
    border-radius: 1.6rem;
    .userInfo {
        display: flex;
        &__name {
            font-size: 2rem;
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            padding-left: 4rem;
            display: flex;
            align-items: center;
        }
    }
    .rowContent {
        padding: 2rem 0;
    }
`;
