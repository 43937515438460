import * as React from "react";

import { FormControl, TextareaAutosize } from "@mui/material";
import { Error, StyledTextAreaNumber } from "..";

interface IProps {
    label?: string;
    name?: string;
    value?: string | number;
    error?: any;
    handleChange: (value: any) => void;
    handleBlur?: (value: any) => void;
    touched?: any;
    placeholder?: string;
    rows?: number;
}

export const AppTextAreaNumber = (props: IProps) => {
    const { label, value, error, name, placeholder, handleChange, handleBlur, touched } = props;

    const handleOnChange = React.useCallback(
        (event: any) => {
            handleChange(event.target.value);
        },
        [handleChange]
    );

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const charCode = event.key;
        if (charCode !== "." && (charCode < "0" || charCode > "9")) {
            event.preventDefault();
        }
    };

    return (
        <StyledTextAreaNumber>
            <div className="input__wrapper">
                {label && <div className="input__label">{label}</div>}
                <FormControl variant="outlined">
                    <TextareaAutosize
                        value={value}
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        name={name}
                        autoComplete="off"
                        placeholder={placeholder}
                        maxRows={8}
                        onKeyPress={handleKeyPress} // Kiểm tra ký tự nhập vào
                    />

                    {!!error && !!touched && <Error>{error}</Error>}
                </FormControl>
                <div
                    style={{
                        marginLeft: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    :mg
                </div>
            </div>
        </StyledTextAreaNumber>
    );
};
