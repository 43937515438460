import styled from "styled-components";

interface IStyledModalMenu {
    screen?: string;
}
export const StyledModalMenu = styled.div<IStyledModalMenu>`
    position: fixed;
    border-radius: 2.4rem;
    margin: 0 2rem;
    z-index: 1;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        margin: 0 4rem;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding: 5rem 8rem;
    }
    .menu {
        &__bg {
            position: absolute;
            top: 4.3rem;
            left: 32%;
            z-index: -1;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: none;
            }
        }
        &__wrapperImage {
            height: 7rem;
            width: 7rem;
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                height: 14rem;
                width: 14rem
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                height: 14rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
                height: 20rem;
                width: 20rem;
            }
        }
        &__item {
            cursor: pointer;
        }
        &__image {
            width: 100%;
            height: 100%;
        }
        &__title {
            text-align: center;
            padding-top: 0.8rem;
            font-size: 14px;
            color: #292d32;
            word-break: break-all;
            display: block;
            display: -webkit-box;
            max-width: 5rem;
            height: 40px;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                font-size: 2.4rem;
                height: 70px;
                max-width: 100%;
            }
        }
    }
`;
export const StyledModalMenuComponent = styled.div`
    position: fixed;
    position:relative;
    height:100vh;
    padding: 2rem 2rem;
    background: ${(p) => p.theme.colors.orange};
    width:380px;
    z-index: 100;
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
    width:280px;
    padding:2rem 1rem;
    }

    .blueprint {
      position:absolute;
      bottom:0;
      left:0;
      width:100%;
      z-index:1;
      opacity:0.8;

    }

    .menu {
        &__bg {
            position: absolute;
            left: 19.5rem;
            top: -2rem;
            display: none;
            z-index: -1;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: block;
            }
        }
        &__logo {
        display: flex;
        padding:3rem;
        margin-left:-4rem;
        margin-top:-1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
           margin-left:0rem;
        }
        width:12rem;
        height:12rem;
        &:hover {
        cursor: pointer;
        }
        // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        //     justify-content: flex-start;
        // }
        }
        &__wrapper {
            margin:4rem 3rem 0 3rem;
            display: grid;
            grid-template-columns: auto auto auto;
            grid-column-gap: 2.5rem;
            // grid-row-gap: 1rem;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin:1rem 1rem 0 1rem;
            }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            //     grid-template-columns: auto auto auto;
            //     grid-column-gap: 2rem;
            //     grid-row-gap: 2rem;
            // }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            //     grid-column-gap: 4rem;
            //     grid-row-gap: 2rem;
            // }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
            //     grid-template-columns: auto auto auto;
            // }
        }
        &__wrapperImage {
            display:flex;
            align-items:center;
            justify-content:center;
            height: 7rem;
            width: 7rem;
            border-radius:100%;
            margin-bottom:5px;
            background-color:rgba(0,0,0,0.2);
            transition: background-color 0.2s ease-in-out;
             &:hover {
            background-color:rgba(255,255,255,0.2);
            }
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                height: 5rem;
                width: 5rem;
            }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            //     height: 7rem;
            //     width: 7rem;
            //     /* padding: 0 6rem; */
            // }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            //     height: 8rem;
            //     width: 8rem;
            //     padding: 0;
            // }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
            //     width: 9rem;
            //     height: 9rem;
            // }
        }
        &__item {
            width: 100%;
            cursor: pointer;
            z-index:10;
            height:auto;
        }
        &__image {
            width: 60%;
            height: 60%;
            border-radius:100%;
        }
        &__title {
            text-align: center;
            font-size: 11px;
            color: ${(p) => p.theme.colors.white00};
            display: block;
            display: -webkit-box;
            max-width: 100%;
            height: 40px;
            line-height: 1.2;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
`;
